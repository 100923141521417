import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import { gamelisting, gameLobbyListing } from "../../services/game.service";
import LobbyCard from "./LobbyCard";
import { Button, Modal, Placeholder } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import Paginator from "../../helper/paginator/Paginator";
import Select from "react-select";
import {
  logoutEventPublicRoutes,
  lobbyFilter,
  lobbyAmountFilters,
  gameTypeFilters,
  gameStatusFilters,
  getModeLabelsSelect,
} from "../../helper/utilities";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ChallengesTable from "./ChallengesTable";
import CreateLobbyForm from "./CreateLobbyForm";
import { getChallengeList } from "../../services/challenges.service";
import { getUserDetail } from "../../services/user.service";
import { useGame } from "../../context/GameContext";
import { gameTypeEnum } from "../../helper/utilities";
const LobbyList = (props) => {
  const { slug } = useParams();
  const history = useHistory();
  const [lobbies, setlobbies] = useState([]);
  const { state } = useGame();

  const [lobbyTypeFilter, setlobbyTypeFilter] = useState();
  const [lobbyAmountFilter, setlobbyAmountFilter] = useState();
  const [gameTypeMode, setGameType] = useState({ value: 1, label: "PPK" });
  const [gameStatus, setGameStatus] = useState(gameStatusFilters[0].value);
  const gameChallenges = 0;
  const [lobbyGame, setLobbyGame] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [totalCountChallenges, settotalCountChallenges] = useState(0);
  const [games, setGames] = useState([]);
  const [verified, setVerified] = useState(false);
  const [pageNum, setpageNum] = useState(1);
  const [pageNumChallenge, setpageNumChallenge] = useState(1);
  const [gamesList, setGamesList] = useState([]);
  const [modeSelected, setmodeSelected] = useState(null);
  const pageSize = 25;
  const challengesPageSize = 10;
  const pagination = true;
  const loggedUser = useSelector((reduxState) => reduxState);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [challenges, setChallenges] = useState([]);
  const [isLoadingChallenge, setLoadingChallenge] = useState(false);
  const [selectedTraditionalGame, setSelectedTraditionalGame] = useState(null);
  const [user, setUser] = useState();

  const fetchChallenges = async () => {
    if(!selectedTraditionalGame?.slug) return 
    let filter = {
      pageNum: pageNumChallenge,
      pageSize: challengesPageSize,
      status: gameChallenges,
      type: gameStatus,
      game: selectedTraditionalGame?.slug,
    };
    setLoadingChallenge(true);
    try {
      const res = await getChallengeList(filter);
      setChallenges(res.results);
      setLoadingChallenge(false);
      settotalCountChallenges(res.total);
    } catch (error) {
      setLoadingChallenge(false);
    }
  };
  useEffect(() => {
    gamelisting().then((res) => {
      setGamesList(
        res
          ?.map((game) => ({ ...game, value: game.id, label: game.name }))
          .filter((game) => game.game_status == 1)
      );
    });

    if (loggedUser?.user) {
      getUserDetail().then((res) => {
        setUser(res.profile.free_lobby_limit);
      });
    }
  }, []);
  useEffect(() => {
    if (slug) {
      getLobbies();
      getGamesList();
    }
    const userData =
      JSON.parse(localStorage.getItem("userProfile")) !== null
        ? JSON.parse(localStorage.getItem("userProfile"))
        : null;
    if (userData !== null) {
      setVerified(userData.profile.is_verified);
    } else {
      setVerified(false);
    }
    logoutEventPublicRoutes();
  }, [
    pageNum,
    slug,
    lobbyTypeFilter,
    lobbyAmountFilter,
    modeSelected,
    gameTypeMode?.value,
  ]);

  useEffect(() => {
    if (loggedUser?.user) {
      fetchChallenges();
    }
  }, [
    pageNumChallenge,
    gameStatus,
    gameChallenges,
    selectedTraditionalGame?.value,
  ]);

  useEffect(() => {
    const defaultGameValue = state.game?.allGames.filter(
      (val) => val.slug === slug
    );
    for (let typeKey in state?.game?.modeObj) {
      if (
        state?.game?.modeObj[typeKey]?.find((gameObj) => {
          return gameObj.slug == slug;
        })
      ) {
        setmodeSelected(
          getModeLabelsSelect(
            { value: typeKey },
            state?.game?.modeGameTypeObj?.[slug]
          )?.[0]
        );
        // apex will match with PPK type first so we skip if route comes from challenges
        if (typeKey == 1 && props.location?.state?.challengeTab) continue;
        setGameType({ value: typeKey, label: gameTypeEnum[typeKey] });
        break;
      }
    }

    setLobbyGame(defaultGameValue?.[0]);
    setSelectedTraditionalGame(
      state?.game?.traditionalGames?.filter((game) => game.slug == slug)?.[0]
    );
  }, [slug, state?.game, props.location?.state]);

  const onLobbyFilterChange = (event) => {
    let val = event.value;
    setlobbyTypeFilter(val);
    setpageNum(1);
  };
  const onLobbyAmountChange = (event) => {
    let val = event.value;
    setlobbyAmountFilter(val);
    setpageNum(1);
  };
  const onLobbyGameChange = (event, gameTypeValue = -1) => {
    setmodeSelected(
      getModeLabelsSelect(
        typeof gameTypeValue != "object" && gameTypeValue != -1
          ? { value: gameTypeValue }
          : gameTypeMode,
        state?.game?.modeGameTypeObj?.[event?.slug]
      )?.[0]
    );
    setLobbyGame(event);
    setpageNum(1);

    const SLUG = event.slug;
    history.push(`/${SLUG}/lobby`);
  };
  const onChallengeGameChange = (event) => {
    setpageNumChallenge(1);
    setSelectedTraditionalGame(event);
  };
  const onLobbyModeChange = (event) => {
    setpageNumChallenge(1);
    setmodeSelected(event);
  };
  const onGameTypeChange = (event) => {
    const isGameExistInCurrentType = state?.game?.modeObj?.[event?.value]?.find(
      (gameObj) => gameObj.value == lobbyGame?.value
    );
    if (
      !isGameExistInCurrentType &&
      state?.game?.modeObj?.[event?.value]?.[0]
    ) {
      onLobbyGameChange(
        state?.game?.modeObj?.[event?.value]?.[0],
        event?.value
      );
    } else {
      setmodeSelected(
        getModeLabelsSelect(
          { value: event?.value },
          state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
        )?.[0]
      );
    }
    setGameType(event);
    setpageNum(1);
  };

  const onGameStatusChange = (event) => {
    let val = event.value;
    setGameStatus(val);
    setpageNumChallenge(1);
  };

  const getLobbies = async () => {
    let filter = {
      game_slug: slug,
      type: lobbyTypeFilter,
      rewardAmount: lobbyAmountFilter === "0" ? null : lobbyAmountFilter,
      isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
      mode: modeSelected?.value,
      gameType: gameTypeMode.value,
    };
    setisLoading(true);
    await gameLobbyListing(filter)
      .then((res) => {
        if (pagination) {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.total);
        } else {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.length);
        }
      })
      .catch((_) => {
        setlobbies(null);
        setisLoading(false);
      });
  };
  const handlePageClick = (newPageNum) => {
    setpageNum(newPageNum);
  };
  const handlePageClickChallenges = (newPageNum) => {
    setpageNumChallenge(newPageNum);
  };
  const reCallLobbies = async () => {
    let filter = {
      game_slug: slug,
      type: lobbyTypeFilter,
      rewardAmount: lobbyAmountFilter === "0" ? null : lobbyAmountFilter,
      isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    setisLoading(true);
    await gameLobbyListing(filter)
      .then((res) => {
        if (pagination) {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.total);
        } else {
          setlobbies(res.results);
          setisLoading(false);
          settotalCount(res.length);
        }
      })
      .catch((_) => {
        setlobbies(null);
        setisLoading(false);
      });
  };

  const handleSuccessChallenge = () => {
    handleRegisterTeamModal();
    fetchChallenges();
  };

  const giveWidthSelect = isTabletOrMobile ? {} : { width: "198px" };
  const customStyles = {
    control: (base, selectState) => ({
      ...base,
      ...giveWidthSelect,
      background: "#0b0b0b",
      // match with menu
      borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "#00ccff" : "#979797",
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: selectState.isFocused ? "white" : "#00ccff",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      background: "#0b0b0b",
      padding: 0,
      ...giveWidthSelect,
      border: "1px solid #979797",
      overflow: "hidden",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
      zIndex: 1,
      color: "#fff",
      // width: "210px",
      ...giveWidthSelect,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      // override border radius to match the box
      borderRadius: 5,
      // kill the gap
      marginTop: 0,
      ...giveWidthSelect,
    }),
  };
  const customStylesSame = {
    control: (base, selectState) => ({
      ...base,
      background: "#0b0b0b",
      // match with the menu
      borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "#00ccff" : "#979797",
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: selectState.isFocused ? "white" : "#00ccff",
      },
    }),
    menuList: (styles) => ({
      ...styles,
      background: "#0b0b0b",
      padding: 0,
      border: "1px solid #979797",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
      zIndex: 1,
      color: "#fff",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      // override border radius to match the box
      borderRadius: 5,
      // kill the gap
      marginTop: 0,
    }),
  };

  const customStylesGames = {
    control: (base, selectState) => ({
      ...base,
      ...giveWidthSelect,
      background: "#0B0B0B",
      // match with menu
      borderRadius: selectState.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: selectState.isFocused ? "#00CCFF" : "#979797",
      boxShadow: null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: selectState.isFocused ? "white" : "#00CCFF",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      // override border radius to match the box
      borderRadius: 5,
      // kill the gap
      marginTop: 0,
      ...giveWidthSelect,
      ...base,
      width: "100%",
      overflowY: "auto",
      "::-webkit-scrollbar-thumb": {
        background: "white", // imported color string
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "white",
      },
      "::-webkit-scrollbar-track": {
        background: "black",
      },
      "::-webkit-scrollbar": {
        width: "5px",
        background: "white",
      },
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#00CCFF" : isSelected ? "#0B0B0B" : undefined,
      zIndex: 1,
      color: "#fff",
      ...giveWidthSelect,
    }),
    menuList: (base) => ({
      ...base,
      background: "#0B0B0B",
      border: "1px solid #979797",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "black",
      },
      "::-webkit-scrollbar-thumb": {
        background: "white",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "white",
      },
    }),
  };

  const getGamesList = () => {
    gamelisting().then((res) => {
      setGames(res);
    });
  };
  const [key, setKey] = useState("lobby");
  const [resgisterTeam, showRegisterTeamModal] = useState(false);
  const handleRegisterTeamModal = () => {
    showRegisterTeamModal(!resgisterTeam);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    if (props.location?.state?.challengeTab && loggedUser?.user) {
      setKey("challenges");
    }
  }, [props.location?.state?.challengeTab, loggedUser?.user]);


  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/lobbies-background.jpg"}
          strength={1000}
          bgClassName="page-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">{key == "lobby" ? "Lobbies":"Challenges"}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
      {/*Lobby list*/}
      <section className="lobby-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="lobby" title="Lobby">
                  <div className="dash-available-lobbies vipLobbies">
                    <h3 className="vip-lobbies">VIP Lobbies</h3>
                    <h3 className="user-lobbies">User Enrolled Lobbies</h3>
                  </div>
                  <div className="lobby-dropdowns">
                    <div className="lobby">
                      <h2>Game Type</h2>
                      <p>Type</p>
                      <Select
                        className="LobbyModeFilters"
                        value={gameTypeMode}
                        options={gameTypeFilters}
                        onChange={onGameTypeChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Game</h2>
                      <p>Game</p>
                      <Select
                        key={gameTypeMode?.value}
                        value={lobbyGame}
                        options={state?.game?.modeObj?.[gameTypeMode.value]}
                        onChange={onLobbyGameChange}
                        styles={customStylesGames}
                        // maxMenuHeight={250}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Lobby Mode</h2>
                      <p>Mode</p>
                      <Select
                        key={gameTypeMode}
                        defaultValue={
                          getModeLabelsSelect(
                            gameTypeMode,
                            state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
                          )?.[0]
                        }
                        className="LobbyModeFilters"
                        value={modeSelected}
                        options={getModeLabelsSelect(
                          gameTypeMode,
                          state?.game?.modeGameTypeObj?.[lobbyGame?.slug]
                        )}
                        onChange={onLobbyModeChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>
                    <div className="lobby">
                      <h2>Lobby</h2>
                      <p>Lobby</p>
                      <Select
                        defaultValue={lobbyFilter[0]}
                        options={lobbyFilter.filter((lobbyOption) =>
                          lobbyOption?.loginRequired ? loggedUser?.user : true
                        )}
                        onChange={onLobbyFilterChange}
                        styles={customStyles}
                        isSearchable={false}
                      />
                    </div>

                    <div className="lobby">
                      <h2>Lobby Amount</h2>
                      <p>Amount</p>
                      <Select
                        defaultValue={lobbyAmountFilters[0]}
                        options={lobbyAmountFilters}
                        onChange={onLobbyAmountChange}
                        styles={customStylesSame}
                        isSearchable={false}
                      />
                    </div>

                    <div className="dash-available-lobbies vipLobbies1">
                      <h3 className="vip-lobbies">VIP Lobbies</h3>
                      <h3 className="user-lobbies">User Enrolled Lobbies</h3>
                    </div>
                  </div>

                  <div className="row row-cols-1 row-cols-md-2">
                    {isLoading ? (
                      <p>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder animation="glow">
                          <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                          <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                          <Placeholder xs={8} />
                        </Placeholder>
                      </p>
                    ) : totalCount === undefined || totalCount === 0 ? (
                      <h5>No lobbies yet</h5>
                    ) : verified ? (
                      lobbies &&
                      lobbies?.map((lobby, index) => (
                        <LobbyCard
                          {...lobby}
                          callLobbies={() => {
                            reCallLobbies();
                          }}
                          data={props.location?.state?.ipAddress}
                          key={index}
                          isTeam={true}
                          games={games}
                          userDataForFreeLimitCount={user}
                        />
                      ))
                    ) : lobbies && lobbies?.every((e) => e?.is_verified) ? (
                      isLoading ? (
                        <p>
                          <Placeholder animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                          <Placeholder animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                            <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                            <Placeholder xs={8} />
                          </Placeholder>
                        </p>
                      ) : (
                        <>
                          <h5>No lobbies yet</h5>
                        </>
                      )
                    ) : (
                      lobbies &&
                      lobbies?.map((lobby, index) =>
                        lobby.is_verified ? (
                          ""
                        ) : (
                          <LobbyCard
                            {...lobby}
                            callLobbies={() => {
                              reCallLobbies();
                            }}
                            data={props.location?.state?.ipAddress}
                            key={index}
                            games={games}
                            userDataForFreeLimitCount={user}
                          />
                        )
                      )
                    )}
                  </div>
                  <div></div>
                </Tab>
                {loggedUser?.user &&
                (props?.match?.params?.slug === "apex-legends" ||
                  props?.match?.params?.slug === "valorant") ? (
                  <Tab eventKey="challenges" title="Challenges">
                    <div className="lobby-dropdowns justify-content-between challengedrop">
                      <div className="d-flex">
                        <div className="d-flex challengeDropdowns me-4">
                          <div className="lobby">
                            <h2>Game Status</h2>
                            <p>Status</p>
                            <Select
                              className="LobbyModeFilters"
                              defaultValue={gameStatusFilters[0]}
                              options={gameStatusFilters}
                              onChange={onGameStatusChange}
                              styles={customStylesGames}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        <div className="d-flex challengeDropdowns">
                          <div className="lobby">
                            <h2>Game</h2>
                            <p>Game</p>
                            <Select
                              value={selectedTraditionalGame}
                              options={state.game?.traditionalGames.map(
                                (game) => ({
                                  ...game,
                                  value: game.id,
                                  label: game.name,
                                })
                              )}
                              onChange={onChallengeGameChange}
                              styles={customStylesGames}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <h2>&emsp;</h2>
                        <p>&emsp;</p>
                        <Button
                          className="addChallenge"
                          variant="secondary"
                          onClick={() => handleRegisterTeamModal()}
                        >
                          Add Challenge
                        </Button>
                      </div>
                    </div>
                    {isLoadingChallenge ? (
                      <p>
                        <Placeholder animation="glow">
                          <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder animation="glow">
                          <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                          <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                          <Placeholder xs={8} />
                        </Placeholder>
                      </p>
                    ) : loggedUser?.user ? (
                      <>
                        <ChallengesTable
                          games={games}
                          challenges={challenges}
                          fetchChallenges={fetchChallenges}
                          totalCountChallenges={totalCountChallenges}
                          challengesPageSize={challengesPageSize}
                          handlePageClickChallenges={handlePageClickChallenges}
                          pageNumChallenge={pageNumChallenge}
                          status={gameStatus}
                        />
                      </>
                    ) : null}
                  </Tab>
                ) : null}
              </Tabs>
              {pagination ? (
                key == "lobby" ? (
                  <Paginator
                    key="paginate1"
                    totalCount={totalCount}
                    pageSize={pageSize}
                    pageClick={handlePageClick}
                  />
                ) : (
                  <Paginator
                    totalCount={totalCountChallenges}
                    pageSize={challengesPageSize}
                    pageClick={handlePageClickChallenges}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </section>
      {/*Lobby list*/}

      <Modal
        show={resgisterTeam}
        onHide={() => handleRegisterTeamModal()}
        backdrop="static"
      >
        <CreateLobbyForm
          games={gamesList}
          handleRegisterTeamModal={handleRegisterTeamModal}
          handleSuccessChallenge={handleSuccessChallenge}
        />
      </Modal>
    </>
  );
};
export default withRouter(LobbyList);
