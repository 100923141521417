import { axiosApi, setToken } from ".";
export async function getCMSData(body) {
  const url = process.env.REACT_APP_APIURL + `pages/static/${body.title}`;

  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.get(url, { headers });
}
