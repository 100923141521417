import { LOGGED_IN_USER, LOGIN_USER, LOGOUT_USER } from "./actionTypes";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
};

const loginReducer = (state = initialState, {type, payload} = {}) => {
  switch (type) {
    case LOGIN_USER:
      return { user: payload };
    case LOGGED_IN_USER:
      return {
        ...state,
      };
    case LOGOUT_USER:
      return {
        user: null,
      };
    default:
      return { ...state };
  }
};

export default loginReducer;
