import React, { useEffect, useState } from "react";

import { gamelisting } from "../../services/game.service";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { callGameFilter } from "../../services/lobbies.service";

import LobbyCard from "../Lobby/LobbyCard";
import {Spinner } from "react-bootstrap";
const AvailableLobby = (availableLobby) => {
  const [gameList, setGameListData] = useState([]);
  const [gameFilter, setGameFilter] = useState([]);
  useEffect(() => {
    gamelisting().then((res) => {
      setGameListData(res);
    });
  }, []);
  const reCallLobbies = () => {
    gamelisting().then((res) => {
      setGameListData(res);
    });
  };

  const [showFilteredData, setShowFilterData] = useState(false);
  const [spinnerLoadder, setSpinnerLoader] = useState(false);
  const [gameName, setGameName] = useState("");
  const [activeIndex, setActive] = useState("");
  const handleclick = (e, data, gameNameValue, index) => {
    setActive(index);
    setSpinnerLoader(true);
    callGameFilter(data).then((res) => {
      setGameFilter(res);

      setSpinnerLoader(false);
    });
    setShowFilterData(true);
    setGameName(gameNameValue);
  };
  return (
    <>
      <section className=" active-lobby-dashboard lobby-section">
        {availableLobby?.availableLobbyInfo?.spinnerLoadder1 ? (
          <Spinner animation="grow" />
        ) : (
          <div className="container active-lobby-container">
            <div className="row">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={true}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={true}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 5,
                    partialVisibilityGutter: 30,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2.15,
                    partialVisibilityGutter: 30,
                  },
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={2}
                swipeable
              >
                {gameList.map((data, index) => (
                  <a
                    value={data.slug}
                    onClick={(e) => handleclick(e, data.slug, data.name, index)}
                    style={
                      data?.game_status == 2
                        ? { pointerEvents: "none" }
                        : { cursor: "pointer" }
                    }
                  >
                    <div className="sliders">
                      <div
                        className={
                          index === activeIndex
                            ? "card bg-dark text-white-card active"
                            : "card  text-white"
                        }
                      >
                        <img
                          src={data.background_image}
                          className="card-img"
                          alt="..."
                        />
                      </div>
                      <p
                        id="demo"
                        className={
                          index === activeIndex
                            ? "card bg-dark text-white active"
                            : "card  text-white dashboradLobbiesTitle"
                        }
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {data.name}
                      </p>
                    </div>
                  </a>
                ))}
              </Carousel>
              <div className="col">
                <h2>Available Lobbies</h2>
                <div className="d-flex justify-content-end dash-available-lobbies">
                  <p className="vip-lobbies">VIP Lobbies</p>
                  <p className="user-lobbies">User Enrolled Lobbies</p>
                </div>
                <div className="row row-cols-1 row-cols-md-2">
                  {availableLobby?.availableLobbyInfo?.availableLobby.length ===
                  0 ? (
                    showFilteredData ? (
                      <h5
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        No lobbies yet for {gameName}
                      </h5>
                    ) : (
                      <h5
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        No lobbies yet
                      </h5>
                    )
                  ) : showFilteredData ? (
                    spinnerLoadder ? (
                      <Spinner animation="grow" />
                    ) : gameFilter.length === 0 ? (
                      <h5
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        No lobbies yet for {gameName}
                      </h5>
                    ) : (
                      gameFilter?.map((data, i) => (
                        <LobbyCard
                          {...data}
                          callLobbies={() => {
                            reCallLobbies();
                          }}
                          key={i}
                          games={gameList}
                        />
                      ))
                    )
                  ) : (
                    availableLobby?.availableLobbyInfo?.availableLobby.map(
                      (data, i) => (
                        <LobbyCard
                          {...data}
                          callLobbies={() => {
                            reCallLobbies();
                          }}
                          key={i}
                          games={gameList}
                        />
                      )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default AvailableLobby;
