import { axiosApi, setToken } from ".";

export async function getNotificationsList({
  isPaginated,
  pageNum,
  pageSize,
  column,
  order,
}) {
  const url = process.env.REACT_APP_APIURL + "players/notifications/";
  let params = new URLSearchParams();
  if (isPaginated) {
    if (pageSize) params.append("page_size", pageSize);
    if (pageNum) params.append("page", pageNum);
    if (column) params.append("column", column);
    if (order) params.append("order", order);
  } else {
    params.append("no_page", isPaginated);
  }

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function putFriendRequest({ notificationId, notificationAction }) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/notification-status/${notificationId}/`;

  let body = {
    status: notificationAction,
  };

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.put(url, body, { headers });
}

export async function putNotificationRead() {
  const url = process.env.REACT_APP_APIURL + `players/notification-read/`;
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.put(url, { headers });
}

export async function removeNotifications({ type, notification }) {
  const url = process.env.REACT_APP_APIURL + "players/remove-notifications/";
  let body = {
    type,
    notification,
  };
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.post(url, body, { headers });
}

// players/remove-notifications/

// players/notification-status
// players/notification-read/

//team invite
export async function putTeamRequest({
  notificationAction,
  teamId,
  notificationId,
}) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/versus/${teamId}/notification/${notificationId}/`;

  let body = {
    action: notificationAction ? "accept" : "cancel",
  };
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.put(url, body, { headers });
}
