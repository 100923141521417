import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Col, Row, FormLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  canCreateZeroFeesChallenge,
  getWalletBalance,
} from "../../services/user.service";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOpponentTeams, getTeams } from "../../services/challenges.service";
import { Link, useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { EnrollTeamPayment } from "./EnrollTeam";
import {
  ConsoleHelper,
  enrollEventListener,
  getGameFromId,
} from "../../helper/utilities";
import AddToWallet from "../payment/AddToWallet";
import ReactTooltip from "react-tooltip";
import { TimePicker } from "react-ios-time-picker";
import { useGame } from "../../context/GameContext";
import { useConfig } from "../../context/ConfigContext";
import { useSelector } from "react-redux";
const CreateLobbyForm = (props) => {
  const date = new Date();
  const history = useHistory();
  const { state } = useGame();
  const { state: configState } = useConfig();
  const [playerTeams, setPlayerTeams] = useState([]);
  const [step, setStep] = useState(1);
 
  const [showCreateChallengeSuccessModal, setShowCreateChallengeSuccessModal] =
    useState(false);
  const opponentTeamRef = useRef(null);
  const [payActiveIndex, setPayActiveIndex] = useState(1);
  const [zeroChallenge, setZeroChallenge] = useState(false);
  const games = state?.game?.allGames;
  const APEX_BUFFER_TIME =
  configState?.config?.button_grey_out_time;
  const validationSchema = Yup.object().shape({
    gameName: Yup.string().required("Please select a game"),
    gameMode: Yup.string().required("Please select a game mode"),
    gameDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Please select date")
      .min(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/New_York",
        }),
        "Please select a future date"
      )
      .max(
        new Date(
          new Date().setFullYear(new Date().getFullYear() + 5)
        ).toLocaleDateString("en-US", {
          timeZone: "America/New_York",
        }),
        "Date range cannot be more than 5 years from today"
      ),
    gameTime: Yup.string()
      .nullable()
      .required("Please enter the game time")
      .test(
        "is-greater",
        "Time should be greater than current time",
        function (value) {
          const { gameDate } = this.parent;
          const isESDDay = moment(gameDate).isSame(
            new Date().toLocaleDateString("en-US", {
              timeZone: "America/New_York",
            })
          );
          if (isESDDay) {
  
            const CURR_TIME = new Date();
            CURR_TIME.setMinutes(CURR_TIME.getMinutes() + APEX_BUFFER_TIME);
            const CURR_TIME_EST = CURR_TIME.toLocaleTimeString("en-GB", {
              timeZone: "America/New_York",
              hour12:false
            });
            const parseTime = new Date(moment(value, "HH:mm a")._d);
            const SELECTED_TIME = parseTime.toLocaleTimeString(
              "en-GB",
              {
                hour12: false,
              }
            );
       
            return CURR_TIME_EST <= SELECTED_TIME
              ? true
              : this.createError({
                  message: `Time should be greater than atleast ${APEX_BUFFER_TIME} minutes than current time`,
                });
          } else if (gameDate === null) {
            return false;
          } else return true;
        }
      ),
    fee: Yup.number()
      .typeError("Please specify a number")
      .positive("Entry fee can't start with a minus")
      .integer("Entry fee can't include a decimal point")
      .min(
        zeroChallenge ? 0 : 1,
        "Please enter entry fee greater than or equal to 1"
      ),
    teamName: Yup.string().required("Please select the team"),
    map: Yup.string()
      .optional()
      .max(40, "Please enter map value less than 40 characters"),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gameName: "",
      gameMode: "",
      gameDate: "",
      gameTime: date.toLocaleTimeString("en-US", {
        timeZone: "America/New_York",
        hour: "2-digit",
        minute: "2-digit",
      }),
      opponent_team: null,
      teamName: "",
      fee: 1,
    },
    resolver: yupResolver(validationSchema),
  });
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  const onSubmit = (data, e) => {
    setChallengeData(data);
    setStep(2);
  };
  const onError = (error, e) => ConsoleHelper(error, e);

  const successCallbackChallenge = (payActiveIndexValueFromCallback) => {
    setShowCreateChallengeSuccessModal(true);
    setTimeout(() => {
      setPayActiveIndex(payActiveIndexValueFromCallback);
      const slug = getGameFromId(games, watchAllFields?.gameName)?.slug;
      props.handleSuccessChallenge(slug);
      history.push({
        pathname: `/${slug}/lobby`,
        state: { challengeTab: true },
      });
    }, 2000);
  };

  const goBackStep = () => {
    setStep(1);
  };
  const getTeamsList = async () => {
    try {
      const game_id = watchAllFields.gameName;
      const mode = watchAllFields.gameMode;
      const res = await getTeams({ game_id, mode });
      setPlayerTeams(res);
    } catch (error) {}
  };

  const getOpponentList = async (opponentName) => {
    try {
      const game_id = watchAllFields.gameName;
      const mode = watchAllFields.gameMode;
      if (!mode || !game_id) return;
      const res = await getOpponentTeams({
        game_id,
        mode,
        opponentName,
      });
      if (res.length) {
        return res?.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }));
      }
      return [];
    } catch (error) {}
  };

  const [isInsufficientBalance, setisInsufficientBalance] = useState(false);
  const [promo_choice, setCheckPromo] = useState(false);
  const loggedUser = useSelector((loggedUserState) => loggedUserState);
  const [walletBalance, setwalletBalance] = useState(null);
  const [openWalletModal, setopenWalletModal] = useState(false);
  const [challengeData, setChallengeData] = useState({});
  const checkBalance = () => {
    setisInsufficientBalance(false);
    setCheckPromo(false);
    getWalletBalance().then((res) => {
      setwalletBalance(res.wallet_balance);
      if (+res.wallet_balance < watchAllFields.fee) {
        setisInsufficientBalance(true);
      }
    });
  };

  const openWalletModalStatus = () => {
    setopenWalletModal(true);
  };

  const closeWalletModalStatus = () => {
    setopenWalletModal(false);
  };

  const gameMode = () => {
    return getGameFromId(games, watchAllFields?.gameName)?.slug == "valorant"
      ? [{ label: "5v5", value: 5 }]
      : [
          { label: "3v3", value: 3 },
          { label: "6v6", value: 6 },
        ];
  };

  const promoBonus =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))?.profile?.bonus_balance
      : "";

  const onChangePromo = (e) => {
    const check = e.target.checked;
    setCheckPromo(check);
    if (watchAllFields.fee > +promoBonus + +walletBalance) {
      setisInsufficientBalance(true);
    } else {
      setisInsufficientBalance(false);
    }
    if (!check && +walletBalance < watchAllFields.fee) {
      setisInsufficientBalance(true);
    }
  };

  const checkZeroChallengePermission = async () => {
    try {
      // loggedUser.user
      const res = await canCreateZeroFeesChallenge(loggedUser.user.id);
      if (res?.player_status) {
        setZeroChallenge(true);
        setValue("fee", 0);
      }
    } catch (error) {
      ConsoleHelper(error);
    }
  };

  useEffect(() => {
    checkBalance();
    enrollEventListener();
    checkZeroChallengePermission();
  }, []);

  useEffect(() => {
    if (watchAllFields.gameMode && watchAllFields.gameName) getTeamsList();
  }, [watchAllFields.gameMode, watchAllFields.gameName]);
  const [disableBackgroundScroll, setBackgroundScrollDisable] = useState(false);
  const handelDisableBackgroundScroll = () => {
    setBackgroundScrollDisable(!disableBackgroundScroll);
    if (!disableBackgroundScroll) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  };
  const handelDisableBackgroundScrollonClose = () => {
    setBackgroundScrollDisable(false);
    document.documentElement.style.overflow = "auto";
  };
  const handelDisableBackgroundScrollonSave = () => {
    setBackgroundScrollDisable(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    setValue("opponent_team", null);
    setValue("teamName", "");
  }, [watchAllFields.gameMode, watchAllFields.gameName]);

  useEffect(() => {
    setValue("gameMode", "");
  }, [watchAllFields.gameName]);

  return showCreateChallengeSuccessModal ? (
    <>
      <Modal show={true} onHide={() => props.handleSuccessChallenge()}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="money-added">
          <div className="tick-image">
            <img src="/assets/images/tick.svg" alt="" />
          </div>
          <h3 className="challengeCreated">Challenge Created !</h3>
          {watchAllFields?.opponent_team?.name ? (
            <p className="challengeBetween">
              Congratulations, Challenge has been created between{" "}
              {
                playerTeams.filter(
                  (team) => team.id == watchAllFields.teamName
                )[0]?.name
              }{" "}
              and {watchAllFields?.opponent_team?.name}
            </p>
          ) : (
            <p className="challengeBetween">
              Congratulations, your open challenge has been created
            </p>
          )}

          <p>Wallet Balance</p>
          <h4>
            $
            {(
              parseFloat(walletBalance) +
              parseFloat(promoBonus) -
              (payActiveIndex == 1
                ? challengeData?.fee * challengeData.gameMode
                : challengeData?.fee)
            )?.toFixed(2)}
          </h4>
        </Modal.Body>
      </Modal>
    </>
  ) : step == 1 ? (
    <div className="addChallengePopup">
      <Modal.Header closeButton>
        <Modal.Title>Add Challenge</Modal.Title>
      </Modal.Header>
      <Modal.Body className="file-complaint-wrapper">
        <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
          <div
            style={{
              backgroundColor: "#00ccff",
              height: "0.5rem",
              flexBasis: "50%",
              borderRadius: 0,
            }}
          >
            <h6 className="text-center text-white mt-3">Register Team</h6>
          </div>
          <div
            style={{
              flexBasis: "50%",
              borderRadius: 0,
              background: "gray",
              height: "0.5rem",
            }}
          >
            <h6 className="text-center text-white mt-3">Pay</h6>
          </div>
        </div>
        <div className="player-details text-white add-challenge wallet-body modal-body">
          <Form onSubmit={handleSubmit(onSubmit, onError)} className="createLobbyForm">
            <Row className="margins mb-4">
              <Form.Group
                as={Col}
                className="first-name-col margin-bottom"
                controlId="formGridGame"
              >
                <FormLabel>Game</FormLabel>
                <Form.Select
                  aria-label="Default select example"
                  name="gameName"
                  {...register("gameName")}
                  className={`mb-0 input-field ${
                    errors.gameName ? "is-invalid" : ""
                  }${watchAllFields.gameName == "" ? "gray-select" : ""}`}
                >
                  <option value="">Select Game</option>
                  {state.game.traditionalGames.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.gameName?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.gameName?.message}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridMode">
                <FormLabel>Mode</FormLabel>
                <Form.Select
                  aria-label="Default select example"
                  name="gameMode"
                  {...register("gameMode")}
                  className={`mb-0 input-field ${
                    errors.gameMode ? "is-invalid" : ""
                  }${watchAllFields.gameMode == "" ? "gray-select" : ""}`}
                >
                  <option value="">Select Mode</option>
                  {watchAllFields.gameName
                    ? gameMode().map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))
                    : null}
                </Form.Select>
                {errors.gameMode?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.gameMode?.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="margins mb-4">
              <Form.Group as={Col} controlId="formGridDate" className="margin-bottom">
                <FormLabel>Start Date(ESD)</FormLabel>

                <Controller
                  control={control}
                  name="gameDate"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Select Date"
                      onChange={(updateDate) => field.onChange(updateDate)}
                      dateFormat="MM/dd/yyyy"
                      selected={field.value}
                      className={` text-white react-datepicker-custom ${
                        errors.gameDate ? "date-picker-is-invalid" : ""
                      }`}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                />
                {errors.gameDate?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.gameDate?.message}
                  </div>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridTime">
                <FormLabel>
                  Start Time(EST)
                  <span class="ms-2 info-icon">
                    <img
                      src="/assets/images/info-icon.svg"
                      alt="info"
                      class="info-image"
                      data-tip
                      data-for="create-challenge-time"
                    />
                  </span>
                  <ReactTooltip
                    id="create-challenge-time"
                    place="right"
                    effect="solid"
                  >
                    Challenge start time should be {APEX_BUFFER_TIME} mins greater than challenge create time (in est)
                  </ReactTooltip>{" "}
                </FormLabel>

                <Controller
                  control={control}
                  name="gameTime"
                  render={({ field }) => (
                    <TimePicker
                      onChange={(time) => field.onChange(time)}
                      value={field?.value}
                      use12Hours
                      onOpen={() => handelDisableBackgroundScroll()}
                      onCancel={() => handelDisableBackgroundScrollonClose()}
                      onSave={() => handelDisableBackgroundScrollonSave()}
                      inputClassName={
                        errors.gameTime ? "time-picker-is-invalid" : ""
                      }
                    />
                  )}
                />
                {errors.gameTime?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.gameTime?.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="margins mb-4">
              <Form.Group
                className="col-lg-6 col-md-6 col-sm-12 col-12 entryFeeColumn"
                as={Col}
                controlId="formGridFee"
              >
                <FormLabel>Entry Fee</FormLabel>
                <span class="info-icon ml--5">
                  <img
                    src="/assets/images/info-icon.svg"
                    alt="info"
                    class="info-image"
                    data-tip
                    data-for="timerTip"
                  />
                </span>
                <ReactTooltip id="timerTip" place="top" effect="float">
                  Entry fee per player
                </ReactTooltip>
                <Form.Control
                  type="number"
                  name="fee"
                  placeholder="Enter entry fee"
                  {...register("fee")}
                  className={`${errors.fee ? "is-invalid" : ""}`}
                />
                {errors.fee?.message && (
                  <div className="invalid-feedback">{errors.fee?.message}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridTeam">
                <div className="d-flex justify-content-between">
                  <FormLabel>Select Team</FormLabel>
                  <Link
                    className="redirect-link"
                    to={{
                      pathname: `/myprofile`,
                      state: {
                        createTeam: true,
                        mode: watchAllFields?.gameMode,
                        game: watchAllFields?.gameName,
                      },
                    }}
                  >
                    + Create New Team
                  </Link>
                </div>
                <Form.Select
                  aria-label="Default select example"
                  name="teamName"
                  {...register("teamName")}
                  className={`mb-0 input-field ${
                    errors.teamName ? "is-invalid" : ""
                  }${watchAllFields.teamName == "" ? "gray-select" : ""}`}
                >
                  <option value="">Select Your Team</option>
                  {playerTeams?.length &&
                    playerTeams.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </Form.Select>
                {errors.teamName?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.teamName?.message}
                  </div>
                )}
              </Form.Group>
            </Row>
            <Row className="margins mb-4">
              <Form.Group
                className={`col-lg-6 col-md-6 col-sm-12 col-12 entryFeeColumn ${
                  getGameFromId(games, watchAllFields?.gameName)?.slug ==
                  "apex-legends"
                    ? "d-block"
                    : "d-none"
                }`}
                as={Col}
                controlId="formGridFee"
              >
                <FormLabel>Map</FormLabel>
                <Form.Control
                  type="text"
                  name="map"
                  placeholder="Enter Map"
                  {...register("map")}
                  className={`${errors?.map ? "is-invalid" : ""}`}
                />
                {errors?.map?.message && (
                  <div className="invalid-feedback">{errors?.map?.message}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridTeam">
                <FormLabel>Select Opponent Team</FormLabel>
                <Controller
                  name="opponent_team"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, ref, name }) => (
                    <AsyncSelect
                      {...field}
                      className="react-select-container-teammates"
                      classNamePrefix="react-select-teammates"
                      isClearable
                      placeholder={"Select Opponent Team"}
                      loadOptions={getOpponentList}
                      name={name}
                      ref={opponentTeamRef}
                    />
                  )}
                />
              </Form.Group>
            </Row>
            {watchAllFields.opponent_team?.id ? (
              <Row>
                <h6 className="text-white">Players in Opponent Team are :</h6>
                <ul className="pl--40">
                  {watchAllFields?.opponent_team?.players.map((player) => {
                    return (
                      <li className="listStyle">
                        {player.player__username} (
                        {player.leader ? "CREATOR" : "MEMBER"}){" "}
                      </li>
                    );
                  })}
                </ul>
              </Row>
            ) : null}
            <div className="d-flex justify-content-evenly align-items-center modal-footer">
              <Button
                variant="primary"
                className="challengeCancel"
                onClick={props.handleRegisterTeamModal}
              >
                Cancel
              </Button>
              <Button className="challengeNext" type="submit" variant="primary">
                Next
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </div>
  ) : (
    <>
      {openWalletModal ? (
        <Modal
          show={true}
          onHide={closeWalletModalStatus}
          contentClassName="qr-verification-wrapper"
        >
          <AddToWallet
            gameName={
              props.games &&
              props?.games?.filter(
                (item) => item.id == watchAllFields.gameName
              )[0].name
            }
            walletBalance={walletBalance}
          />
        </Modal>
      ) : (
        <EnrollTeamPayment
          challengeData={challengeData}
          createChallenge={true}
          modalHeading="Add Challenge"
          entry_fee={watchAllFields.fee}
          mode={watchAllFields.gameMode}
          walletBalance={walletBalance}
          isInsufficientBalance={isInsufficientBalance}
          openWalletModalStatus={openWalletModalStatus}
          promo_choice={promo_choice}
          promoBonus={promoBonus}
          onChangePromo={onChangePromo}
          closeEnrollModalStatus={goBackStep}
          games={props.games}
          successCallbackChallenge={successCallbackChallenge}
          game={
            props.games &&
            props?.games?.filter(
              (item) => item.id == watchAllFields.gameName
            )[0]
          }
        />
      )}
    </>
  );
};

export default CreateLobbyForm;
