import { Button, Modal } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { gamerTagInfo } from "../../helper/utilities";
import {getWalletBalance } from "../../services/user.service";

const PaymentComponent = ({
  selectedGame,
  modalHeading = false,
  createChallenge = false,
  entry_fee,
  mode,
  free_lobby = false,
  handleSubmit = () => {},
  handleCancel = () => {},
  openAddToWallet = () => {},
  creatorPay = false,
}) => {
  const loggedUser = useSelector((state) => state);
  const [payActiveIndex, setPayActiveIndex] = useState(2);
  const [gamertag, setGamerTag] = useState(null);
  const [user, setUser] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [isInsufficientBalance, setisInsufficientBalance] = useState(false);
  const [promo_choice, setCheckPromo] = useState(false);
  const [walletBalance, setwalletBalance] = useState(null);

  const promoBonus =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))?.profile?.bonus_balance
      : "";

  const userData =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))
      : null;
  const onChangePromo = (e) => {
    const check = e.target.checked;
    setCheckPromo(check);
    if (entry_fee > +promoBonus + +walletBalance) {
      setisInsufficientBalance(true);
    } else {
      setisInsufficientBalance(false);
    }
    if (!check && +walletBalance < entry_fee) {
      setisInsufficientBalance(true);
    }
  };
  useEffect(() => {
    if (loggedUser?.user) {
      if (loggedUser?.user) {
        setUser(userData);
      }

      getWalletBalance().then((res) => {
        setwalletBalance(res.wallet_balance);
        if (+res.wallet_balance < entry_fee) {
          setisInsufficientBalance(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  return (
    <>
      (
      <>
        {" "}
        <Modal.Header
          className={modalHeading ? "justify-content-center" : ""}
          closeButton
        >
          {createChallenge ? (
            <Modal.Title className="mb-4 mt-4">{modalHeading}</Modal.Title>
          ) : (
            <Modal.Title className="mb-4 mt-4">Pay</Modal.Title>
          )}{" "}
        </Modal.Header>
        <Modal.Body className="make-payment">
          {createChallenge ? (
            <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
              <div
                style={{
                  flexBasis: "50%",
                  borderRadius: 0,
                  background: "gray",
                  height: "0.5rem",
                }}
              >
                <h6 className="text-center text-white mt-3">Register Team</h6>
              </div>
              <div
                style={{
                  backgroundColor: "#00ccff",
                  height: "0.5rem",
                  flexBasis: "50%",
                  borderRadius: 0,
                }}
              >
                <h6 className="text-center text-white mt-3">Pay</h6>
              </div>
            </div>
          ) : null}
          {creatorPay ? (
            <div
              className="d-flex justify-content-evenly payTeamYorself"
              style={createChallenge ? { marginTop: "70px" } : {}}
            >
              <Button
                onClick={() => setPayActiveIndex(1)}
                className={
                  payActiveIndex == 1 ? `cancel-btn payTeam` : "pay-yourserlf"
                }
              >
                Pay for Team
              </Button>
              <Button
                onClick={() => setPayActiveIndex(2)}
                className={
                  payActiveIndex == 2 ? `cancel-btn payTeam` : "pay-yourserlf"
                }
              >
                Pay for Yourself
              </Button>
            </div>
          ) : null}
          <div className="d-flex justify-content-between entryFee">
            <span className="entry-fee">Entry Fee</span>
            <span>
              $
              {payActiveIndex === 1
                ? entry_fee * mode + ".00"
                : entry_fee + ".00"}
            </span>
          </div>
          <div className="d-flex justify-content-between walletTopBorder mb-5">
            <div className="mt-5">
              <h3>Wallet</h3>
              <h4>Total Balance</h4>
              <h5>${walletBalance}</h5>
              {free_lobby
                ? null
                : isInsufficientBalance && (
                    <>
                      <p>Insufficient Balance Add Money in Wallet</p>
                      <button
                        className="btn btn-secondary"
                        onClick={() => openAddToWallet()}
                      >
                        Add Money to wallet
                      </button>
                    </>
                  )}
            </div>
            <div className="promo-bonus mt-5">
              <h3>Promo Bonus</h3>
              <h4>Total Balance</h4>
              <h5>${promoBonus}</h5>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Avail Promo Bonus to enroll into lobby"
                    onChange={onChangePromo}
                    checked={promo_choice}
                    disabled={!parseInt(promoBonus)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className="displayGamerTag">
            <p>Display Name</p>
            <div className="d-flex gamertag-edit">
              <h6>{gamertag ? gamertag : "-"}</h6>
              <Link
                to={{
                  pathname: "/myProfile",
                  data: "settingsTab",
                  settingsKey: "gameIds",
                }}
              >
                <img
                  src="/assets/images/edit-gamertag-payment-popup.svg"
                  alt="edit-icon"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="total d-flex justify-content-evenly">
          <div
            className="pb-3"
            data-tip
            data-for="loginTip"
            style={{ cursor: "pointer" }}
          >
            <span class="info-icon">
              <img
                src="/assets/images/info-icon.svg"
                alt="info"
                class="info-image"
              />
              <span className="gamerTagInfoMessage">
                {gamerTagInfo(selectedGame)}
              </span>
            </span>
            <div className="helper-image-container HelperImage">
              <ReactTooltip id="loginTip" place="top" effect="solid">
                <img src={selectedGame?.helper_image} alt="helper-image" />
              </ReactTooltip>{" "}
            </div>
          </div>
          <Button className="cancel-btn" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleSubmit({ payActiveIndex, bonus: promo_choice ,setisLoading });
            }}
            disabled={isLoading || free_lobby ? false : isInsufficientBalance}
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Pay"
            )}
          </Button>
        </Modal.Footer>
      </>
      )
    </>
  );
};

export default PaymentComponent;
