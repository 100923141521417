import React from "react";
import { Link } from "react-router-dom";
import { DateFormat } from "../../helper/DateFormatter";
import { Spinner } from "react-bootstrap";
import LobbyCardHeader from "./LobbyCardHeader";
import WinnerEntryBox from "../WinnerEntryBox";
import { getCardBackgroundImage } from "../../helper/utilities";
const PreviousLobby = ({ previousLobbyInfo }) => {
  const lobby = previousLobbyInfo;
  const renderPreviousLobby = () => {
    return lobby.previousLobby.length === 0 ? (
      <h5
        style={{
          textTransform: "uppercase",
        }}
      >
        No previous lobby{" "}
      </h5>
    ) : (
      lobby.previousLobby.map((data, idx) => (
        <div className="col" key={data.id}>
          <div
            className={`${getCardBackgroundImage(
              data.game.slug,
              "previousPlayed",
              data.is_verified
            )} enrolled-card`}
          >
            <LobbyCardHeader data={data} />
            <div className="card-img-overlay">
              <h3>{data.name}</h3>
              <div className="dashboard-game-wrapper active-lobby-wrapper previous-lobby-played-wrapper">
                <div className="entry-box">
                  <div className="enrolled-box">
                    Date Played<span>:</span>
                  </div>
                  <div className="count-box"> {DateFormat(data.end_date)}</div>
                </div>
                <div className="entry-box">
                  <div className="enrolled-box">
                    Your Ranking<span>:</span>
                  </div>
                  <div className="count-box">
                    {data.player_stats.team_placement != null
                      ? data.player_stats.team_placement +
                        "/" +
                        data.enrolled_players
                      : "NA"}
                  </div>
                </div>
                <div className="entry-box">
                  <div className="enrolled-box">
                    Total Kills<span>:</span>
                  </div>
                  <div className="count-box">
                    {data.player_stats.kills != null
                      ? data.player_stats.kills
                      : "NA"}
                  </div>
                </div>
                <div className="entry-box">
                  <div className="enrolled-box">
                    Winnings<span>:</span>
                  </div>
                  <div className="count-box">
                    {data.player_stats?.winnings != null
                      ? "$" + data.player_stats?.winnings
                      : "NA"}
                  </div>
                </div>

                <WinnerEntryBox data={data} responsiveClassName={""} isActive = {false} />

                <div className="entry-box">
                  <div className="enrolled-box">
                    Lobby Mode<span>:</span>
                  </div>
                  <div className="count-box"> {data.modetag}</div>
                </div>
                <div className="entry-box">
                  <div className="enrolled-box">
                    {data.mode == 1 ? "Enrolled Players" : "Enrolled Teams "}
                    <span>:</span>
                  </div>
                  <div className="count-box">
                    {data.mode == 1
                      ? data?.enrolled_players || "-"
                      : data.enrolled_teams || "-"}
                  </div>
                </div>
              </div>
              <div className="start-time-wrapper">
                <div className="enrolled-box">
                  <Link
                    className="btn btn-outline-primary"
                    to={{
                      pathname: data.game.slug + "/lobby/" + data.id,
                      data: window.location.pathname,
                    }}
                  >
                    View Results
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      <section className="dashboard previous-lobby-dashboard">
        <div className="container previous-played-lobbies-container">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-end dash-available-lobbies">
                <p className="vip-lobbies">VIP Lobbies</p>
                <p className="user-lobbies">User Enrolled Lobbies</p>
              </div>
              <div className="row row-cols-1 row-cols-md-2">
                {lobby.spinnerLoadder2 ? (
                  <Spinner animation="grow" />
                ) : (
                  renderPreviousLobby()
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PreviousLobby;
