import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { uploadFile } from "react-s3";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION_NAME,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};
const ImageCropper = (props) => {
  const { imageToCrop, onImageCropped } = props;
  const [cropConfig, setCropConfig] = useState({
    unit: "px",
    width: 100,
    height: 100,
  });

  const [imageRef, setImageRef] = useState();
  function makeid(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] % charactersLength));
    }
    return result;
  }
  async function cropImage(crop) {
    let imageName = makeid(5);
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        `${imageName}.jpeg`
      );
      onImageCropped(croppedImage);
    }
  }

  function getCroppedImage(sourceImage, crop, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      sourceImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        const url = await uploadFile(blob, config)
          .then(function (data) {
            setcroppedImageLink(data.location);
            return data;
          })
          .catch((err) => console.error(err));
        resolve(url?.location);
      }, "image/jpeg");
    });
  }
  const setcroppedImageLink = (link) => {
    localStorage.setItem("imageLink", link);
  };
  return (
    <ReactCrop
      src={imageToCrop}
      crop={cropConfig}
      ruleOfThirds
      onImageLoaded={(ref) => setImageRef(ref)}
      onComplete={(cropImageValue) => cropImage(cropImageValue)}
      onChange={(cropConfigValue) => setCropConfig(cropConfigValue)}
      crossorigin="anonymous"
      maxWidth={190}
      maxHeight={180}
    />
  );
};

export default ImageCropper;
