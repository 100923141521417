import React, { useEffect, useState } from "react";
import { Modal, Form, Placeholder } from "react-bootstrap";
import { getKycStatus } from "../../services/user.service";
import PropTypes from "prop-types";
import KycVerification from "../kyc-verification/KycVerification";
import Paypal from "./Paypal";
import Stripe from "./Stripe";
import ReactTooltip from "react-tooltip";

const RedeemFunds = (props) => {
  const [iskycDone, setiskycDone] = useState(false);
  const [iskycStatusLoading, setiskycStatusLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isRedeemProcessDone, setisRedeemProcessDone] = useState(false);
  const [redeemType, setRedeemType] = useState("paypal");

  const [deductedAmount, setdeductedAmount] = useState(null);
  const [updatedWallet, setupdatedWallet] = useState(null);

  useEffect(() => {
    checkUserKyc();
  }, []);

  const checkUserKyc = async (_) => {
    setiskycStatusLoading(true);
    await getKycStatus().then((res) => {
      setiskycStatusLoading(false);
      setiskycDone(res.is_kyc_completed);
    });
  };

  return (
    <>
      {iskycStatusLoading ? (
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <p>
              <Placeholder animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
            </p>
          </Modal.Body>
        </>
      ) : !isRedeemProcessDone ? (
        !iskycDone ? (
          <KycVerification subheader={"reedem funds"} />
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Redeem Funds</Modal.Title>
            </Modal.Header>
            <Modal.Body className="redeem-body">
              <p className="mb-2">
                Please choose your appropriate payment method to redeem funds
              </p>
              <div className="payment-unavailable-container mb-4">
                <p className="payment-method-unavailable-message">
                  Current Balance {`$${props.wallet_balance}`}
                </p>
              </div>

              <h3 className="text-white my-4 payment-method-bold">
                Choose Payment Method
              </h3>
              <div className="d-flex gap-2  flex-column">
                <div className="notification-setting-item mb-3 payment-method-labels justify-content-start align-items-center">
                  <div className="options">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      className="friendPopup"
                      name="paypal"
                      checked={"paypal" == redeemType}
                      onChange={() => setRedeemType("paypal")}
                    />
                  </div>
                  <div className="payment-method-label">
                    <h4 className="text-white me-2 font-weight-light">
                      Paypal
                    </h4>
                  </div>
                  <>
                    <span class="info-icon">
                      <img
                        src="/assets/images/info-icon.svg"
                        alt="info"
                        class="info-image"
                        data-tip
                        data-for="paypal"
                      />
                    </span>
                    <ReactTooltip id="paypal" place="top" effect="solid">
                      Payout within 1 to 3 days
                    </ReactTooltip>
                  </>
                </div>
                <div className="notification-setting-item mb-3 payment-method-labels justify-content-start align-items-center stripe">
                  <div className="options">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      className="friendPopup"
                      name="stripe"
                      checked={"stripe" == redeemType}
                      onChange={() => setRedeemType("stripe")}
                    />
                  </div>
                  <div className="payment-method-label">
                    <h4 className="text-white font-weight-light">
                      Bank/ Debit Card
                    </h4>
                  </div>
                </div>
              </div>

              {redeemType == "paypal" ? (
                <Paypal
                  {...props}
                  setisLoading={setisLoading}
                  setisRedeemProcessDone={setisRedeemProcessDone}
                  setdeductedAmount={setdeductedAmount}
                  setupdatedWallet={setupdatedWallet}
                  isLoading={isLoading}
                />
              ) : (
                <Stripe
                  {...props}
                  setisLoading={setisLoading}
                  setisRedeemProcessDone={setisRedeemProcessDone}
                  setdeductedAmount={setdeductedAmount}
                  setupdatedWallet={setupdatedWallet}
                  isLoading={isLoading}
                />
              )}
            </Modal.Body>
          </>
        )
      ) : (
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="redeem-funds-body zx">
            <div className="tick-image">
              <img src="/assets/images/tick.svg" alt="" />
            </div>
            <h4>{`$${deductedAmount}`} Redeemed Successfully</h4>
            <p>Remaining Wallet Balance</p>
            <h5>{`$${updatedWallet}`}</h5>
          </Modal.Body>
        </>
      )}
    </>
  );
};

RedeemFunds.propTypes = {
  wallet_balance: PropTypes.string,
  paypal_id: PropTypes.string,
};

export default RedeemFunds;
