import { axiosApi, setToken } from ".";

export async function getChallengeList({ pageSize, pageNum, status, type ,game}) {
  const url = process.env.REACT_APP_APIURL + "players/challenges/";
  let params = new URLSearchParams();
  params.append("page_size", pageSize);
  params.append("page", pageNum);
  if (status) params.append("status", status);
  if (type) params.append("type", type);
  if (game) params.append("game", game);


  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function getTeams({ game_id, mode }) {
  const url = process.env.REACT_APP_APIURL + "players/challenges-team/";

  let headers = {
    "Content-Type": "application/json",
  };
  let params = new URLSearchParams();
  params.append("game_id", game_id);
  params.append("mode", mode);
  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function getOpponentTeams({ game_id, mode, opponentName }) {
  const url = process.env.REACT_APP_APIURL + "players/challenges-opp-team/";

  let headers = {
    "Content-Type": "application/json",
  };
  let params = new URLSearchParams();
  params.append("game_id", game_id);
  params.append("mode", mode);
  params.append("team", opponentName);

  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function addChallenge(model) {
  const url = process.env.REACT_APP_APIURL + "players/challenges/";
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.post(url, model, { headers });
}

export async function deleteLobbyChallenge(challengeId) {
  const url =
    process.env.REACT_APP_APIURL + `players/challenges/${challengeId}/`;
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.delete(url, { headers });
}

export async function getChallengesById(lobbyId) {
  const url = process.env.REACT_APP_APIURL + `players/challenges/${lobbyId}/`;

  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers });
}
export async function openChallengeAccept({ challengeId, body }) {
  const url =
    process.env.REACT_APP_APIURL + `players/challenge-accept/${challengeId}/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.put(url, body, { headers });
}

export async function closedChallengeAccept({ challengeId, body }) {
  const url =
    process.env.REACT_APP_APIURL + `players/challenge-invite/${challengeId}/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.put(url, body, { headers });
}

export async function closedChallengeReject({ challengeId, body }) {
  const url =
    process.env.REACT_APP_APIURL + `players/challenge-invite/${challengeId}/`;
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.put(url, body, { headers });
}

