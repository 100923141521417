import React, { useState } from "react";
import { Spinner, Modal } from "react-bootstrap";
import EditGameName from "./EditGameName";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
const GameIdsTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [gameData, setGameData] = useState(null);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const handleEditGameName = (id, gameName, name) => {
    setShowModal(true);
    setGameData({ gameId: id, gameName: gameName, gameAccountName: name });
    setSpinnerLoader(true);

    setTimeout(() => {
      setSpinnerLoader(false);
    }, 2000);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalAfterEdit = () => {
    setShowModal(false);
    props.fetchUserData();
  };
  return (
    <>
      <table className="newGameIdTable" style={{ color: "white" }}>
        <tr>
          <th>Game</th>
          <th>Id</th>
        </tr>
        {props?.GameAccounts.map((item, idx) => (
          <tr>
            <td key={idx}>{item.game.gaming_account_name}</td>
            <td>
              {item.gaming_account}
              <img
                className="editCursorImage"
                src="/assets/images/edit-pen.svg"
                alt="edit"
                onClick={() =>
                  handleEditGameName(
                    item.game.id,
                    item.game.gaming_account_name,
                    item.gaming_account
                  )
                }
                data-tip
                data-for="edit-gameId"
              />
                  <ReactTooltip
                  id="edit-gameId"
                  place="bottom"
                  effect="solid"
                >
                 Edit Game ID
                </ReactTooltip>
            </td>
          </tr>
        ))}
      </table>
      <Modal
        className="profile-modal-box edit-game-name-modal"
        show={showModal}
        onHide={() => handleCloseModal()}
      >
        {spinnerLoader ? (
          <Spinner animation="grow" />
        ) : (
          <EditGameName
            userGame={gameData}
            closeEditModal={() => handleCloseModalAfterEdit()}
          />
        )}
      </Modal>
    </>
  );
};
GameIdsTable.propTypes = {
  fetchUserData: PropTypes.func,
};
export default GameIdsTable;
