import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  closedChallengeAccept,
  closedChallengeReject,
  deleteLobbyChallenge,
  getTeams,
  openChallengeAccept,
} from "../../services/challenges.service";
import AddToWallet from "../payment/AddToWallet";
import PaymentComponent from "../PaymentMethod";
import Select from "react-select";
import { Link } from "react-router-dom";
import toastify from "../../helper/toastify";
import ChallengeTableCard from "./ChallengeTableCard";
import { getUserDetail, getWalletBalance } from "../../services/user.service";
import { useSelector } from "react-redux";
import { gamerTagInfo } from "../../helper/utilities";

const ChallengesTable = ({ games, challenges, fetchChallenges, status }) => {
  const [challenge, setSelectedChallenge] = useState({});
  const [addtoWallet, setAddToWallet] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showTeamSelectModel, setShowTeamSelectModal] = useState(false);
  const [playerTeams, setPlayerTeams] = useState([]);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [challengeDelete, setChallengeDelete] = useState(false);
  const [user, setUser] = useState([]);
  const loggedUser = useSelector((state) => state);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [gamertag, setGamerTag] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [walletBalance, setwalletBalance] = useState(null);
  const [entryFeeSubtract, setEntryFeeSubtract] = useState(0);
  const [challengeAcceptConfirmModal, setChallengeAcceptConfirmModal] =
    useState(false);

  useEffect(() => {
    setSelectedGame(games?.find((item) => item.id == challenge.game));
  }, [games, challenge]);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  useEffect(() => {
    if (loggedUser?.user) {
      checkBalance();
      getUserDetail().then((res) => {
        setUser(res);
      });
    }
  }, []);

  const statusObj = {
    1: "active",
    2: "cancelled",
    3: "completed",
  };
  const [team, selectedTeam] = useState(null);

  const handleCancel = () => {
    selectedTeam(null);
    setShowPaymentModal(false);
  };
  const handleSubmit = async ({ payActiveIndex, bonus }) => {
    try {
      const isOpenChallenge = challenge.challenge_mode == 1;
      const isInvite = challenge?.action_type?.team_id;

      if (isOpenChallenge) {
        // means accept invite by creator member
        if (isInvite) {
          await closedChallengeAccept({
            challengeId: challenge?.action_type.match,
            body: {
              ...challenge.action_type,
              is_paying_full: false,
              bonus,
              team_id: challenge?.action_type?.team_id,
              action: "accept",
            },
          });
          setEntryFeeSubtract(challenge.entry_fee);
        }
        // challenge accept by opponent team so it will select new team
        else {
          const isPayingFull = payActiveIndex == 1 ? true : false;
          await openChallengeAccept({
            challengeId: challenge.id,
            body: {
              is_paying_full: isPayingFull,
              bonus,
              team_id: team.id,
              action: "accept",
            },
          });
          setEntryFeeSubtract(
            isPayingFull ? challenge.entry_fee * 5 : challenge.entry_fee
          );
        }
      }
      // since closed challenge it means it will accept by opponent creator or member or challenge creator member
      else {
        const isPayingFull = !challenge?.action_type?.leader
          ? false
          : payActiveIndex == 1
          ? true
          : false;
        await closedChallengeAccept({
          challengeId: challenge.id,
          body: {
            ...challenge.action_type,
            action: "accept",
            is_paying_full: isPayingFull,
            bonus,
          },
        });
        setEntryFeeSubtract(
          isPayingFull ? challenge.entry_fee * 5 : challenge.entry_fee
        );
      }
      setShowPaymentModal(false);
      setChallengeAcceptConfirmModal(true);
    } catch (error) {}
  };

  const openAddToWallet = () => {
    setAddToWallet(true);
  };

  const closeAddToWallet = () => {
    setAddToWallet(false);
  };

  const declineChallenge = async () => {
    try {
      const res = await closedChallengeReject({
        challengeId: challenge.id,
        body: {
          ...challenge.action_type,
          action: "decline",
          is_paying_full: false,
          bonus: false,
        },
      });
      if (res) {
        if (challenge?.action_type?.leader) {
          toastify("success", "Challenge declined sucessfully");
        } else {
          toastify("success", "Invite declined sucessfully");
        }
      }
      setShowDeclineModal(false);
      fetchChallenges();
    } catch (error) {}
  };

  const getTeamsList = async ({ game_id, mode }) => {
    try {
      const res = await getTeams({ game_id, mode });
      setPlayerTeams(res);
    } catch (error) {}
  };

  const handleAcceptChallenge = (challengeItem) => {
    setSelectedChallenge(challengeItem);
    getTeamsList({
      game_id: challengeItem.game,
      mode: challengeItem.mode,
    });
    setShowPaymentModal(true);
    if (
      !challengeItem?.action_type?.team_id && !challengeItem.team_leader
    ) {
      setShowTeamSelectModal(true);
    }
  };

  const handleDeclineChallenge = (challengeItem) => {
    setSelectedChallenge(challengeItem);
    setShowDeclineModal(true);
  };

  const deleteChallenge = async () => {
    try {
      const res = await deleteLobbyChallenge(challenge.id);
      setShowDeclineModal(false);
      setChallengeDelete(false);
      if (res) {
        toastify("success", "Challenge deleted successfully");
      }
      fetchChallenges();
    } catch (error) {}
  };

  const handleAcceptNoPay = async () => {
    try {
      setSpinnerLoader(true);
      await closedChallengeAccept({
        challengeId: challenge.id,
        body: {
          ...challenge.action_type,
          action: "accept",
          is_paying_full: false,
          bonus: false,
        },
      });
      setShowPaymentModal(false);
      setShowAcceptModal(false);
      setSpinnerLoader(false);
      setChallengeAcceptConfirmModal(true);
    } catch (error) {
      setSpinnerLoader(false);
    }
  };

  const handleNoPay = (challengeItem) => {
    setSelectedChallenge(challengeItem);
    setShowAcceptModal(true);
  };

  const checkBalance = () => {
    getWalletBalance().then((res) => {
      setwalletBalance(res.wallet_balance);
    });
  };

  return (
    <>
      {challengeAcceptConfirmModal ? (
        <>
          <Modal
            show={true}
            onHide={() => {
              fetchChallenges();
              checkBalance();
              setChallengeAcceptConfirmModal(false);
            }}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="money-added">
              <div className="tick-image">
                <img src="/assets/images/tick.svg" alt="" />
              </div>
              <h3 className="challengeCreated">
                {(
                  challenge.challenge_mode == 2
                    ? challenge?.action_type?.leader
                    : !challenge.action_type.team_id
                )
                  ? "Challenge"
                  : "Invite"}{" "}
                Accepted !
              </h3>

              <p className="challengeBetween">
                Congratulations, your have successfully accepted the{" "}
                {(
                  challenge.challenge_mode == 2
                    ? challenge?.action_type?.leader
                    : !challenge.action_type.team_id
                )
                  ? "challenge"
                  : "invite"}{" "}
              </p>

              <p>Wallet Balance</p>

              <h4>${(walletBalance - entryFeeSubtract)?.toFixed(2)}</h4>
            </Modal.Body>
          </Modal>
        </>
      ) : null}

      {showPaymentModal ? (
        addtoWallet ? (
          <Modal
            show={true}
            onHide={closeAddToWallet}
            contentClassName="qr-verification-wrapper"
          >
            <AddToWallet
              gameName={
                games.filter((game) => challenge.game == game.id)?.[0]?.name
              }
            />
          </Modal>
        ) : showTeamSelectModel ? (
          <Modal
            show={true}
            onHide={() => {
              setShowPaymentModal(false);
              setShowTeamSelectModal(false);
              selectedTeam(null);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Team</Modal.Title>
            </Modal.Header>

            <Modal.Body className="make-payment mt-4">
              <Link
                className="redirect-link mb-2"
                to={{
                  pathname: `/myprofile`,
                  state: {
                    createTeam: true,
                    mode: challenge?.mode,
                    game: challenge?.game,
                  },
                }}
              >
                + Create New Team
              </Link>
              <Select
                className="react-select-container-teammates chooseTeam mt-2"
                classNamePrefix="react-select-teammates"
                placeholder="Choose Team"
                isSearchable
                onChange={(teamToBeSelected) => {
                  selectedTeam(teamToBeSelected);
                }}
                value={team}
                options={playerTeams.map((teamItem) => ({
                  ...teamItem,
                  value: teamItem.id,
                  label: teamItem.name,
                }))}
              />
            </Modal.Body>
            <Modal.Footer className="total-money total delete-footer registerCancelBtn">
              <Button
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(false);
                  setShowTeamSelectModal(false);
                  selectedTeam(null);
                }}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                disabled={!team?.id}
                variant="primary"
                onClick={() => {
                  setShowPaymentModal(true);
                  setShowTeamSelectModal(false);
                }}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal show={true} onHide={handleCancel}>
            <PaymentComponent
              entry_fee={challenge.entry_fee}
              mode={challenge.mode}
              selectedGame={
                games.filter((game) => challenge.game == game.id)?.[0]
              }
              free_lobby={false}
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              openAddToWallet={openAddToWallet}
              creatorPay={
                challenge.mode == 1
                  ? false
                  : challenge.challenge_mode == 1 &&
                    !challenge?.action_type?.team_id
                  ? true
                  : challenge?.action_type.team_id
                  ? challenge.action_type?.leader
                  : true
              }
            />
          </Modal>
        )
      ) : null}

      {showDeclineModal ? (
        <Modal
          show
          onHide={() => {
            setChallengeDelete(null);
            setShowDeclineModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Decline Challenge?</p>
              <p className="leave-modal-text">
                Are you sure you want to{" "}
                {challengeDelete ? "delete" : "decline"} the challenge?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <Button
              className="cancel-btn"
              onClick={() => {
                setChallengeDelete(null);
                setShowDeclineModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="yes-btn"
              onClick={() =>
                challengeDelete ? deleteChallenge() : declineChallenge()
              }
            >
              {spinnerLoader ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {showAcceptModal ? (
        <Modal show onHide={() => setShowAcceptModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Accept Invitation?</p>
              <p className="leave-modal-text">
                Are you sure you want to accept the team's invitation?
              </p>
              <div className="d-flex justify-content-evenly participantItemGamer">
                <div>
                  <p>Game's Name</p>
                  <h6 style={{ color: "white" }}>{selectedGame?.name}</h6>
                </div>
                <div className="displayGamerTag">
                  <p>Display Name </p>{" "}
                  <div className="gamertag-edit">
                    <h6>{gamertag ? gamertag : "-"}</h6>
                    <Link
                      to={{
                        pathname: "/myProfile",
                        data: "settingsTab",
                        settingsKey: "gameIds",
                      }}
                      className="ml-4"
                    >
                      <img
                        src="/assets/images/edit-gamertag-payment-popup.svg"
                        alt="edit-icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer">
            <div
              className="pb-3"
              data-tip
              data-for="loginTip"
              style={{ cursor: "pointer" }}
            >
              <span class="info-icon">
                <img
                  src="/assets/images/info-icon.svg"
                  alt="info"
                  class="info-image"
                />
                <span className="gamerTagInfoMessage">
                  {gamerTagInfo(selectedGame)}
                </span>
              </span>
              <div className="helper-image-container enrollHelperImage">
                <ReactTooltip id="loginTip" place="top" effect="solid">
                  <img src={selectedGame?.helper_image} alt="helper-image" />
                </ReactTooltip>{" "}
              </div>
            </div>
            <Button
              className="cancel-btn"
              onClick={() => setShowAcceptModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={handleAcceptNoPay}>
              {spinnerLoader ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      <div className="stats-wrapper">
        <table style={{ color: "white" }}>
          <tr>
            <th>GAME</th>
            <th>ENTRY</th>
            <th>TEAM MODE</th>
            <th>COMPETITION</th>
            <th>START DATE</th>
            <th>START TIME</th>
            <th>STATUS</th>
            <th>ACTIONS</th>
          </tr>
          {challenges.length === 0 ? (
            <h5 style={{ textAlign: "center", padding: "20px" }}>
              No {statusObj[status]} challenges found
            </h5>
          ) : (
            challenges?.map((challengeItem, idx) => {
              return (
                <ChallengeTableCard
                  key={challengeItem?.id}
                  challenge={challengeItem}
                  games={games}
                  handleAcceptChallenge={handleAcceptChallenge}
                  handleDeclineChallenge={handleDeclineChallenge}
                  handleNoPay={handleNoPay}
                />
              );
            })
          )}
        </table>
      </div>
    </>
  );
};

export default ChallengesTable;
