import React from "react";
import { Modal } from "react-bootstrap";

function SubmitClip({show,handleClose}) {
  return (
    <Modal show={show} onHide={handleClose} className="submit-clip">
      <Modal.Header closeButton>
        <Modal.Title className="profile-information-header">
          {" "}
          Submit a clip
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" submit-header">
        <h5>
          Upload and send the clip you wish to be considered to
          <a href="mailto:community@ryvals.com"> community@ryvals.com </a> Be
          sure to include your socials.
        </h5>
      </Modal.Body>
    </Modal>
  );
}

export default SubmitClip;
