import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountDownChallenges from "./ChallengeCountdown";
import CountDown from "./CountDown";
import CountDownTeams from "./CountDownTeams";

function ChallengeTableCard({
  challenge,
  games,
  handleDeclineChallenge,
  handleAcceptChallenge,
  handleNoPay,
}) {
  const [isTimerReached, setTimerReached] = useState(false);
  const [enrollTeamTimer, setEnrollTeamTimer] = useState(false);
  const [canEnroll, setCanEnroll] = useState(true);
  const [canModify, setCanModify] = useState(true);

  const ChallengeCountdownEnded = (isComplete, id) => {
    setTimerReached(isComplete);
  };

  return (
    <tr key={challenge.id}>
      <td>{games.filter((game) => challenge.game == game.id)?.[0]?.name}</td>
      <td>${challenge.entry_fee}</td>
      <td>{challenge.modetag}</td>
      <td>
        {challenge.challenge_type == 1 ? "Open Challenge" : challenge.versus}
      </td>
      <td>{challenge.match_date}</td>
      <td>{challenge.match_time}</td>
      <td>
        {challenge.challenge_mode == 3 ||
        challenge.challenge_mode == 4 ||
        challenge.challenge_mode == 5 ||
        isTimerReached ? (
          challenge.challenge_mode == 3 ? (
            "Active"
          ) : challenge.challenge_mode == 4 ? (
            "Cancelled"
          ) : challenge.challenge_mode == 5 ? (
            "Completed"
          ) : (
            "Active"
          )
        ) : (
          <div className="challengeStatus">
            <CountDown
              server_current_datetime={challenge?.countdown_time}
              time_reached={(e) => {
                ChallengeCountdownEnded();
              }}
              stopEnrollTeam={(isComplete) => {
                if (!canEnroll) {
                  setCanEnroll(true);
                }
              }}
            />
            <CountDownTeams
              challengeList={true}
              server_current_datetime={challenge?.countdown_time}
              owner={false}
              setCanModify={setCanModify}
              setCanReplace={() => {}}
            />
          </div>
        )}
      </td>

      <td>
        {challenge.creator ? null : challenge.enrolled ? (
          <Button
            className="actionsChallenge mr--10 acceptedChallenge"
            variant="primary"
            disabled={true}
          >
            Accepted
          </Button>
        ) : (
          <>
            {!(
              challenge.challenge_mode == 3 ||
              challenge.challenge_mode == 4 ||
              challenge.challenge_mode == 5
            ) ? (
              <>
                <Button
                  className="actionsChallenge mr--10 timerReached"
                  variant="primary"
                  onClick={() => {
                    if (
                      challenge?.action_type?.team_id
                        ? challenge.payment_required
                        : true
                    ) {
                      handleAcceptChallenge(challenge);
                    } else {
                      handleNoPay(challenge);
                    }
                  }}
                  disabled={
                    challenge?.rejected &&
                    (challenge.team_leader || challenge.challenge_mode != 1)
                      ? true
                      : challenge?.action_type?.team_id
                      ? !canModify
                      : !canEnroll
                  }
                >
                  Accept
                </Button>
                {
                  !challenge?.action_type?.team_id  && !challenge.team_leader && challenge.challenge_mode == 1 ? null : (
                  <Button
                    className="actionsChallenge mr--10"
                    onClick={() => handleDeclineChallenge(challenge)}
                    variant="primary"
                    disabled={
                      challenge?.rejected
                        ? true
                        : challenge?.action_type?.team_id
                        ? !canModify
                        : !canEnroll
                    }
                  >
                    {challenge.rejected ? "Declined" : "Decline"}
                  </Button>
                )}
              </>
            ) : null}
          </>
        )}

        <Link
          to={{
            pathname: window.location.pathname + "/versus/" + challenge.id,
            data: challenge,
          }}
          className="enroll-button"
        >
          <Button className="actionsChallenge" variant="primary">
            View Details
          </Button>
        </Link>
      </td>
    </tr>
  );
}

export default ChallengeTableCard;
