import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import toastify from "../../helper/toastify.jsx";
import {
  cancelFriendRequest,
  getFriendLists,
  getSearchData,
  removeFriendFromList,
  replaceFavFriend,
  sendAddTofavourites,
  sendDelfavourites,
  sendFriendRequest,
} from "../../services/user.service";
import Paginator from "../../helper/paginator/Paginator";
const Friends = (props) => {
  useEffect(() => {
    FetchFriends();
  }, []);
  const [friendData, setFriendData] = useState([]);
  const [friendCount, setFriendCount] = useState(0);
  const [maxFavFriends, setMaxFavFriends] = useState(0);
  const [favFriendList, setFavFriendList] = useState(null);
  const [ascFriendUsername, setAscFriendUsername] = useState(true);
  const [ascFriendSince, setAscFriendSince] = useState(true);

  const FetchFriends = async (data) => {
    let filter = {
      isPaginated: true,
      pageNum: data === 0 ? 1 : pageNumFriends,
      pageSize: 25,
    };
    await getFriendLists(filter)
      .then((res) => {
        const favouriteFriends = res.results.filter(
          (value) => value.favourite === true
        );
        setFriendData(res.results);
        setFriendCount(res.total);
        setMaxFavFriends(res.max_fav);
        setFavFriendList(favouriteFriends);
      })
      .catch((err) => {});
  };
  const [searchText, setSearchText] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestedList, setSuggestedList] = useState(null);
  const [showFriendTable, setShowFriendTable] = useState(false);
  const [friendRequestCount, setFriendRequestCount] = useState(0);
  const [showSearchedList, setSearchedList] = useState(null);
  const [hideAllYourFriendsList, setHideAllYourFriendsList] = useState(false);
  const [spinnerLoaderForFriendTable, setSpinnerLoaderForFriendTable] =
    useState(false);
  const [disableAddFriendButton, setDisableAddFriendButton] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);

  const [pageNumberFriendTable, setPageNumberFriendTable] = useState(1);

  const [openReplaceFavouriteModal, setOpenReplaceFavouriteModal] =
    useState(false);
  const [newFavId, setNewFavId] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeFriend, setRemoveFriend] = useState(null);
  const searchHandler = async (e) => {
    var text = e.target.value;
    setSearchText(text);
    let filter = {
      isPaginated: true,
      searchTerm: text,
      pageNumber: pageNumberFriendTable,
      pageSize: 25,
    };

    await getSearchData(filter)
      .then((res) => {
        setSuggestedList(text.length === 0 ? null : res.results);
        setShowSuggestion(text.length === 0 ? false : true);
        setShowFriendTable(false);
        setFriendRequestCount(res.total);
        setSearchedList(text.length === 0 ? null : res.results);
      })
      .catch((err) => {
        setShowSuggestion(false);
        setShowFriendTable(false);
      });
    if (text.length === 0) {
      setHideAllYourFriendsList(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === " " && searchText.length === 0) {
      e.preventDefault();
    }
  };
  const showSearchResultInTable = async () => {
    setShowSuggestion(false);
    setShowFriendTable(true);
    setHideAllYourFriendsList(true);
    setSpinnerLoaderForFriendTable(true);
    setTimeout(() => {
      setSpinnerLoaderForFriendTable(false);
    }, 2000);
  };
  const handleFriend = async (id, key, data) => {
    if (data.disable_add_friend) {
      toastify(
        "error",
        "Sorry this account is private and not accepting requests at this time"
      );
    } else {
      setDisableAddFriendButton(key);

      await sendFriendRequest(id)
        .then((res) => {
          toastify("success", res.success);
          reCallSearch();
          setDisableAddFriendButton(null);
        })
        .catch((err) => {
          setShowSuggestion(false);
          setShowFriendTable(false);
          setHideAllYourFriendsList(false);
          setSpinnerLoaderForFriendTable(true);
          setDisableAddFriendButton(null);
        });
    }
  };
  const handleCancelFriend = async (id, key) => {
    setDisableCancelButton(key);
    await cancelFriendRequest(id)
      .then((res) => {
        toastify("success", res.success);
        setDisableCancelButton(null);
        reCallSearch();
      })
      .catch((err) => {
        setShowSuggestion(false);
        setShowFriendTable(false);
        setHideAllYourFriendsList(false);
        setDisableCancelButton(null);
      });
  };
  const reCallSearch = async () => {
    let filter = {
      isPaginated: true,
      searchTerm: searchText,
      pageNumber: pageNumberFriendTable,
      pageSize: 25,
    };
    await getSearchData(filter)
      .then((res) => {
        setFriendRequestCount(res.total);
        setSearchedList(searchText.length === 0 ? null : res.results);
        setSuggestedList(searchText.length === 0 ? null : res.results);
      })
      .catch((err) => {
        setShowSuggestion(false);
        setShowFriendTable(false);
        setHideAllYourFriendsList(false);
      });
  };

  const handlePageClickFriendsTable = async (pageNum) => {
    setPageNumberFriendTable(pageNum);
    setSpinnerLoaderForFriendTable(true);
    let filter = {
      isPaginated: true,
      searchTerm: searchText,
      pageNumber: pageNum,
      pageSize: 25,
    };

    await getSearchData(filter)
      .then((res) => {
        setSpinnerLoaderForFriendTable(false);
        setSearchedList(res.results);
        setFriendRequestCount(res.total);
      })
      .catch((err) => {
        setSpinnerLoaderForFriendTable(false);
        setShowSuggestion(false);
        setShowFriendTable(false);
      });
  };
  const addTofav = async (id) => {
    if (favFriendList.length >= maxFavFriends) {
      showReplaceMod(id);
    } else {
      await sendAddTofavourites(id)
        .then((res) => {
          toastify("success", "Player added as favorite");
          FetchFriends();
        })
        .catch((err) => {});
    }
  };
  const delFav = async (id) => {
    await sendDelfavourites(id)
      .then((res) => {
        toastify("success", "Player removed from favorite");
        FetchFriends();
      })
      .catch((err) => {});
  };
  const showReplaceMod = (id) => {
    setOpenReplaceFavouriteModal(true);
    setNewFavId(id);
  };
  const hideReplaceMod = () => {
    setOpenReplaceFavouriteModal(false);
  };
  const showRemoveMod = (data, id) => {
    setShowRemoveModal(true);
    setRemoveFriend({
      friendUsername: data,
      friendId: id,
    });
  };
  const hideRemoveMod = () => {
    setShowRemoveModal(false);
  };
  const handleRemoveFriend = async (data) => {
    await removeFriendFromList(data)
      .then((res) => {
        toastify("success", res.success);
        FetchFriends();
        hideRemoveMod();
      })
      .catch((err) => {});
  };
  const [pageNumFriends, setPageNumFriends] = useState(1);
  const handlePageClickFriends = async (pageNum) => {
    setPageNumFriends(pageNum);
    let filter = {
      isPaginated: true,
      pageNum: pageNum,
      pageSize: 25,
    };
    await getFriendLists(filter).then((res) => {
      setFriendData(res.results);
      setFriendCount(res.total);
    });
  };
  const [replaceFriendLoading, setReplaceFriendLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleReplaceFavFriend = async (e) => {
    e.preventDefault();
    let data = { user_id: newFavId };
    if (pageNumFriends > 1) {
      setReplaceFriendLoading(true);
      await replaceFavFriend(selectedOption, data)
        .then((res) => {
          toastify("success", "Player removed from favorites successfully");
          FetchFriends(0);
          hideReplaceMod();
          setReplaceFriendLoading(false);
        })
        .catch((err) => {
          setReplaceFriendLoading(false);
        });
    } else {
      setReplaceFriendLoading(true);
      await replaceFavFriend(selectedOption, data)
        .then((res) => {
          toastify("success", "Player removed from favorites successfully");
          FetchFriends();
          hideReplaceMod();
          setReplaceFriendLoading(false);
        })
        .catch((err) => {
          setReplaceFriendLoading(false);
        });
    }
  };
  const handleOptionChange = (changeEvent) => {
    setSelectedOption(changeEvent.target.value);
  };
  const requestSortAsecFriendUsername = async () => {
    let filter = {
      isPaginated: true,
      pageNum: pageNumFriends,
      pageSize: 25,
      order_by: "from_user__username",
    };
    await getFriendLists(filter)
      .then((res) => {
        if (res.page === 1) {
          const favouriteFriends = res.results.filter(
            (value) => value.favourite === true
          );
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setFavFriendList(favouriteFriends);
          setAscFriendUsername(false);
        } else {
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setAscFriendUsername(false);
        }
      })
      .catch((err) => {});
  };
  const requestSortDsecFriendUsername = async () => {
    let filter = {
      isPaginated: true,
      pageNum: pageNumFriends,
      pageSize: 25,
      order_by: "-from_user__username",
    };
    await getFriendLists(filter)
      .then((res) => {
        if (res.page === 1) {
          const favouriteFriends = res.results.filter(
            (value) => value.favourite === true
          );
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setFavFriendList(favouriteFriends);
          setAscFriendUsername(true);
        } else {
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setAscFriendUsername(true);
        }
      })
      .catch((err) => {});
  };
  const requestSortAsecFriendSince = async () => {
    let filter = {
      isPaginated: true,
      pageNum: pageNumFriends,
      pageSize: 25,
      order_by: "created",
    };
    await getFriendLists(filter)
      .then((res) => {
        if (res.page === 1) {
          const favouriteFriends = res.results.filter(
            (value) => value.favourite === true
          );
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setFavFriendList(favouriteFriends);
          setAscFriendSince(false);
        } else {
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setAscFriendSince(false);
        }
      })
      .catch((err) => {});
  };
  const requestSortDsecFriendSince = async () => {
    let filter = {
      isPaginated: true,
      pageNum: pageNumFriends,
      pageSize: 25,
      order_by: "-created",
    };
    await getFriendLists(filter)
      .then((res) => {
        if (res.page === 1) {
          const favouriteFriends = res.results.filter(
            (value) => value.favourite === true
          );
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setFavFriendList(favouriteFriends);
          setAscFriendSince(true);
        } else {
          setFriendData(res.results);
          setFriendCount(res.total);
          setMaxFavFriends(res.max_fav);
          setAscFriendSince(true);
        }
      })
      .catch((err) => {});
  };

  const seeAll = () => {
    setShowSuggestion(false);
    setShowFriendTable(true);
    setHideAllYourFriendsList(true);
    setSpinnerLoaderForFriendTable(true);
    setTimeout(() => {
      setSpinnerLoaderForFriendTable(false);
    }, 2000);
  };
  return (
    <div>
      <div className="wallet-funds-wrapper search-friends searchGoto">
        <input
          className="form-control search-bar"
          type="search"
          placeholder="Search friends"
          value={searchText}
          onChange={(e) => searchHandler(e)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <span>
          <button
            className="invite-btn search-goto-btn"
            onClick={() => showSearchResultInTable()}
            disabled={searchText.length === 0 ? true : false}
          >
            Search
          </button>
        </span>

        {showSuggestion ? (
          <p className="suggestive-listing">
            {suggestedList === null || suggestedList.length === 0 ? (
              <h5
                style={{
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                No result found
              </h5>
            ) : (
              <p>
                {suggestedList.map((data, idx) => {
                  return (
                    <div className="suggestive-search">
                      <Link
                        className="profileFriend"
                        to={{
                          pathname: `/player-profile/${data.id}`,
                          state: {
                            id: data.id,
                            // fav: this.state.favFriendList,
                            friend: data.friend,
                            // maxFav: this.state.maxFavFriends,
                            pending: data.request,
                          },
                        }}
                      >
                        {data.username}
                      </Link>
                      {!data.friend ? (
                        <>
                          <Button
                            className="invite-btn search-addBtn"
                            onClick={() => handleFriend(data.id, idx, data)}
                            disabled={
                              data.request
                                ? true
                                : disableAddFriendButton === idx
                                ? true
                                : false
                            }
                          >
                            {data.request ? "Pending" : "Add friend"}
                          </Button>
                          {data.request ? (
                            <Button
                              className="invite-btn search-addBtn"
                              onClick={() => handleCancelFriend(data.id, idx)}
                              disabled={
                                disableCancelButton === idx ? true : false
                              }
                            >
                              Cancel request
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {friendRequestCount >= 5 ? (
                  <Button
                    className="invite-btn search-seeAll"
                    onClick={() => seeAll()}
                  >
                    see all
                  </Button>
                ) : null}
              </p>
            )}
          </p>
        ) : (
          ""
        )}
        {showFriendTable ? (
          <>
            <table style={{ color: "white" }} className="suggestiveSearch-box">
              <tr>
                <th>USERNAME</th>
                <th>ACTION</th>
              </tr>
              {spinnerLoaderForFriendTable ? (
                <Spinner animation="grow" />
              ) : friendRequestCount === 0 ? (
                <h5
                  style={{
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  No result found
                </h5>
              ) : (
                showSearchedList.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <Link
                          className="profileFriend"
                          to={{
                            pathname: `/player-profile/${data.id}`,
                            state: {
                              id: data.id,
                              friend: data.friend,
                              pending: data.request,
                            },
                          }}
                        >
                          {data.username}
                        </Link>
                      </td>
                      <td>
                        {!data.friend ? (
                          <>
                            <Button
                              className="invite-btn search-addBtn"
                              onClick={() => handleFriend(data.id, idx, data)}
                              disabled={
                                data.request
                                  ? true
                                  : disableAddFriendButton === idx
                                  ? true
                                  : false
                              }
                            >
                              {data.request ? "Pending" : "Add friend"}
                            </Button>
                            {data.request ? (
                              <Button
                                className="invite-btn search-addBtn"
                                onClick={() => handleCancelFriend(data.id, idx)}
                                disabled={
                                  disableCancelButton === idx ? true : false
                                }
                              >
                                Cancel request
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </table>

            <div>
              {/* {isPaginatedFriendsTable ? ( */}
              <Paginator
                totalCount={friendRequestCount}
                //pageSize={this.state.pageSizeFriendTable}
                pageSize={25}
                pageClick={handlePageClickFriendsTable}
              />
              {/* ) : null} */}
            </div>
          </>
        ) : null}
      </div>
      {hideAllYourFriendsList ? null : (
        <>
          {friendCount !== 0 ? (
            <>
              <table style={{ color: "white" }}>
                <tr>
                  <th>PROFILE’S PICTURE</th>
                  <th>
                    Friend's Username
                    {ascFriendUsername ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecFriendUsername()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDsecFriendUsername()}
                      />
                    )}
                  </th>

                  <th>
                    FRIEND SINCE
                    {ascFriendSince ? (
                      <img
                        src="/assets/images/sort-ascending-desc.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortAsecFriendSince()}
                      />
                    ) : (
                      <img
                        src="/assets/images/descending.svg"
                        alt="Sorting"
                        style={{ cursor: "pointer" }}
                        className="sorting"
                        onClick={() => requestSortDsecFriendSince()}
                      />
                    )}
                  </th>
                  <th>ACTION</th>
                </tr>
                {friendData &&
                  friendData.map((data, idx) => {
                    return (
                      <tr
                        key={idx}
                        className={data.favourite ? "friendBackground" : ""}
                      >
                        <td className="friendsProfilepic">
                          <img
                            src={
                              data?.profile === null
                                ? "/assets/images/profile-img.jpg"
                                : data?.profile === "null" ||
                                  data?.profile === ""
                                ? "/assets/images/profile-img.jpg"
                                : data.profile
                            }
                            alt="Image"
                            className="profilepic"
                          />
                        </td>
                        <td>
                          <Link
                            className="profileFriend"
                            to={{
                              pathname: `/player-profile/${data.id}`,
                              state: {
                                id: data.id,
                                friend: true,
                                pending: false,
                              },
                            }}
                          >
                            {data.username}
                          </Link>
                        </td>
                        <td>
                          <p>
                            {new Date(data.create_date).toLocaleString(
                              "en-US",
                              {
                                //weekday: "short", // long, short, narrow
                                day: "numeric", // numeric, 2-digit
                                year: "numeric", // numeric, 2-digit
                                month: "long", // numeric, 2-digit, long, short, narrow
                              }
                            )}
                          </p>
                        </td>
                        <td>
                          {data.favourite ? (
                            <img
                              src="/assets/images/Favourites.svg"
                              alt="Favorite"
                              className="fav-icon cursorPointer"
                              onClick={() => delFav(data.id)}
                              data-tip
                              data-for="add-favourite"
                            />
                          ) : (
                            <img
                              src="/assets/images/fav-fill.svg"
                              alt="Favorite"
                              className="fav-icon cursorPointer"
                              onClick={() => addTofav(data.id)}
                              data-tip
                              data-for="add-favourite"
                            />
                            
                          )}
                          <ReactTooltip
                            id="add-favourite"
                            place="bottom"
                            effect="solid"
                          >
                            Add to Favorite
                          </ReactTooltip>
                          <img
                            src="/assets/images/delete-icon.svg"
                            alt="Delete"
                            className="deleteicon cursorPointer"
                            data-tip
                            data-for="delete-Friend"
                            onClick={() =>
                              showRemoveMod(data.username, data.id)
                            }
                          />
                           <ReactTooltip
                            id="delete-Friend"
                            place="bottom"
                            effect="solid"
                          >
                            Delete my Friend
                          </ReactTooltip>
                        </td>
                      </tr>
                    );
                  })}
              </table>
              <div>
                {/* {this.state.isPaginatedFriends ? ( */}
                <Paginator
                  totalCount={friendCount}
                  pageSize={25}
                  pageClick={handlePageClickFriends}
                />
                {/* ) : null} */}
              </div>
            </>
          ) : (
            <h5
              style={{
                textAlign: "center",
                padding: "20px",
              }}
            >
              No Friend(s) Added yet{" "}
            </h5>
          )}
        </>
      )}
      {/* Remove pop up */}
      <Modal show={showRemoveModal} onHide={() => hideRemoveMod()}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-body text-center">
          <div className="refer-friend-icons">
            <img src="/assets/images/tick-mark-icon.svg" alt="gift-icon" />
            <p className="remove-heading invite-para">Remove</p>
            <p className="remove-text">
              Are you sure you want to remove{" "}
              {removeFriend === null ? "" : removeFriend.friendUsername} as a
              friend?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="delete-footer">
          <Button className="cancel-btn" onClick={() => hideRemoveMod()}>
            Cancel
          </Button>
          <Button
            className="yes-btn"
            onClick={() =>
              handleRemoveFriend(
                removeFriend === null ? "" : removeFriend.friendId
              )
            }
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openReplaceFavouriteModal}
        onHide={() => hideReplaceMod()}
        backdrop="static"
      >
        <form onSubmit={handleReplaceFavFriend}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <h3>
              Choose a Friend with who you would replace the new favourite
            </h3>

            {favFriendList &&
              favFriendList.map((data, id) => {
                return (
                  <Form.Group
                    className="mb-3 d-flex justify-content-between"
                    controlId="formBasicCheckbox"
                  >
                    <label className="favouritePopup">{data.username}</label>
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      className="friendPopup"
                      value={data.id}
                      defaultChecked={selectedOption === data.id}
                      onChange={handleOptionChange}
                      name="ReplaceFriend"
                    />
                  </Form.Group>
                );
              })}
          </Modal.Body>

          <Modal.Footer className="justify-content-around">
            <Button className="cancel-btn" onClick={() => hideReplaceMod()}>
              Cancel
            </Button>
            <Button
              className="yes-btn"
              type="submit"
              disabled={
                selectedOption === null || replaceFriendLoading ? true : false
              }
            >
              {replaceFriendLoading && (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              )}
              Yes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
Friends.propTypes = {
  FetchUserData: PropTypes.func,
};
export default Friends;
