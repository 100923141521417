import React, { useState, Fragment } from "react";
import { withRouter } from "react-router";
import { Alert } from "react-bootstrap";
import { requestverification } from "../../services/authService";
import { STRINGS_CONSTANTS } from "../../helper/stringConstants";
const SignUpSuccess = (props) => {
  const [isReverifyLoading, setisReverifyLoading] = useState(false);
  const [isEmailSent, setisEmailSent] = useState(false);
  const [isEmailSentError, setisEmailSentError] = useState("");
  const hideEmail = props.location.state.email;
  let a = hideEmail.split("@");
  let b = a[0];
  let newstr = "";
  for (let i in b) {
    if (b.length <= 5) {
      if (i > 1) newstr += "*";
      else newstr += b[i];
    } else if (i > 1 && i < b.length - 2) newstr += "*";
    else newstr += b[i];
  }
  const newEmail = newstr + "@" + a[1];
  const resendLink = () => {
    setisEmailSent(false);
    setisEmailSentError("");
    setisReverifyLoading(true);
    requestverification(props.location.state.email)
      .then(() => {
        setisEmailSent(true);
        setisReverifyLoading(false);
      })
      .catch((err) => {
        setisEmailSent(false);
        setisEmailSentError(err);
        setisReverifyLoading(false);
      });
  };

  const getEmailErrorRender = () => {
    if (isEmailSentError?.length)
      return (
        <Alert variant="danger" className="mt-4">
          <p style={{ color: "black" }}>
            {newEmail}
            <br></br>
            {isEmailSentError}
          </p>
        </Alert>
      );
    return null;
  };

  return (
    <Fragment>
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <div className="contact-form--1">
            <div className="container">
              <div className="row row--35 d-flex justify-content-center">
                <div className="col-lg-6 form-class image-class signup-image">
                  {/* <AuthWrapper /> */}
                  <div className="section-title text-left mb--50">
                    <div className="tick-image discordNewImage">
                      <img
                        src="/assets/images/discord-image.svg"
                        alt="discord-image"
                      />
                    </div>
                    <h2 className="title sign-in-title signup-successful">
                      Sign Up<span>For Discord</span>
                    </h2>
                    <h3 className="thankyou">Join the Ryvals</h3>
                    <h3 className="thankyou"> community!</h3>
                    <p className="verify discord-content">
                      The Discord is used to send out lobby codes prior to
                      matches, as well as for support. You can also find info
                      about giveaways and community events here.
                    </p>
                    <a
                      href="https://discord.com/invite/Nr4xmYZzQa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="rn-button-style--2 btn-solid mt-4"
                        type="button"
                        id="mc-embedded-subscribe"
                      >
                        Ryvals Discord Sign Up
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 form-class signup-success">
                  <div className="section-title text-left mb--50">
                    <div className="tick-image">
                      <img src="/assets/images/tick.svg" alt="tick" />
                    </div>
                    <h2 className="title sign-in-title signup-successful">
                      {STRINGS_CONSTANTS.signup}
                      <span>Successful!</span>
                    </h2>
                    <h3 className="thankyou">Thank You</h3>
                    <h3 className="thankyou signupEmail">{newEmail}</h3>
                    <p className="verify">
                      An account verification email has been sent to your
                      registered email address. Please verify your email to
                      complete your account setup. If you don’t see the email,
                      please check your spam.
                    </p>
                    <button
                      className="rn-button-style--2 btn-solid mt-4"
                      type="button"
                      id="mc-embedded-subscribe"
                      disabled={isReverifyLoading}
                      onClick={resendLink}
                    >
                      {isReverifyLoading && (
                        <span className="spinner-border spinner-border-sm mr-5 ml-5"></span>
                      )}{" "}
                      Resend Verification Link
                    </button>
                    {isEmailSent ? (
                      <Alert variant="success" className="mt-4">
                        <p style={{ color: "black" }}>
                          {newEmail}
                          <br></br>
                          {STRINGS_CONSTANTS.accountVerificationMail}
                        </p>
                      </Alert>
                    ) : getEmailErrorRender()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(SignUpSuccess);
