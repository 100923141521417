import moment from "moment";
import {
  CARD_BACKGROUNDS_PATHS,
  ERROR_MESSAGES,
  VALIDATIONS_ERROR,
} from "./stringConstants";
import * as Yup from "yup";

export const isTodayorTommorrow = (date) => {
  const today = moment().endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");

  //   0 -> Today , 1->  Yesteeday , -1 else
  if (moment(date).endOf("day").format() === today.format()) return 0;
  if (moment(date).endOf("day").format() === yesterday.format()) return 1;
  else return -1;
};

export const dateStringTodayTommorrow = (date) => {
  const isTodayorTommrowIndex = isTodayorTommorrow(date);
  if (isTodayorTommrowIndex === 0)
    return "Today, " + moment(date).format("h:mm A");
  if (isTodayorTommrowIndex === 1)
    return "Yesterday, " + moment(date).format("h:mm A");
  else return moment(date).format("DD MMM YYYY, h:mm A");
};

export const AddTeamMatefilterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  stringify: (option) =>
    `${option.label} ${option.data?.email} ${
      option.data?.first_name + " " + option.data?.last_name
    }`,
  trim: true,
};

export const convertTeamMatesToOptions = (arr = []) =>
  arr
    .map((item) => ({
      ...item,
      label: item.username,
      value: item?.id ? item?.id : item?.uid,
    }))
    .filter((item) => item?.is_available);

export const mask = (field) => {
  return new Array(field.length - 1).fill("*").join("");
};

export const gameEnum = {
  "apex-legends": 0,
  pubg: 1,
};
export const gameEnumFromValue = {
  0: "apex-legends",
  1: "pubg",
};
export const minusEighteen = () => {
  let eighteenYearsAgo = new Date();
  let date = new Date(eighteenYearsAgo),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear() - 18, mnth, day].join("-");
};
export const logoutEventPublicRoutes = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "logout-event") {
        window.location.replace("/signin");
      }
    },
    false
  );
};
export const logoutEventPrivateRoutes = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "logout-event") {
        window.location.reload();
      }
    },
    false
  );
};
export const enrollEventListener = () => {
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "enroll-event") {
        window.location.reload();
      }
    },
    false
  );
};

export const gamerTagInfo = (game) => {
  const clanMessage = game?.name == "Apex Legends";
  return game
    ? `* Please make sure your ${
        game?.label ? game?.label : game?.gaming_account_name
      } gamertag ${
        game.slug == "valorant" || game.slug == "call-of-duty-warzone"
          ? "(username#tagline)"
          : ""
      } on your Ryvals profile, matches your current in-game ${
        game?.name
      } name ${
        clanMessage ? "minus any clan tag" : ""
      } prior to lobby enrollment.`
    : null;
};
export const gameOptions = [
  { value: 0, label: "Apex", slugName: "apex-legends" },
  { value: 1, label: "Pubg", slugName: "pubg" },
];
export const lobbyFilter = [
  { value: "current", label: "Current lobbies" },
  { value: "previous", label: "Previously played lobbies" },
  { value: "enrolled", label: "Enrolled Lobbies", loginRequired: true },
];
export const lobbyAmountFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "$1" },
  { value: 5, label: "$5" },
];
export const lobbyModeFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
  { value: 3, label: "Trios" },
  { value: 4, label: "Quads" },
  { value: 5, label: "Penta" },
  { value: 6, label: "Hexa" },
];
export const challengeModeFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "1v1" },
  { value: 2, label: "2v2" },
  { value: 3, label: "3v3" },
  { value: 4, label: "4v4" },
  { value: 5, label: "5v5" },
  { value: 5, label: "6v6" },
];

export const lobbyModeFiltersForChallengesValorant = [
  { value: 5, label: "5v5" },
];
export const teamMode = [
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
  { value: 3, label: "Trios" },
  { value: 4, label: "Quads" },
  { value: 5, label: "Penta" },
];
export const teamModeForValorant = [{ value: 5, label: "5v5" }];
export const profileProgressData = [
  {
    message: "Please complete the sign up",
    completeMessage: "Sign up Completed",
    name: "signup",
    complete: true,
    order: 1,
  },
  {
    message: "Please enter profile information",
    completeMessage: "Profile information completed",
    name: "profile",
    complete: false,
    order: 2,
  },
  {
    message: "Please update profile picture",
    name: "avatar",
    completeMessage: "Profile picture updated",
    complete: false,
    order: 3,
  },
  {
    message: "Please enter any Game ID",
    completeMessage: "Game ID entered",
    name: "gameid",
    complete: false,
    order: 4,
  },
];

export const notificationSettingEnum = {
  noti_text_message: "TEXT MESSAGES",
  noti_email: "EMAILS",
  noti_discord: "DISCORD MESSAGES",
};

export const paymentMethodsEnum = [
  {
    label: "Credit Card",
    value: 1,
  },
  {
    label: "Wallets",
    value: 2,
  },
];

export const durationAsString = (ms, maxPrecission = 3) => {
  const duration = moment.duration(ms);

  const items = [];
  items.push({ timeUnit: "h", value: duration.hours() });
  items.push({ timeUnit: "m", value: duration.minutes() });
  items.push({ timeUnit: "s", value: duration.seconds() });

  const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
    if (
      accumulator.length >= maxPrecission ||
      (accumulator.length === 0 && value === 0)
    ) {
      return accumulator;
    }

    accumulator.push(`${value}${timeUnit}`);
    return accumulator;
  }, []);

  return formattedItems.length !== 0 ? formattedItems.join(" ") : "-";
};
export const convertMilliSecToTimeAndDate = (msTobeConverted) => {
  const date = new Date(msTobeConverted);
  const options = {
    weekday: "short",
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "America/New_York",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};
export const teamStatusText = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Accepted" },
  { value: 3, label: "Rejected" },
  { value: 4, label: "Removed" },
];
export const gameTypeEnum = {
  1: "PPK",
  2: "Kill Race",
  3: "Traditional",
};

export const gameTypeFilters = [
  { value: 1, label: "PPK" },
  { value: 2, label: "Kill Race" },
  { value: 3, label: "Traditional" },
];
export const lobbyModeForCODFilters = [
  { value: 0, label: "All" },
  { value: 1, label: "Solos" },
  { value: 2, label: "Duos" },
];
export const lobbyModeForValorantFilters = [{ value: 5, label: "Penta" }];
export const gameStatusFilters = [
  { value: 1, label: "Active" },
  { value: 2, label: "Cancelled" },
  { value: 3, label: "Completed" },
];
export const gameChallengesFilters = [
  { value: 1, label: "Open" },
  { value: 2, label: "Private" },
];

export const getPlaceholder = (selectedGame) => {
  if (
    selectedGame?.slug == "valorant" ||
    selectedGame?.slug == "call-of-duty-warzone"
  ) {
    return "Add gamertag here : Ryvals#1234";
  } else {
    return "Add gamertag here";
  }
};

export const loadScriptByURL = (id, url, callback = () => {}) => {
  // if already added in script then ignore
  const isScriptExist = document.getElementById(id);
  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }
  if (isScriptExist && callback) callback();
};

export const recaptchaVerify = (action, callback = () => {}) => {
  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action })
      .then((token) => {
        callback(token);
      })
      .catch((err) => {
        ConsoleHelper({ err });
      });
  });
};

export const gameStatsTableHeadForApex = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Team placement",
  "Survival time",
  "Damage dealt",
  "Platform",
];
export const gameStatsTableHeadForValorant = [
  "Team name",
  "Player name",
  "Kills",
  "Winnings",
  "Assists",
  "Damage dealt",
  "Survival time",
  "Platform",
];

export const ConsoleHelper = (...data) => {
  if (process.env.NODE_ENV === "production") return;
  console.log(...data);
};

export const getUrlChanger = (type) => {
  return WEBSITE_URLS_TO_REDIRECT_MAP[type];
};

export const WEBSITE_URLS_TO_REDIRECT_MAP =  {
  signup: window.location.origin + "/signup",
  myprofile: window.location.origin + "/myprofile",
  referral: window.location.origin + "/signup?referral=",
};



export const handleBannedLocationError = (errorMsgToDisplay) => {
  return errorMsgToDisplay.length === 70
    ? ERROR_MESSAGES.bannedLocation
    : errorMsgToDisplay;
};

export const getCardBackgroundImage = (gameSlug, type, isVerified = false) => {
  return `card active-card bg-dark text-white ${
    CARD_BACKGROUNDS_PATHS[gameSlug][type]
  } ${isVerified ? "verified-available-lobby" : ""}`;
};

export const YupSchemaPassword = () =>
  Yup.string()
    .transform((x) => (x === "" ? undefined : x))
    .concat(Yup.string().required(VALIDATIONS_ERROR.passwordRequired))
    .matches(
      /(?=.*[A-Za-z])(?=.*[\d])(?=.*[@$!%*#?&^_-])^[A-Za-z0-9][A-Za-z\d@$!%*#?&^_-]{7,14}$/,
      VALIDATIONS_ERROR.passwordPattern
    );

export const YupSchemaConfirmPassword = () =>
  Yup.string()
    .transform((x) => (x === "" ? undefined : x))
    .when("password", (password, schema) => {
      if (password) return schema.required(VALIDATIONS_ERROR.confirmPassword);
    })
    .oneOf([Yup.ref("password")], VALIDATIONS_ERROR.confirmPasswordMatch);
// 1 PPK , 2 KILL RACE , 3 TRADITIONAL

export const gamesToCategories = (games) => {
  let traditional = [];
  let ppk = [];
  let killRace = [];
  games.forEach((data) => {
    data.types_of_game.forEach((gameType) => {
      switch (gameType) {
        case "1":
          ppk.push({ ...data, value: data.id, label: data.name });
          break;
        case "2":
          killRace.push({ ...data, value: data.id, label: data.name });
          break;
        case "3":
          traditional.push({ ...data, value: data.id, label: data.name });
          break;
        default:
          break;
      }
    });
  });
  return {
    ppkGames: ppk,
    traditionalGames: traditional,
    killRaceGames: killRace,
    allGames: games.map((game) => ({
      ...game,
      value: game.id,
      label: game.name,
    })),
    modeObj: {
      1: ppk,
      2: killRace,
      3: traditional.filter((game) => game.slug != "apex-legends"),
    },
    modeGameTypeObj: getModesOfGame([...ppk, ...traditional, ...killRace]),
  };
};

export const getModesOfGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
    }));
  });
  temp["apex-legends"] = temp["apex-legends"].filter((mode) => mode.mode != 6);
  Object.keys(temp).forEach((gameKey) => {
    if (gameKey != "valorant")
      temp[gameKey] = [{ label: "All", value: 0, mode: 0 }, ...temp[gameKey]];
  });
  return temp;
};

export const getModeLabel = (type, mode) =>
  type == 1 ? lobbyModeFilters[mode]?.label : challengeModeFilters[mode]?.label;

export const getModeLabelsSelect = (type, modeArr) =>
  modeArr?.map((mode) => ({
    ...mode,
    label: getModeLabel(type.value, mode.mode),
  }));

  export const getGameFromId = (games =[] , gameId) => {
    const res = games?.filter(({id}) => gameId == id )?.[0]
    return res
  }
