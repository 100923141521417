import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { fileComplaint, getEnrolledUsers } from "../../services/user.service";
import toastify from "../../helper/toastify.jsx";

import { DateFormat } from "../../helper/DateFormatter";
const ComplaintForm = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(null);
  const [enrolledUsers, setenrolledUsers] = useState(null);

  useEffect(() => {
    getEnrolledUserList();
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Opponent game id is required"),
    link: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Invalid url"
      )
      .required("Link is required"),
    message: Yup.string().required("Message is required"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    let body = {
      lobby_id: props.id,
      offender_id: +data.username,
      streaming_link: data.link,
      message: data.message,
    };
    showLoader(true);
    seterrorMsg(null);
    await fileComplaint(body)
      .then((res) => {
        toastify("success", "Complaint registered successfully");
        showLoader(false);
        props.onComplete();
      })
      .catch((err) => {
        showLoader(false);
        seterrorMsg(err);
      });
  };

  const getEnrolledUserList = async () => {
    await getEnrolledUsers(props.game_slug, props.id).then((res) => {
      setenrolledUsers(res);
    });
  };

  const showLoader = (load) => {
    setisLoading(load);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>File a Complaint</Modal.Title>
      </Modal.Header>
      <Modal.Body className="file-complaint-wrapper">
        <h2>Lobby Details</h2>
        <div className="complaint-wrapper">
          <div className="complaint-info">
            <h5>Game Name</h5>
            <h6>{props.game?.name}</h6>
          </div>
          <div className="complaint-info">
            <h5>Date</h5>
            <h6>{DateFormat(props.start_date)}</h6>
          </div>
          <div className="complaint-info">
            <h5>Start Time</h5>
            <h6>{props.start_time}</h6>
          </div>
          <div className="complaint-info">
            <h5>End Time</h5>
            <h6>{props.end_time}</h6>
          </div>
        </div>
        <div className="player-details">
          <h3>Enter Opponent’s Details</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Select
                aria-label="Default select example"
                id="username"
                name="username"
                {...register("username")}
                className={`mb-0 input-field ${
                  errors.username ? "is-invalid" : ""
                }`}
              >
                <option value="">Opponent Game Id</option>
                {enrolledUsers &&
                  enrolledUsers.map((res) => (
                    <option value={res.id}>{res.username}</option>
                  ))}
              </Form.Select>
              {errors.username?.message && (
                <div className="invalid-feedback d-block">
                  {errors.username?.message}
                </div>
              )}
            </Form.Group>

            <Form.Group
              className="mb-4 input-field"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Control
                id="streaminglink"
                type="text"
                name="link"
                placeholder="Link to evidence"
                {...register("link")}
                className={`input-field ${errors.link ? "is-invalid" : ""}`}
              />
              {errors.link?.message && (
                <div className="invalid-feedback d-block">
                  {errors.link?.message}
                </div>
              )}
            </Form.Group>

            <Form.Group className="" controlId="Message">
              <Form.Control
                as="textarea"
                rows={20}
                id="Message"
                name="message"
                placeholder="Enter message here"
                {...register("message")}
                className={`input-field ${errors.message ? "is-invalid" : ""}`}
              />
            </Form.Group>
            {errors.message?.message && (
              <div className="invalid-feedback d-block">
                {errors.message?.message}
              </div>
            )}

            {errorMsg && (
              <div className="invalid-feedback d-block my-2">{errorMsg}</div>
            )}

            <Button type="submit" variant="primary" disabled={isLoading}>
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </>
  );
};

export default ComplaintForm;
