import React from "react";

function CommunitySocials() {
  return (
    <section class="community-socials">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>
              TO HAVE YOUR CLIP CONSIDERED FOR OUR COMMUNITY PAGE, “SUBMIT A
              CLIP” THEN FOLLOW AND TAG @RYVALS ON TWITTER, @ryvalsppk ON
              TIKTOK, INSTAGRAM OR YOUTUBE.
            </h2>
            <h3>
              Use{" "}
              <a
                href="https://twitter.com/Ryvals"
                target="_blank"
                rel="noopener noreferrer"
              >
                #RyvalsPPK #PPK #Ryvalscommunity
              </a>
            </h3>
            <div class="social-icons-wrapper">
              <div class="icons">
                <div class="image-box">
                  <a
                    href="https://twitter.com/Ryvals"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/images/community-twitter.svg"
                      alt="twitter"
                    />
                  </a>
                </div>
                <a
                  href="https://twitter.com/Ryvals"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <p>@Ryvals</p>
                </a>
              </div>
              <div class="icons">
                <div class="image-box">
                  <a
                    href="https://www.instagram.com/ryvals_ppk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      src="/assets/images/community-instagram.svg"
                      alt="instagram"
                    />
                  </a>
                </div>
                <a
                  href="https://www.instagram.com/ryvals_ppk/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>@Ryvals_PPK</p>
                </a>
              </div>
              <div class="icons">
                <div class="image-box">
                  <a
                    href="https://www.youtube.com/channel/UC-zMv3sKD_JOinszajenPXg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <img
                      src="/assets/images/community-youtube.svg"
                      alt="youtube"
                    />
                  </a>
                </div>
                <a
                  href="https://www.youtube.com/channel/UC-zMv3sKD_JOinszajenPXg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <p>@RyvalsPPK</p>
                </a>
              </div>
              <div class="icons">
                <div class="image-box">
                  <a
                    href="https://www.tiktok.com/@RyvalsPPK"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/images/community-tiktok.svg"
                      alt="tiktok"
                    />
                  </a>
                </div>
                <a
                  href="https://www.tiktok.com/@RyvalsPPK"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>@RyvalsPPK</p>
                </a>
              </div>
            </div>
            <h6>
              If your clip is chosen it will be featured on our community page
              along with a link to your social.
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CommunitySocials;
