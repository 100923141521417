import React, { useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import { Button } from "react-bootstrap";
import {
  getPlayerProfile,
  sendFriendRequest,
  cancelFriendRequest,
  getSearchData,
} from "../../services/user.service";

import {Link, useParams } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import toastify from "../../helper/toastify.jsx";
import Paginator from "../../helper/paginator/Paginator";
import { putFriendRequest } from "../../services/notifications.service";
import { ConsoleHelper, mask } from "../../helper/utilities";
import ReactTooltip from "react-tooltip";

const PlayerProfile = (props) => {
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [playerProfileDetail, setPlayerProfile] = useState(null);
  const [playersFriendsTable, setPlayerFriendsTable] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageNum, setpageNum] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const pagination = true;
  const [isAddFriendButtonPending, setAddFriendButtonToPending] =
    useState(false);
  const [maskDetails, setMaskDetails] = useState(true);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableAddFriendButton, setDisableAddFriendButton] = useState(false);

  const [friendReq, setFriendReq] = useState(false);
  useEffect(() => {
    if (params.playerId) {
      let filter = {
        id: params.playerId,
        isPaginated: pagination,
        pageNum: pageNum,
        pageSize: pageSize,
      };
      setLoader(true);
      getPlayerProfile(filter)
        .then((res) => {
          setPlayerProfile(res.profile);
          setPlayerFriendsTable(res.results);
          settotalCount(res.total);
          setPageSize(res.page_size);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      props.history.push("/pageNotFound");
    }
  }, [pageNum, params.playerId]);
  const handlePageClick = (newPageNum) => {
    setpageNum(newPageNum);
  };
  const handleFriend = async (id) => {
    if (playerProfileDetail.disable_add_friend) {
      toastify(
        "error",
        "Sorry this account is private and not accepting requests at this time"
      );
    } else {
      setDisableAddFriendButton(true);
      await sendFriendRequest(id)
        .then((res) => {
          toastify("success", res.success);
          setAddFriendButtonToPending(true);
          setFriendReq(false);
          setDisableAddFriendButton(false);
        })
        .catch((err) => {
          ConsoleHelper(err);
          setAddFriendButtonToPending(false);
          setDisableAddFriendButton(false);
        });
    }
  };
  const handleCancelFriend = async (id) => {
    setDisableCancelButton(true);
    await cancelFriendRequest(id)
      .then((res) => {
        toastify("success", res.success);
        setDisableCancelButton(false);
        setAddFriendButtonToPending(false);
        callUsername();
      })
      .catch((err) => {
        ConsoleHelper(err);
        setDisableCancelButton(false);
      });
  };
  const callUsername = async () => {
    let filter = {
      searchTerm: playerProfileDetail?.username,
      isPaginated: true,
      pageNumber: 1,
      pageSize: 25,
    };
    await getSearchData(filter)
      .then((res) => {
        if (res.results[0].username === playerProfileDetail?.username) {
          setFriendReq(!res.results[0].request ? true : false);
        } else {
          setFriendReq(false);
        }
      })
      .catch((err) => {
        setFriendReq(false);
      });
  };
  const handleFriendRequest = async (e, notificationAction, notificationId) => {
    e.stopPropagation();
    await putFriendRequest({ notificationAction, notificationId })
      .then((res) => {
        const msg = Object.keys(res);
        toastify("success", msg[0] == "errors" ? res.errors : res.success);
        props.history.push({
          pathname: "/myprofile",
          data: "playerProfile",
        });
      })
      .catch((err) => {
        ConsoleHelper(err);
      });
  };

  const handleMask = (fieldKey) => {
    setMaskDetails(false);
  };
  return (
    <>
      {loader ? (
        <div class="loading"></div>
      ) : (
        <>
          <div className="slider-activation slider-creative-agency profile-slider">
            <Parallax
              bgImage={"/assets/images/header-background.png"}
              strength={500}
              bgClassName="page-banner-parallax profile-banner-parallax"
            >
              <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1 className="title game-title theme-gradient">
                          Player Profile
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          </div>

          <section className="my-profile">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="back-arrow-button">
                    <img src="/assets/images/back-arrow.svg" alt="back-arrow" />
                    <Link
                      to={
                        props?.location?.state?.type == 1
                          ? {
                              pathname: "/notifications",
                            }
                          : {
                              pathname: "/myProfile",
                              data: "playerProfile",
                            }
                      }
                      className="back-text"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="myprofile-wrapper mt-5">
                    <div className="profile-image">
                      <img
                        class="user-image"
                        src={
                          playerProfileDetail?.profile_url === "" ||
                          playerProfileDetail?.profile_url === "null" ||
                          playerProfileDetail?.profile_url === null
                            ? "/assets/images/profile-img.jpg"
                            : playerProfileDetail?.profile_url
                        }
                        alt="profile"
                      />
                    </div>
                    <div className="information">
                      <div className="info-user-wrapper">
                        <div className="username-wrapper">
                          <div className="username notificationUser">
                            <h2 className="mb-3">
                              {playerProfileDetail?.first_name +
                                " " +
                                playerProfileDetail?.last_name}
                            </h2>
                            <h3>{playerProfileDetail?.username}</h3>
                          </div>
                        </div>
                        <div className="edit-profile-button medium-device-button">
                          {props?.location?.state?.type == 1 ||
                          playerProfileDetail?.request ? (
                            <>
                              {" "}
                              <Button
                                className="decline-btn decAccept-btn notif-list float-inherit notificationListing"
                                onClick={(e) =>
                                  handleFriendRequest(
                                    e,
                                    false,
                                    props?.location?.state?.notificationId
                                      ? props?.location?.state?.notificationId
                                      : playerProfileDetail?.notification_id
                                  )
                                }
                              >
                                Decline
                              </Button>
                              <Button
                                className="accept-btn decAccept-btn notif-list notificationListing"
                                onClick={(e) =>
                                  handleFriendRequest(
                                    e,
                                    true,
                                    props?.location?.state?.notificationId
                                      ? props?.location?.state?.notificationId
                                      : playerProfileDetail?.notification_id
                                  )
                                }
                              >
                                Accept
                              </Button>
                            </>
                          ) : props?.location?.state?.friend ? (
                            <Button variant="secondary" disabled={true}>
                              Friend
                            </Button>
                          ) : props?.location?.state?.pending ||
                            isAddFriendButtonPending ? (
                            friendReq ? (
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleFriend(playerProfileDetail?.id)
                                }
                                disabled={disableAddFriendButton}
                              >
                                Add friend
                              </Button>
                            ) : (
                              <>
                                <Button variant="secondary" disabled={true}>
                                  Pending
                                </Button>
                                <Button
                                  className="cancelRequest"
                                  variant="secondary"
                                  onClick={() =>
                                    handleCancelFriend(playerProfileDetail?.id)
                                  }
                                  disabled={disableCancelButton}
                                >
                                  Cancel request
                                </Button>
                              </>
                            )
                          ) : (
                            <Button
                              variant="secondary"
                              onClick={() =>
                                handleFriend(playerProfileDetail?.id)
                              }
                              disabled={disableAddFriendButton}
                            >
                              Add friend
                            </Button>
                          )}
                        </div>
                      </div>
                      <div class="ids-wrapper notificationDiscord">
                        {playerProfileDetail?.hide_profile_detail ? null : (
                          <p>
                            <span>Discord Username :{"   "}</span>

                            {playerProfileDetail?.discord_id
                              ? maskDetails
                                ? mask(playerProfileDetail?.discord_id)
                                : playerProfileDetail?.discord_id
                              : playerProfileDetail?.discord_id || "NA"}
                            {playerProfileDetail?.discord_id ? (
                              <img
                                src={
                                  maskDetails
                                    ? "/assets/images/eye.svg"
                                    : "/assets/images/no-eye.svg"
                                }
                                onClick={() => handleMask("discord")}
                                alt=""
                                className="eye-icon"
                              />
                            ) : null}
                          </p>
                        )}
                      </div>
                      <div class="ids-wrapper">
                        <p>
                          <span>Private Account</span>
                        </p>

                        <label class="switch">
                          <input
                            type="checkbox"
                            className="react-switch"
                            checked={
                              playerProfileDetail?.disable_add_friend ||
                              playerProfileDetail?.hide_friend_list ||
                              playerProfileDetail?.hide_profile_detail
                            }
                            disabled={true}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {playerProfileDetail?.hide_friend_list ? null : (
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      activeKey="friends"
                    >
                      <Tab
                        eventKey="friends"
                        title="FRIENDS"
                        className="searchTableBox"
                      >
                        {playersFriendsTable.length === 0 ? (
                          <h5
                            style={{
                              textAlign: "center",
                              padding: "20px",
                            }}
                          >
                            No result found
                          </h5>
                        ) : (
                          <table style={{ color: "white" }}>
                            <tr>
                              <th>PROFILE’S PICTURE</th>
                              <th>Friend's Username</th>

                              <th>FRIEND SINCE</th>
                              <th>Favorite</th>
                            </tr>

                            {playersFriendsTable &&
                              playersFriendsTable.map((data, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td className="friendsProfilepic">
                                      <img
                                        src={
                                          data.profile_url === null
                                            ? "/assets/images/profile-img.jpg"
                                            : data.profile_url === "null"
                                            ? "/assets/images/profile-img.jpg"
                                            : data.profile_url
                                        }
                                        alt="Sorting"
                                        className="profilepic"
                                      />
                                    </td>
                                    <td>
                                      <p style={{ textTransform: "uppercase" }}>
                                        {data.username}
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        {new Date(
                                          data.create_date
                                        ).toLocaleString("en-US", {
                                          //weekday: "short", // long, short, narrow
                                          day: "numeric", // numeric, 2-digit
                                          year: "numeric", // numeric, 2-digit
                                          month: "long", // numeric, 2-digit, long, short, narrow
                                        })}
                                      </p>
                                    </td>
                                    <td>
                                      <img
                                        src="/assets/images/Favourites.svg"
                                        alt="Favorite"
                                        className="fav-icon" 
                                        data-tip
                                        data-for="add-favourite"
                                      />
                                      <ReactTooltip
                                        id="add-favourite"
                                        place="bottom"
                                        effect="solid"
                                      >
                                        Add to Favorite
                                      </ReactTooltip>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        )}
                      </Tab>
                    </Tabs>
                  )}
                  <div>
                    {pagination ? (
                      <Paginator
                        totalCount={totalCount}
                        pageSize={pageSize}
                        pageClick={handlePageClick}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default PlayerProfile;
