import { axiosApi, setToken } from ".";

export async function faqlisting({ type }) {
  const url = process.env.REACT_APP_APIURL + "pages/faqs/";

  let params = new URLSearchParams();

  params.append("type", type);
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.get(url, { headers, params });
}
export async function sendFeedBack(body) {
  const url = process.env.REACT_APP_APIURL + "emails/feedback/";

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.post(url, body, { headers });
}
