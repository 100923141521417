import React, { useState, useEffect } from "react";
import { Button, Modal, Placeholder, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getKycStatus,
  getPublicKeyStripe,
  getWalletBalance,
} from "../../services/user.service.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
const AddToWallet = (props) => {
  const userEmail = props.data;
  const [walletBalance, setwalletBalance] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const defaultAmounts = [10, 25, 50, 100, "Other"];
  const [iskycStatusLoading, setiskycStatusLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    checkUserKyc();
    getPublicKey();
    getWallet();
  }, []);

  const checkUserKyc = async (_) => {
    setiskycStatusLoading(true);
    await getKycStatus().then((res) => {
      setiskycStatusLoading(false);
    });
  };

  const getWallet = () => {
    getWalletBalance().then((res) => {
      setwalletBalance(res.wallet_balance);
    });
  };

  const getPublicKey = async (_) => {
    await getPublicKeyStripe().then((res) => {
      setPublicKey(res.token);
    });
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      //.typeError("Please enter a valid amount")
      .required("Amount is required"),
    // .positive("Amount cannot be 0")
    // .min(10, "Amount has to be more than $10")
    // .max(10000, "Amount would not be greater than $10,000")
    // .test(
    //   "invalid-decimal",
    //   "Amount doesn't have more than 2 decimal points",
    //   (value) => (value + "").match(/^(\d+(\.\d{1,2})?)$/)
    // ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { amount: props.prevAmount },
    resolver: yupResolver(validationSchema),
  });
  const [activeIndex, setActive] = useState(
    props.prevAmount == 10
      ? 0
      : props.prevAmount == 25
      ? 1
      : props.prevAmount == 50
      ? 2
      : props.prevAmount == 100
      ? 3
      : 4
  );
  const onSelectValue = (amount, index) => {
    setValue("amount", amount);
    setActive(index);
  };
  const handleChange = (e) => {
    if (e.target.value > 100) {
      setActive(4);
    }
  };
  const onSubmit = (data) => {
    setisLoading(true);
    if (props.location?.pathname == "/myProfile") {
      props.history.push({
        pathname: "/payment",
        state: {
          amount: data.amount,
          email: userEmail,
          stripeKey: publicKey,
          walletBalance: walletBalance,
        },
      });
    } else {
      if (props.match.path === `/:slug/lobby`) {
        props.history.push({
          pathname: "/payment",
          state: {
            amount: data.amount,
            stripeKey: publicKey,
            prevPath: props.location.pathname,
            gameName: props.gameName,
            walletBalance: props.walletBalance,
          },
        });
      } else if (
        props.location.pathname ===
          `/${props?.gameName?.slug}/lobby/${props.lobbyId}` ||
        props.location.pathname === "/dashboard"
      ) {
        props.history.push({
          pathname: "/payment",
          state: {
            amount: data.amount,
            stripeKey: publicKey,
            prevPath: props.location.pathname,
            gameName: props.gameName,
            lobbyId: props.lobbyId,
            walletBalance: props.walletBalance,
          },
        });
      } else {
        props.history.push({
          pathname: "/payment",
          state: {
            amount: data.amount,
            email: userEmail,
            stripeKey: publicKey,
            walletBalance: props.oldwalletBalance,
          },
        });
        setisLoading(false);
        props.closeModal();
      }
    }
  };
  return (
    <>
      {iskycStatusLoading ? (
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="money-added">
            <p>
              <Placeholder animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
            </p>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Add Money to Wallet</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className="wallet-body" style={{ color: "white" }}>
              <div>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Amount"
                    {...register("amount")}
                    name="amount"
                    className={`${errors.amount ? "is-invalid" : ""}`}
                    onChange={(e) => handleChange(e)}
                  />
                </InputGroup>
                {errors.amount?.message && (
                  <div className="invalid-feedback d-block">
                    {errors.amount?.message}
                  </div>
                )}
                <p class="deposit-note">Note: $10.00 minimum deposit.</p>
              </div>
              {defaultAmounts &&
                defaultAmounts.map((btn, idx) => {
                  const other = btn == "Other" ? "Other" : `$${btn}`;
                  return (
                    <span className="ml-2" key={idx}>
                      <button
                        type="button"
                        className={
                          idx == activeIndex
                            ? "ml-2 btn btn-sm  btn-outline-secondary active"
                            : "ml-2 btn btn-sm  btn-outline-secondary"
                        }
                        onClick={() => onSelectValue(btn, idx)}
                      >
                        {other}
                      </button>
                    </span>
                  );
                })}
            </Modal.Body>
            <Modal.Footer className="wallet-footer">
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading}
                id="myInput"
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm mr-4"></span>
                ) : (
                  "Add Money"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </>
  );
};
AddToWallet.propTypes = {
  closeModal: PropTypes.func,
};
export default withRouter(AddToWallet);
