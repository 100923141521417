import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { gamelisting } from "../../services/game.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { editGamerTag } from "../../services/user.service";
import PropTypes from "prop-types";
import { gamerTagInfo, getPlaceholder } from "../../helper/utilities";
import toastify from "../../helper/toastify";

const EditGameName = (props) => {
  const [isLoading, setisLoading] = useState(null);
  const [errorMsg, seterrorMsg] = useState(null);
  const [games, setgames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    gaming_account: Yup.string()
      .required("Account Username is required")
      .test("Invalid", "Please enter valid gamertag", function (value) {
        if (selectedGame.slug === "call-of-duty-warzone") {
          if (!!value) {
            const schema = Yup.string().matches(
              /^[\p{L}\p{M}\p{Zs}0-9 _-]{2,}#[0-9]{2,}$/u
            );

            return schema.isValidSync(value);
          }
          return true;
        } else if (selectedGame.slug == "valorant") {
          if (!!value) {
            const schema = Yup.string().matches(/^[a-zA-Z0-9\s_,-]+#[0-9]+$/);
            return schema.isValidSync(value);
          }
          return true;
        }
        return true;
      }),
  });

  useEffect(() => {
    gameList();
  }, []);

  useEffect(() => {
    setSelectedGame(games?.find((item) => item.id == props?.userGame.gameId));
  }, [games, props?.userGame]);

  const gameList = () => {
    setisLoading(true);
    gamelisting()
      .then((res) => {
        setgames(res);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  // functions to build form returned by useForm() hook
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { gaming_account: props.userGame.gameAccountName },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    let body = {
      game_id: props.userGame.gameId,
      gaming_account: data.gaming_account?.trim(),
    };
    setisLoading(true);
    await editGamerTag(body)
      .then((res) => {
        toastify(
          "success",
          `Gamertag for ${selectedGame.name} updated successfully`
        );
        props.closeEditModal();
        setisLoading(false);
      })
      .catch((err) => {
        seterrorMsg(err);
        setisLoading(false);
      });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Game Id</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="wallet-body gamerbody">
          <Form.Group className="bottom-margin" controlId="formText">
            <Form.Control placeholder={props.userGame.gameName} disabled />
          </Form.Group>
          <Form.Group className="bottom-margin" controlId="formText">
            <Form.Control
              type="text"
              placeholder={getPlaceholder(selectedGame)}
              {...register("gaming_account")}
              className={`${errors.gaming_account ? "is-invalid" : ""}`}
            />
            {errors.gaming_account?.message && (
              <div className="invalid-feedback d-block">
                {errors.gaming_account?.message}
              </div>
            )}
          </Form.Group>
          {errorMsg && (
            <div className="invalid-feedback d-block">{errorMsg}</div>
          )}
        </Modal.Body>
        <Modal.Footer className="wallet-footer gamertag-prompt-header">
          <p className="gamertag-prompt">{gamerTagInfo(selectedGame)}</p>
          <Button
            variant="primary"
            type="submit"
            disabled={
              isLoading
                ? true
                : watch("gaming_account") === props.userGame.gameAccountName
                ? true
                : false
            }
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Edit Account"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

EditGameName.propTypes = {
  closeEditModal: PropTypes.func,
};

export default EditGameName;
