import React, { useState } from "react";
import PasswordValidation from "../passwordValidation";

function PasswordComponent({
  errors = {},
  register = () => {},
  passWordLeftBullets = [],
  PasswordRightBullets = [],
  htmlFor1 = "item1",
  htmlFor2 = "item2",
}) {
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const togglePassword = () => {
    setshowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <label htmlFor={htmlFor1}>
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          id="item05"
          placeholder="Password"
          {...register("password")}
          className={`${errors.password ? "is-invalid" : ""}`}
        />
        <img
          className="passowrd-field"
          onClick={togglePassword}
          src={`assets/images/${showPassword ? "no-eye" : "eye"}.svg`}
          alt={showPassword ? "hide" : "show"}
          title={showPassword ? "hide" : "show"}
        />
      </label>
      {errors.password?.message === "Password is required" ? (
        <>
          <div className="invalid-feedback d-block mb-2">
            {errors.password?.message}
          </div>
          <div className="invalid-feedback d-block password-guidelines">
            <PasswordValidation
              leftBulltets={passWordLeftBullets}
              rightBullets={PasswordRightBullets}
            />
          </div>
        </>
      ) : (
        <div
          className={`d-block  ${
            errors.password?.message
              ? "invalid-feedback"
              : "password-guidelines"
          }`}
        >
          <PasswordValidation
            leftBulltets={passWordLeftBullets}
            rightBullets={PasswordRightBullets}
          />
        </div>
      )}

      <label htmlFor={htmlFor2}>
        <input
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          id="item06"
          placeholder="Confirm Password"
          {...register("confirmPassword")}
          className={`${errors.confirmPassword ? "is-invalid" : ""}`}
        />
        <img
          onClick={toggleConfirmPassword}
          src={`assets/images/${showConfirmPassword ? "no-eye" : "eye"}.svg`}
          alt={showConfirmPassword ? "hide" : "show"}
          title={showConfirmPassword ? "hide" : "show"}
        />
      </label>
      {errors.confirmPassword?.message && (
        <div className="invalid-feedback d-block">
          {errors.confirmPassword?.message}
        </div>
      )}
    </>
  );
}

export default PasswordComponent;
