import React, { useLayoutEffect, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import toastify from "../../helper/toastify.jsx";
import { sendShift4DataSuccessForFurtherProcessing } from "../../services/user.service.js";
import "./Payment.style.scss";
function PaymentShiftFour({
  handleShift4InitialParameters,
  handleShift4SavedCard,
  amount,
  walletBalance,
  successFullCallback,
  errorCallback,
  errorResponse,
}) {
  const [loader, setLoader] = useState(true);
  const [showSavedCard, setShowSavedCard] = useState(false);
  const [shift4data, setShift4Data] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  if (errorResponse === "" || errorResponse === undefined) {
    let i4goTrueTokenObj = window.$("#form-main").i4goTrueToken({
      //  window.$("#form-main").i4goTrueToken({
      debug: true, // Parent side
      remoteDebug: false, // Adds width indicators within the iframe contents to help with frame sizing
      url: handleShift4InitialParameters.i4go_i4m_url,
      server: handleShift4InitialParameters.i4go_server,
      accessBlock: handleShift4InitialParameters.i4go_accessblock,
      self: document.location,
      template: "bootstrap5",
      language: "en",
      formAutoSubmitOnSuccess: false,
      formAutoSubmitOnFailure: false,
      frameAutoResize: true,
      /* *** begin options *** */
      i4goInfo: { visible: false },
      /* *** end options *** */
      encryptedOnlySwipe: false,
      frameContainer: "i4goFrame",
      submitButton: {
        label: "Proceed To Pay",
        visible: false,
      },
      body: {
        styles: { "background-color": "##aaa", borderLeft: "5px solid ##ccc" },
      },
      label: { classes: "control-label col-xs-4" },
      cssRules: [
        "body{background-color: #000;font-family: sans-serif;}",
        "body{text-size: 20%}",
        "div#error-dialog {color: #dc3545;}",
        "div#i4goFrame {height: 208px;}",
        "#i4go-payment-form .col-md-8.col-lg-6  {padding: 0;}",
        "#i4go-payment-form .row {background-color: #000;}",
        "#i4go_cardType-error{margin-bottom:25px;}",
        "input#cardNumber{background-color: #000;color: #fff;border: 1px solid #979797;border-radius: 4px;padding: 1px 19px;height: 50px;margin-bottom: 20px!important;}",
        "input#i4go_expiration{background-color: #000;color: #fff;border: 1px solid #979797;border-radius: 4px;padding: 1px 19px;height: 50px}",
        "input#cvv2Code{background-color: #000;color: #fff;border: 1px solid #979797;border-radius: 4px;padding: 1px 19px;height: 50px}",
        "input#i4go_streetAddress{background-color: #000;color: #fff;border: 1px solid #979797;border-radius: 4px;padding: 1px 19px;height: 50px;margin-bottom: 20px!important;}",
        "input#i4go_postalCode{background-color: #000;color: #fff;border: 1px solid #979797;border-radius: 4px;padding: 1px 19px;height: 50px;margin-bottom: 20px!important;}",
        "#i4go_submit{width: 100%;height: 54px;background-color: #00ccff;font-family: poppinsregular;font-size: 20px;line-height: 1.33;letter-spacing: -0.23px;color: #fff;border-color: #00ccff;margin-top: 18px;}",
        "label.control-label{display: none;}",
        "label{color:##444;font-size:80%;font-weight:bold;}",
        "label#cardNumber-error {color: #dc3545;width: 100%;font-size: .875em;margin-bottom: 10px; position:relative; bottom:8px;}",
        "label#i4go_expiration-error {color: #dc3545;width: 100%;margin-top: 0.25rem;font-size: .875em;}",
        "label#cvv2Code-error {color: #dc3545;width: 100%;margin-top: 0.25rem;font-size: .875em;}",
        "label#i4go_streetAddress-error {color: #dc3545;width: 100%;font-size: .875em;margin-bottom: 10px; position:relative; bottom:8px;}",
        "label#i4go_postalCode-error {color: #dc3545;width: 100%;font-size: .875em;margin-bottom: 10px; position:relative; bottom:8px;}",
        ".modal-body.text-center { display: none;}",
        "div#dialog-wait .modal-dialog {height: 100%;margin: auto;}",
        "div#dialog-wait .modal-content {height: 100%;}",
      ],
      wallet: {
        buttonColor: "white",
        buttonSizeMode: "fill",
        nameRequired: null, // true/false/null - null uses addressRequired
        addressRequired: false,
        emailRequired: false,
        phoneNumberRequired: false,
        allowedCountryCodes: [],
        shippingOptionRequired: false,
        shippingOptions: [], // first option will be the default
        shippingType: "shipping", // shipping, delivery, storePickup, servicePickup
      },
      container: { classes: "" },
      cardType: {
        classes: "",
        label: "",
        placeholder: "",
        message: "",
        visible: false,
      },
      cvv2Code: {
        classes: "",
        label: "",
        placeholder: "",
        message: "",
        visible: true,
        required: true,
      },
      streetAddress: {
        classes: "",
        label: "",
        placeholder: "",
        message: "",
        visible: true,
        required: true,
      },
      postalCode: {
        classes: "",
        label: "",
        placeholder: "",
        message: "",
        visible: true,
        required: true,
      },
      onSuccess: function (form, data) {
        console.log("MY onSuccess called!", data);
        if (data.i4go_responsecode === "1") {
          setShowSavedCard(true);
          setShift4Data(data);
          // CallModalToSaveCard(data);
          // CallApiToExecuteTransaction(data);
        }
        //setProcessing(true);
      },
      onFailure: function (form, data) {
        console.warn("MY onFailure custom-submitcalled!", data);
        // toastify("error", data.otn.longerror);
        // errorCallback();
        setProcessing(false);
      },
      //Wallet will asynchronously trigger individual callbacks.
      onWalletInit: function (wallet, enabled, reason) {
        console.log("i4goTrueToken- onWalletInit()", wallet, enabled, reason);
        setLoader(false);
      },
      onPaymentDataChanged: function (
        iobj,
        intermediatePaymentData,
        paymentDataRequestUpdate
      ) {
        console.log(
          "i4goTrueToken- onPaymentDataChanged()",
          iobj,
          intermediatePaymentData
        );
        return paymentDataRequestUpdate;
      },
    });
    window.$("#custom-submit").click(function (e) {
      e.preventDefault();
      i4goTrueTokenObj.submit();
    });
  } else {
    toastify("error", errorResponse);
    errorCallback();
  }

  const [processing, setProcessing] = useState("");
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const CallApiToExecuteTransaction = (shift4payload) => {
    sendShift4DataSuccessForFurtherProcessing(shift4payload)
      .then(() => {
        successFullCallback({
          res: { amount: +walletBalance + amount },
          setProcessing,
        });
      })
      .catch(() => {
        // errorCallback();
        setProcessing(false);
      });
  };
  const declineSaveCard = (data) => {
    setShowSavedCard(false);
    setProcessing(true);
    const payload = {
      amount: amount,
      token: "",
      save_card: false,
      card_number: "",
      ...data,
    };
    CallApiToExecuteTransaction(payload);
  };
  const acceptSaveCard = (data) => {
    setShowSavedCard(false);
    setProcessing(true);
    const payload = {
      amount: amount,
      token: data.i4go_uniqueid,
      save_card: true,
      card_number: data.otn.cardnumber,
      ...data,
    };
    CallApiToExecuteTransaction(payload);
  };
  const handleSubmitWithSavedCard = () => {
    setProcessing(true);
    const payload = {
      amount: amount,
      save_card: false,
      card_number: selectedCard,
      token: "",
      i4go_expirationmonth: "",
      i4go_expirationyear: "",
      i4go_extendedcarddata: "",
      i4go_uniqueid: "",
      i4go_streetAddress: "",
      i4go_postalCode: "",
    };
    CallApiToExecuteTransaction(payload);
  };
  return (
    <>
      <div className="payment-method-container shift4Payment-container">
        {loader ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        ) : null}
        <div className="d-flex gap-2  flex-column">
          {handleShift4SavedCard?.length === 0 ? null : (
            <form className={loader ? "d-none" : "form"}>
              <div className="notification-setting-item mb-4 payment-method-labels justify-content-start align-items-center">
                <div className="payment-method-label ms-0">
                  <h5 className="payment-titile mb-0">Saved Card</h5>
                </div>
              </div>
              {handleShift4SavedCard?.map((item, idx) => {
                return (
                  <Form.Group className="mb-2 saved-paymentMethod">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      className="friendPopup"
                      name="SavedCard"
                      onChange={() => setSelectedCard(item.card_number)}
                      id={idx}
                      label={item.card_number}
                    />
                  </Form.Group>
                );
              })}
              <Button
                variant="primary"
                disabled={processing}
                className="card-payment-button mb-4 mt-4"
                onClick={handleSubmitWithSavedCard}
              >
                Proceed To Pay
              </Button>
            </form>
          )}
          <form
            id="form-main"
            action={document.location}
            method="post"
            className={loader ? "d-none" : "form"}
          >
            <div className="notification-setting-item mb-4 payment-method-labels justify-content-start align-items-center">
              <div className="payment-method-label ms-0">
                <h5 className="payment-titile mb-0">Credit Card</h5>
              </div>
            </div>
            <div id="i4goFrame"></div>
            <Button
              variant="primary"
              id="custom-submit"
              disabled={processing}
              className="card-payment-button mb-4 mt-0"
            >
              Proceed To Pay
            </Button>
            <div className="notification-setting-item payment-method-labels justify-content-start align-items-center">
              <div className="payment-method-label mx-0">
                <h5 className="payment-titile mb-0">Others Payment Methods</h5>
              </div>
            </div>
            <div className="pay-buttons text-center mt-24">
              <button className="pay-button pay-hidden apple-pay-button apple-pay-button-white"></button>
              <button className="pay-button pay-hidden google-pay-button"></button>
            </div>
          </form>
        </div>
        <Modal
          show={showSavedCard}
          onHide={() => setShowSavedCard(false)}
          backdrop="static"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">save card</p>
              <p className="leave-modal-text">Do you want to save this card?</p>
            </div>
          </Modal.Body>

          <Modal.Footer className="delete-footer">
            <Button
              className="cancel-btn"
              onClick={() => declineSaveCard(shift4data)}
            >
              {processing ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Cancel"
              )}
            </Button>
            <Button
              className="yes-btn"
              onClick={() => acceptSaveCard(shift4data)}
            >
              {processing ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <>
          <Modal
            show={processing}
            onHide={() => setProcessing(false)}
            backdrop="static"
          >
            <Modal.Body className="money-added">
              <h3>Please Wait , Payment Processing ...</h3>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </>
  );
}
export default PaymentShiftFour;
