import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { gamelisting } from "../../services/game.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { addGamerTag } from "../../services/user.service";
import PropTypes from "prop-types";
import { gamerTagInfo, getPlaceholder } from "../../helper/utilities";
import { useRef } from "react";
import toastify from "../../helper/toastify";

const GameAccount = (props) => {
  const [games, setgames] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [isLoading, setisLoading] = useState(null);
  const [errorMsg, seterrorMsg] = useState(null);
  const gamerTagRef = useRef(null);
  useEffect(() => {
    gameList();
  }, []);

  const gameList = () => {
    gamelisting().then((res) => {
      let addedGamesId = props.addedGameAccounts.map((it) => it.game.id);
      let game = res
        .filter((it) => !addedGamesId.includes(it.id))
        .map((it) => ({
          ...it,
          label: it.gaming_account_name,
          value: it.id,
          name: it.name,
        
        }));
      setgames(game);
    });
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    game_id: Yup.number()
      .min(1, "Game provider is required")
      .required("Game provider is required"),
    gaming_account: Yup.string()
      .required("Account Username is required")
      .test("Invalid", "Please enter valid gamertag.", function (value) {
        if (selectedGame.slug === "call-of-duty-warzone") {
          if (!!value) {
            const schema = Yup.string().matches(
              /^[\p{L}\p{M}\p{Zs}0-9 _-]{2,}#[0-9]{2,}$/u
            );

            return schema.isValidSync(value);
          }
          return true;
        } else if (selectedGame.slug == "valorant") {
          if (!!value) {
            const schema = Yup.string().matches(/^[a-zA-Z0-9\s_,-]+#[0-9]+$/);
            return schema.isValidSync(value);
          }
          return true;
        }
        return true;
      }),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const game_id = watch("game_id");
  const onSubmit = async (data) => {
    let body = {
      game_id: data.game_id,
      gaming_account: data.gaming_account?.trim(),
    };
    setisLoading(true);
    await addGamerTag(body)
      .then((res) => {
        toastify(
          "success",
          `Gamertag for ${selectedGame.name} added successfully`
        );
        props.closeGameModal();
        setisLoading(false);
      })
      .catch((err) => {
        seterrorMsg(err);
        setisLoading(false);
      });
  };

  useEffect(() => {
    if (game_id == -1) {
      setSelectedGame(null);
    } else {
      setSelectedGame(games?.find((item) => item.value == game_id));
    }
  }, [game_id]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Gamertag</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="wallet-body gamerbody">
          <Form.Group className="bottom-margin" controlId="formSelectText">
            <Form.Select
              ref={gamerTagRef}
              aria-label="Default select example"
              {...register("game_id")}
              className={`mb-0 ${errors.game_id ? "is-invalid" : ""}`}
            >
              <option value="-1">Select game</option>
              {games && games?.length ? (
                games.map((opt, idx) => (
                  <option key={idx} value={opt.value}>
                    {opt.label}
                  </option>
                ))
              ) : (
                <option value={0}>No Game Accounts to select</option>
              )}
            </Form.Select>
            {errors.game_id?.message && (
              <div className="invalid-feedback d-block">
                {errors.game_id?.message}
              </div>
            )}
          </Form.Group>
          <Form.Group className="bottom-margin" controlId="formText">
            <Form.Control
              type="text"
              placeholder={getPlaceholder(selectedGame)}
              {...register("gaming_account")}
              className={`${errors.gaming_account ? "is-invalid" : ""}`}
            />
            {errors.gaming_account?.message && (
              <div className="invalid-feedback d-block">
                {errors.gaming_account?.message}
              </div>
            )}
          </Form.Group>
          {errorMsg && (
            <div className="invalid-feedback d-block">{errorMsg}</div>
          )}
        </Modal.Body>
        <Modal.Footer className="wallet-footer gamertag-prompt-header">
          <p className="gamertag-prompt">{gamerTagInfo(selectedGame)}</p>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (
              <span className="spinner-border spinner-border-sm mr-4"></span>
            ) : (
              "Add Account"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  );
};

GameAccount.propTypes = {
  closeGameModal: PropTypes.func,
  addedGameAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      game: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        gaming_account_name: PropTypes.string,
      }),
      gaming_account: PropTypes.string,
      id: PropTypes.number,
    })
  ),
};

export default GameAccount;
