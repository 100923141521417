import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import RegisterTeam from "./RegisterTeam";
import { gamerTagInfo } from "../../helper/utilities";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { addChallenge } from "../../services/challenges.service";
function EnrollTeam(props) {
  const [enrollTeamStep, setEnrollTeamStep] = useState(0);
  const [value, setValue] = useState("");
  const handleRegister = () => {
    setEnrollTeamStep(1);
  };
  const [selectedTeamMates, setSelectedTeamMates] = useState([]);
  const [teamSuggestId, setTeamSuggestId] = useState(null);

  return enrollTeamStep === 0 ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Register Team</Modal.Title>
      </Modal.Header>

      <Modal.Body className="make-payment">
        <div className="d-flex justify-content-between">
          <h4 className="make-payment-modal">
            {props?.modetag} ({props.name})
          </h4>
        </div>
        <RegisterTeam
          value={value}
          setValue={setValue}
          lobbyId={props.id}
          setTeamMates={setSelectedTeamMates}
          mode={props?.mode}
          setTeamSuggestId={setTeamSuggestId}
          game={props.game}
        />
        <Link
          className="redirect-link mb-2"
          to={{
            pathname: `/myprofile`,
            state: {
              createTeam: true,
              mode: props?.mode,
              game: props?.game?.id,
            },
          }}
        >
          + Create New Team
        </Link>
      </Modal.Body>
      <Modal.Footer className="total-money total delete-footer registerCancelBtn">
        <Button
          variant="primary"
          onClick={() => props.closeEnrollModalStatus()}
          className="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!selectedTeamMates?.length}
          onClick={handleRegister}
        >
          Register
        </Button>
      </Modal.Footer>
    </>
  ) : enrollTeamStep === 1 ? (
    <SuccessRegisterTeam {...props} setEnrollTeamStep={setEnrollTeamStep} />
  ) : (
    <EnrollTeamPayment
      {...props}
      team_name={value}
      team_members={selectedTeamMates}
      teamSuggestId={teamSuggestId}
    />
  );
}

export default EnrollTeam;

export const EnrollTeamPayment = (props) => {
  const loggedUser = useSelector((state) => state);
  const [payActiveIndex, setPayActiveIndex] = useState(2);
  const [gamertag, setGamerTag] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [user, setUser] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const createChallenge = async () => {
    try {
      // only send map data for Apex and if some value
      const mapPayload =
        props.challengeData.gameName == 2 &&
        props.challengeData?.map?.trim()?.length
          ? { map: props.challengeData.map }
          : {};
      const payload = {
        mode: props.challengeData.gameMode,
        game_id: props.challengeData.gameName,
        match_date: moment(props.challengeData.gameDate).format("YYYY-MM-DD"),
        match_time: props.challengeData.gameTime,
        entry_fee: props.challengeData.fee,
        is_paying_full: payActiveIndex == 1 ? true : false,
        team_1: props.challengeData.teamName,
        team_2: props.challengeData?.opponent_team?.id
          ? props.challengeData?.opponent_team?.id
          : 0,
        bonus: props.promo_choice,
        ...mapPayload,
      };
      setisLoading(true);
      await addChallenge(payload);
      setisLoading(false);
      props.successCallbackChallenge(payActiveIndex);
    } catch (error) {
      setisLoading(false);
    }
  };

  useEffect(() => {
    setSelectedGame(props?.games?.find((item) => item.id == props?.game?.id));
  }, [props?.games]);

  useEffect(() => {
    const userData =
      JSON.parse(localStorage.getItem("userProfile")) !== null
        ? JSON.parse(localStorage.getItem("userProfile"))
        : null;
    if (loggedUser?.user) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    if (user?.game_accounts?.length && selectedGame?.gaming_account_name) {
      const game_account = user?.game_accounts.filter(
        (gameItem) =>
          gameItem?.game?.gaming_account_name ==
          selectedGame?.gaming_account_name
      );
      setGamerTag(game_account?.[0]?.gaming_account);
    }
  }, [user, selectedGame]);

  return (
    <>
      <Modal.Header
        className={props?.modalHeading ? "justify-content-center" : ""}
        closeButton
      >
        {props?.createChallenge ? (
          <Modal.Title className="mb-4 mt-4">{props?.modalHeading}</Modal.Title>
        ) : (
          <Modal.Title className="mb-4 mt-4">Pay</Modal.Title>
        )}{" "}
      </Modal.Header>

      <Modal.Body className="make-payment">
        {props?.createChallenge ? (
          <div style={{ display: "flex", width: "100%", margin: "10px 0px" }}>
            <div
              style={{
                flexBasis: "50%",
                borderRadius: 0,
                background: "gray",
                height: "0.5rem",
              }}
            >
              <h6 className="text-center text-white mt-3">Register Team</h6>
            </div>
            <div
              style={{
                backgroundColor: "#00ccff",
                height: "0.5rem",
                flexBasis: "50%",
                borderRadius: 0,
              }}
            >
              <h6 className="text-center text-white mt-3">Pay</h6>
            </div>
          </div>
        ) : null}
        <div
          className="d-flex justify-content-evenly payTeamYorself"
          style={props?.createChallenge ? { marginTop: "70px" } : {}}
        >
          {props.mode != 1 ? (
            <>
              <Button
                onClick={() => setPayActiveIndex(1)}
                className={
                  payActiveIndex == 1 ? `cancel-btn payTeam` : "pay-yourserlf"
                }
              >
                Pay for Team
              </Button>
              <Button
                onClick={() => setPayActiveIndex(2)}
                className={
                  payActiveIndex == 2 ? `cancel-btn payTeam` : "pay-yourserlf"
                }
              >
                Pay for Yourself
              </Button>
            </>
          ) : null}
        </div>
        <div className="d-flex justify-content-between entryFee">
          <span className="entry-fee">Entry Fee</span>
          <span>
            $
            {payActiveIndex === 1
              ? props.entry_fee * props.mode + ".00"
              : props.entry_fee + ".00"}
          </span>
        </div>
        <div className="d-flex justify-content-between walletTopBorder mb-5">
          <div className="mt-5">
            <h3>Wallet</h3>
            <h4>Total Balance</h4>
            <h5>${props.walletBalance}</h5>
            {props.free_lobby
              ? null
              : props.isInsufficientBalance && (
                  <>
                    <p>Insufficient Balance Add Money in Wallet</p>
                    <button
                      className="btn btn-secondary"
                      onClick={() => props.openWalletModalStatus()}
                    >
                      Add Money to wallet
                    </button>
                  </>
                )}
          </div>
          <div className="promo-bonus mt-5">
            <h3>Promo Bonus</h3>
            <h4>Total Balance</h4>
            <h5>${props.promoBonus}</h5>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Avail Promo Bonus to enroll into lobby"
                  onChange={props.onChangePromo}
                  checked={props.promo_choice}
                  disabled={!parseInt(props?.promoBonus)}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="displayGamerTag">
          <p>Display Name</p>
          <div className="d-flex gamertag-edit">
            <h6>{gamertag ? gamertag : "-"}</h6>
            <Link
              to={{
                pathname: "/myProfile",
                data: "settingsTab",
                settingsKey: "gameIds",
              }}
            >
              <img
                src="/assets/images/edit-gamertag-payment-popup.svg"
                alt="edit-icon"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="total d-flex justify-content-evenly">
        <div
          className="pb-3"
          data-tip
          data-for="loginTip"
          style={{ cursor: "pointer" }}
        >
          <span class="info-icon">
            <img
              src="/assets/images/info-icon.svg"
              alt="info"
              class="info-image"
            />
            <span className="gamerTagInfoMessage">
              {gamerTagInfo(selectedGame)}
            </span>
          </span>
          <div className="helper-image-container HelperImage">
            <ReactTooltip id="loginTip" place="top" effect="solid">
              <img src={selectedGame?.helper_image} alt="helper-image" />
            </ReactTooltip>{" "}
          </div>
        </div>
        <Button
          className="cancel-btn"
          onClick={() => props.closeEnrollModalStatus()}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (props.createChallenge) {
              createChallenge();
            } else {
              props.enroll(
                payActiveIndex,
                props.team_name,
                props.team_members,
                props.teamSuggestId
              );
            }
          }}
          disabled={
            isLoading || props.free_lobby ? false : props.isInsufficientBalance
          }
        >
          {isLoading ? (
            <span className="spinner-border spinner-border-sm mr-4"></span>
          ) : (
            "Pay"
          )}
        </Button>
      </Modal.Footer>
    </>
  );
};

const SuccessRegisterTeam = (props) => (
  <>
    <Modal.Header>
      <Modal.Title></Modal.Title>
    </Modal.Header>
    <Modal.Body className="invite-body text-center">
      <div className="refer-friend-icons">
        <img src="/assets/images/tick-mark-icon.svg" alt="gift-icon" />
        <h4 className="invite-para teamsRegistered">
          Team Successfully Registered
        </h4>
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button onClick={() => props.setEnrollTeamStep(2)}>Pay Now</Button>
    </Modal.Footer>
  </>
);
