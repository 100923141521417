import { axiosApi, setToken } from ".";

export async function communitylisting() {
  const url = process.env.REACT_APP_APIURL + `players/community/`;

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}
export async function getTopPlayerList() {
  const url = process.env.REACT_APP_APIURL + `players/top-players/`;

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}
