import { axiosApi, setToken } from ".";

export async function gamelisting() {
  const url = process.env.REACT_APP_APIURL + "games/";

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function gameLobbyListing(filters) {
  const url =
    process.env.REACT_APP_APIURL + `games/${filters.game_slug}/lobby/`;

  let params = new URLSearchParams();

  if (filters.isPaginated) {
    if (filters.type) params.append("lobby", filters.type);
    if (filters.pageSize) params.append("page_size", filters.pageSize);
    if (filters.pageNum) params.append("page", filters.pageNum);
    if (filters?.rewardAmount) params.append("type", filters?.rewardAmount);
    if (filters?.mode) params.append("mode", filters?.mode);
    if (filters?.gameType) params.append("gametype", filters?.gameType);
  } else {
    params.append("no_page", filters.isPaginated);
    if (filters?.rewardAmount) params.append("type", filters?.rewardAmount);
  }

  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function gameLobbyDetail(game_slug, lobbyId) {
  const url =
    process.env.REACT_APP_APIURL + `games/${game_slug}/lobby/${lobbyId}/`;

  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers });
}

export async function participantList(game_slug, lobbyId) {
  const url =
    process.env.REACT_APP_APIURL +
    `games/${game_slug}/lobby/${lobbyId}/teammates/`;

  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers });
}
export async function gameFlowListing(filters) {
  let params = new URLSearchParams();
  if (filters?.q.length < 0) {
    if (filters?.limit) params.append("limit", filters?.limit);
    if (filters?.offset) params.append("&offset", filters?.offset);
  } else {
    if (filters?.limit) params.append("limit", filters?.limit);
    if (filters?.offset) params.append("offset", filters?.offset);
    if (filters?.q) params.append("q", filters?.q);
  }
  let headers = {
    "Content-Type": "application/json",
  };
  const url =
    process.env.REACT_APP_APIURL + `games/${filters.game_slug}/game-demo/`;
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function gameFlowAllListing(model) {
  let params = new URLSearchParams();
  if (model?.q.length < 0) {
    if (model?.limit) params.append("limit", model?.limit);
    if (model?.offset) params.append("&offset", model?.offset);
  } else {
    if (model?.limit) params.append("limit", model?.limit);
    if (model?.offset) params.append("offset", model?.offset);
    if (model?.q) params.append("q", model?.q);
  }
  const url = process.env.REACT_APP_APIURL + `games/game-demos/`;
  let headers = {
    "Content-Type": "application/json",
  };
  localStorage.removeItem("enroll-event");
  setToken();
  return await axiosApi.get(url, { headers, params });
}
