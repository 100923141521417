import React from "react";
import Home from "../component/home/Home";
import Login from "../component/auth/SignIn";
import Register from "../component/auth/SignUp";
import ForgotPassword from "../component/auth/ForgetPassword";
import GamesList from "../component/games/GamesList";
import LobbyList from "../component/Lobby/LobbyList";
import LobbyDetail from "../component/Lobby/LobbyDetail";
import Faq from "../component/faq/Faq";
import PPK from "../component/faq/FaqList";
import MyProfile from "../component/myprofile/MyProfile";
import ResetPassword from "../component/auth/ResetPassword";
import accountVerification from "../component/auth/AccountVerification";
import Dashboard from "../component/dashboard/Dashboard";
import PrivacyPolicy from "../component/privacy/PrivacyPolicy";
import CaliforniaPrivacy from "../component/privacy/CaliforniaPrivacy";
import TermsOfUse from "../component/privacy/TermsOfUse";
import Logout from "../component/auth/Logout";
import VpnWarning from "../component/vpn/VpnWarning";
import BrandGuidelines from "../component/brandguidelines/BrandGuidelines";
import PageNotFound from "../component/vpn/PageNotFound";
import StripeForm from "../component/payment/StripeForm";
import SignUpSuccess from "../component/auth/SignupSuccess";
import ManageCookies from "../home/ManageCookies";
import Community from "../component/communityPage/Community";
import Notifications from "../component/notifications";
import PlayerProfile from "../component/myprofile/PlayerProfile";
import GameFlow from "../component/gameFlow/GameFlow";
import ChallengesDetail from "../component/Lobby/ChallengesDetail";

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/games", component: GamesList },
  { path: "/faq", component: Faq },
  { path: "/ppk", component: PPK },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/california-privacy", component: CaliforniaPrivacy },
  { path: "/terms-of-use", component: TermsOfUse },
  { path: "/vpn", component: VpnWarning },
  { path: "/PageNotFound", component: PageNotFound },
  { path: "/:slug/lobby", component: LobbyList },
  { path: "/:slug/lobby/:lobbyId", component: LobbyDetail },
  { path: "/:slug/lobby/versus/:lobbyId", component: ChallengesDetail },
  { path: "/BrandGuidelines", component: BrandGuidelines },
  { path: "/manage-cookies", component: ManageCookies },
  { path: "/community", component: Community },
  { path: "/demos", component: GameFlow },
  // this route should be at the end of all other routes
  // { path: "*", exact: true, component: () => <Redirect to="/" /> },
];

const authRoutes = [
  { path: "/signin", component: Login },
  { path: "/signup", component: Register },
  { path: "/verifyEmail", component: SignUpSuccess },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/verification", component: accountVerification },
];

const privateRoutes = [
  { path: "/myprofile", component: MyProfile },
  { path: "/player-profile/:playerId", component: PlayerProfile },
  { path: "/dashboard", component: Dashboard },
  { path: "/logout", component: Logout },
  { path: "/payment", component: StripeForm },
  { path: "/notifications", component: Notifications },
];

export { publicRoutes, authRoutes, privateRoutes };
