export const userNameLeftBullets = [
    "Username may not start with a special character",
    "Special characters that are allowed [.-_]",
  ];
  
  export const userNameRightBullets = [
    "Minimum characters- 4",
    "Maximum characters- 16",
    "Spaces are not allowed",
  ];
  
  export const passWordLeftBullets = [
    "At least one letter",
    "At least one number",
    "Spaces are not allowed",
    "At least one special character [@$!%*#?&^_-]",
  ];
  
  export const PasswordRightBullets = [
    "Minimum characters - 8",
    "Maximum characters - 15",
    "Password may not start with a special character",
  ];
  
