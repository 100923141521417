import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ConsoleHelper } from "../../helper/utilities";
import toastify from "../../helper/toastify";
import Select from "react-select/async";
import {
  getPlayersForChallengeReplace,
  replaceMemberChallenge,
  leaveMemberChallenge,
  makePaymentChallenge,
} from "../../services/teams.service";
import { debounce } from "lodash";
import PaymentComponent from "../PaymentMethod";
import { useParams } from "react-router-dom";

function ChallengeParticipants({
  participantData,
  lobbyData,
  getChallenges,
  currentPlayer,
  games,
  openAddToWallet,
  canReplace,
  canModify,
}) {
  const comparator = (x) => (x.leader ? -1 : 1);
  const [showMore, setShowMore] = useState(false);
  const [selectedShowMoreTeamId, setSelectedShowMoreTeamId] = useState(null);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [replaceMember, setReplaceMember] = useState(null);
  const [oldMember, setOldMember] = useState(null);
  const [checkReplaceModal, setCheckReplaceModal] = useState(false);
  const [orderedParticipants, setOrderedParticipants] = useState([]);
  const { lobbyId } = useParams();

  const handleShowMore = (id) => {
    if (selectedShowMoreTeamId === null || id === selectedShowMoreTeamId) {
      setSelectedShowMoreTeamId(id);
      setShowMore(!showMore);
    } else {
      setShowMore(false);
      setSelectedShowMoreTeamId(id);
      setShowMore(true);
    }
  };
  const [spinnerLoaderReplace, setSpinnerLoaderReplace] = useState(false);
  const getReplaceOptions = async ({
    name = "",
    team,
    callback = () => {},
  }) => {
    try {
      const res = await getPlayersForChallengeReplace({
        name,
        team,
        challenge_id: lobbyId,
      });
      if (res.length) {
        callback(
          res
            ?.map((item) => ({
              ...item,
              value: item.player_id,
              label: item.username,
            }))
            .filter((item) => item.is_available)
        );
      }
      return callback([]);
    } catch (error) {}
  };

  const handleSearch = (name, callback) => {
    getReplaceOptions({
      name,
      team: currentPlayer?.teams?.team_id,
      callback,
    });
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), [
    currentPlayer,
  ]);
  const handleInputChange = async (inputValue) => {
    setSearchQuery(inputValue);
  };
  const getData = (name, callback) => {
    debouncedHandleSearch(name, callback);
  };

  const handleReplace = (teammate) => {
    setOldMember(teammate);
    setShowReplaceModal(true);
  };

  const handleLeave = () => setShowLeaveModal(true);
  const handleMakePayment = (teammate) => {
    setOldMember(teammate);
    setShowMakePaymentModal(true);
  };
  const leaveTeam = async () => {
    try {
      await leaveMemberChallenge({
        team_id: currentPlayer?.teams.team_id,
        challenge_id: lobbyData?.id,
      });

      toastify("success", "Leaved the team successfully");
      getChallenges();
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
      localStorage.setItem(
        "enroll-event",
        "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
      );
    } catch (error) {
      ConsoleHelper(error);
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
    }
  };

  const replaceTeamMate = async () => {
    try {
      const payload = {
        remove_player: oldMember.player_id,
        new_player: replaceMember.id,
      };
      setSpinnerLoaderReplace(true);
      await replaceMemberChallenge({
        data: payload,
        team_id: currentPlayer?.teams.team_id,
        challenge_id: lobbyData?.id,
      });

      toastify("success", "Member replaced successfully");
      getChallenges();
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
      localStorage.setItem(
        "enroll-event",
        "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
      );
    } catch (error) {
      ConsoleHelper(error);
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
    }
  };

  const handleChange = (newMember) => {
    setReplaceMember(newMember);
  };

  const handleMakePaymentCancel = () => setShowMakePaymentModal(false);
  const handleMakePaymentSubmit = async ({ bonus }) => {
    try {
      const payload = {
        team_id: currentPlayer?.teams.team_id,
        challenge_id: lobbyData?.id,
        user_id: oldMember?.player_id,
        promo_choice: bonus,
      };
      await makePaymentChallenge(payload);
      toastify("success", "Payment made successfully");
      getChallenges();
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
      localStorage.setItem(
        "enroll-event",
        "enroll" + window.crypto.getRandomValues(new Uint32Array(1))[0]
      );
    } catch (error) {
      ConsoleHelper(error);
      setSpinnerLoaderReplace(false);
      setShowReplaceModal(false);
    }
  };
  const hideReplaceButton = (item) =>
    !currentPlayer.leader ||
    currentPlayer.teams.team_id != item.teams.team_id ||
    (item.pay_type == 2 && item.invite_status == "Accepted") ||
    item.id == currentPlayer.id ||
    lobbyData.current_status > 2
      ? "d-none"
      : "";

  const hideLeaveButton = (item) =>
    currentPlayer?.invite_status != "Accepted" ||
    currentPlayer?.leader ||
    currentPlayer.username != item.username
      ? "d-none"
      : "";

  const hideMakePaymentButton = (item) =>
    !currentPlayer.leader ||
    currentPlayer.teams.team_id != item.teams.team_id ||
    item.payment_status ||
    item.id == currentPlayer.id ||
    lobbyData.current_status > 2
      ? "d-none"
      : "";

  useEffect(() => {
    setOrderedParticipants(
      participantData?.map((data, id) => data?.sort(comparator))
    );
  }, [participantData]);
  return (
    <>
      <div className="col mb-4 left-card">
        <div className="card card_teams">
          <div className="card-body members-cards challengeCards">
            <div className="teams-information  w-100 challengesTeams mb--10">
              <table>
                {orderedParticipants?.map((data, id) => {
                  return data?.map((item, idx) => {
                    return (
                      <>
                        {idx === 0 ? (
                          <tr className="topHeader d-block w-100 justify-content-between">
                            <th className=" d-flex ">
                              <p>
                                {" "}
                                {`${item.teams.name} | ${data.length} Members`}
                              </p>

                              <button
                                className="challengeAccordion challengeUpDown"
                                onClick={() =>
                                  handleShowMore(item.teams.team_id)
                                }
                                type="button"
                              >
                                {selectedShowMoreTeamId === null ? (
                                  <img
                                    src="/assets/images/dropdown-white.svg"
                                    alt="dropdown-image"
                                  />
                                ) : selectedShowMoreTeamId ===
                                    item.teams.team_id && showMore ? (
                                  <img
                                    src="/assets/images/dropdown-up.svg"
                                    alt="dropUp-image"
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/dropdown-white.svg"
                                    alt="dropdown-image"
                                  />
                                )}
                              </button>
                            </th>
                          </tr>
                        ) : null}

                        <tr
                          className={
                            selectedShowMoreTeamId === null
                              ? "d-none"
                              : selectedShowMoreTeamId === item.teams.team_id &&
                                showMore
                              ? ""
                              : "d-none"
                          }
                        >
                          <th key={idx}>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <>{idx === 0 ? "Team Players" : null}</>
                            ) : null}
                          </th>
                          <th>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <>{idx === 0 ? "Payment Status" : null}</>
                            ) : null}
                          </th>
                          <th>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <>{idx === 0 ? "Challenge Status" : null}</>
                            ) : null}
                          </th>
                          <th>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <>{idx === 0 ? "Actions" : null}</>
                            ) : null}
                          </th>
                        </tr>
                        <tr
                          className={
                            selectedShowMoreTeamId === null
                              ? "d-none"
                              : selectedShowMoreTeamId === item.teams.team_id &&
                                showMore
                              ? ""
                              : "d-none"
                          }
                        >
                          <td>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <p
                                className={`${
                                  item.invite_status === "Left"
                                    ? "leftMember"
                                    : ""
                                } leader-member`}
                              >
                                {item.username}{" "}
                                {item.leader ? <>(Creator)</> : <>(Member)</>}
                              </p>
                            ) : null}
                          </td>
                          <td>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <p
                                className={`${
                                  item.invite_status === "Left"
                                    ? "leftMember"
                                    : ""
                                }`}
                              >
                                {item.payment_status ? "Paid" : "Unpaid"}
                              </p>
                            ) : null}{" "}
                          </td>
                          <td>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <p
                                className={`${
                                  item.invite_status === "Left"
                                    ? "leftMember"
                                    : ""
                                }`}
                              >
                                {item.invite_status}
                              </p>
                            ) : null}{" "}
                          </td>
                          <td>
                            {selectedShowMoreTeamId ===
                            null ? null : selectedShowMoreTeamId ===
                                item.teams.team_id && showMore ? (
                              <div className="d-flex  gap-3 align-items-center">
                                <Button
                                  className={`declineAction memberReplaceBtn ${hideReplaceButton(
                                    item
                                  )}`}
                                  onClick={() => handleReplace(item)}
                                  disabled={!canReplace}
                                >
                                  Replace
                                </Button>
                                <Button
                                  className={`declineAction memberReplaceBtn ${hideLeaveButton(
                                    item
                                  )}`}
                                  onClick={handleLeave}
                                  disabled={!canModify}
                                >
                                  Leave
                                </Button>
                                <Button
                                  className={`declineAction memberReplaceBtn  ${hideMakePaymentButton(
                                    item
                                  )}`}
                                  onClick={() => handleMakePayment(item)}
                                  disabled={!canModify}
                                >
                                  Pay
                                </Button>
                              </div>
                            ) : null}{" "}
                          </td>
                        </tr>
                      </>
                    );
                  });
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
      {showReplaceModal ? (
        <Modal show={true} onHide={() => setShowReplaceModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Replace Teammate</Modal.Title>
          </Modal.Header>

          <Modal.Body className="make-payment mt-3">
            <h5 className="mb-2">Choose Team Mate</h5>
            <Select
              className="react-select-container-teammates"
              classNamePrefix="react-select-teammates"
              components={{
                IndicatorSeparator: () => null,
              }}
              placeholder="Please select a new teammate"
              inputValue={searchQuery}
              onInputChange={handleInputChange}
              closeMenuOnSelect={true}
              loadOptions={getData}
              onChange={handleChange}
              isClearable={true}
            />
            <Form className="mt-3">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  className="checkbox-replace-prompt"
                  label={`Replace the existing teammate “${
                    oldMember?.username
                  }” ${
                    replaceMember?.username
                      ? `with ${replaceMember?.username}`
                      : ""
                  }`}
                  onChange={() => setCheckReplaceModal((check) => !check)}
                  checked={checkReplaceModal}
                  // disabled={!parseInt(promoBonus)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="total-money total delete-footer registerCancelBtn">
            <Button
              variant="primary"
              onClick={() => setShowReplaceModal(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={replaceTeamMate}
              disabled={!checkReplaceModal || !replaceMember}
            >
              {spinnerLoaderReplace ? (
                <span className="spinner-border spinner-border-sm mr-4"></span>
              ) : (
                "Replace"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {showLeaveModal ? (
        <Modal show onHide={() => setShowLeaveModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-body text-center leave-modal">
            <div className="refer-friend-icons">
              <p className="remove-heading invite-para">Leave Team?</p>
              <p className="leave-modal-text">
                Are you sure you want to leave the team? There will be no
                refunds
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="delete-footer mb-4">
            <Button
              className="cancel-btn"
              onClick={() => setShowLeaveModal(false)}
            >
              Cancel
            </Button>
            <Button className="yes-btn" onClick={leaveTeam}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {showMakePaymentModal ? (
        <Modal show={true} onHide={handleMakePaymentCancel}>
          <PaymentComponent
            entry_fee={lobbyData.entry_fee}
            mode={1}
            selectedGame={
              games.filter((game) => lobbyData.game == game.id)?.[0]
            }
            free_lobby={false}
            handleCancel={handleMakePaymentCancel}
            handleSubmit={handleMakePaymentSubmit}
            openAddToWallet={openAddToWallet}
            creatorPay={false}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default ChallengeParticipants;
