import React, { Fragment, useState, useEffect } from "react";
import {
  signup,
  discordLogin,
  twitchLogin,
  twitchUserData,
  validateTwitch,
  facebookLogin,
  googleLogin,
  twitterLogin,
  twitterUrl,
} from "../../services/authService.js";
import { loginUser } from "../../store/auth/login/actions.js";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import AuthWrapper from "./AuthWrapper.jsx";
import { useLocation, withRouter, Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import axios from "axios";
import publicIp from "public-ip";
import toastify from "../../helper/toastify.jsx";
import GoogleLogin from "react-google-login";
import Form from "react-bootstrap/Form";

import { FacebookProvider, Login } from "react-facebook";
import ReactTooltip from "react-tooltip";
import Cookies from "js-cookie";
import PasswordValidation from "../passwordValidation/index.js";
import {
  passWordLeftBullets,
  PasswordRightBullets,
  userNameLeftBullets,
  userNameRightBullets,
} from "../../constants/constantData.js";
import {
  ConsoleHelper,
  YupSchemaConfirmPassword,
  YupSchemaPassword,
  getUrlChanger,
  handleBannedLocationError,
} from "../../helper/utilities.js";
import {
  PLACEHOLDERS,
  SOCIAL_ACCOUNTS,
  STRINGS_CONSTANTS,
  VALIDATIONS_ERROR,
} from "../../helper/stringConstants.js";
import PasswordComponent from "../PasswordComponent/index.js";
const SignUp = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);
  const [value, onChange] = useState(() => getValue());
  const [originalDOB, setDOB] = useState("");
  const [player_ip, setIP] = useState("");
  const [socialProvider, setProvider] = useState("");
  const [socialToken, setSocialToken] = useState("");
  const [spinnerLoader, setSpinnerLoader] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(VALIDATIONS_ERROR.firstNameRequired)
      .min(3, VALIDATIONS_ERROR.firstNameMin(3))
      .max(50, VALIDATIONS_ERROR.firstNameMax(50)),
    lastName: Yup.string()
      .trim()
      .required(VALIDATIONS_ERROR.lastNameRequired)
      .min(2, VALIDATIONS_ERROR.lastNameMin(2))
      .max(50, VALIDATIONS_ERROR.lastNameMax(50)),
    email: Yup.string()
      .trim()
      .email(VALIDATIONS_ERROR.invalidEmail)
      .required(VALIDATIONS_ERROR.requiredEmail),
    username: Yup.string()
      .trim()
      .required(VALIDATIONS_ERROR.userNameRequired)
      .matches(
        /^[A-Za-z0-9][A-Za-z0-9._-]*$/,
        VALIDATIONS_ERROR.userNamePattern
      )
      .min(4, VALIDATIONS_ERROR.userNamePattern)
      .max(16, VALIDATIONS_ERROR.userNamePattern),
    password: YupSchemaPassword(),
    confirmPassword: YupSchemaConfirmPassword(),
    affiliate_code: Yup.string().notRequired(),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email:
        props?.location?.state === undefined
          ? ""
          : props?.location?.state?.data?.email,
      firstName:
        props?.location?.state === undefined
          ? ""
          : props?.location?.state?.data?.first_name,
      lastName:
        props?.location?.state === undefined
          ? ""
          : props?.location?.state?.data?.last_name,
    },
    resolver: yupResolver(validationSchema),
  });

  const [ageError, setAgeError] = useState("");
  const [ageModal, setAgeModal] = useState(false);
  const [ageCheck, setAgeCheck] = useState();

  const [twitterLink, setTwitterUrl] = useState("");
  const [calledOnce, setCalledOnce] = useState(false);
  const queryParam = useLocation().search;
  const code = new URLSearchParams(queryParam).get("code");
  const scope = new URLSearchParams(queryParam).get("scope");
  const referral = new URLSearchParams(queryParam).get("referral");
  const oauth_token = new URLSearchParams(queryParam).get("oauth_token");
  const oauth_verifier = new URLSearchParams(queryParam).get("oauth_verifier");

  const urlChanger = getUrlChanger("signup");
  useEffect(() => {
    getIp();
    getLink();
    if (scope !== "" && scope !== null) {
      setSpinnerLoader(true);
      axios
        .post(
          `https://id.twitch.tv/oauth2/token?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_CLIENT_SECRET_KEY}&grant_type=authorization_code&redirect_uri=${urlChanger}&code=${code}`
        )
        .then((res) => {
          handleTwitch(res);
        })
        .catch((err) => {
          toastify("error", err.response.data.message);
          setSpinnerLoader(false);
        });
    } else if (oauth_token !== "" && oauth_token !== null) {
      setSpinnerLoader(true);
      const data = `${oauth_token}&${oauth_verifier}`;
      handleTwitter(data);
    } else if (code !== "" && code !== null) {
      setSpinnerLoader(true);
      let data = {
        client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
        client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET_KEY,
        code: code.toString(),
        grant_type: "authorization_code",
        scope: "identify",
        redirect_uri: getUrlChanger("signup"),
      };
      const config = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      axios
        .post(
          "https://discord.com/api/oauth2/token",
          new URLSearchParams(data),
          config
        )
        .then((res) => {
          handleDiscord(res);
        })
        .catch((err) => {
          toastify("error", err.response.data.message);
          setSpinnerLoader(false);
        });
    } else if (referral !== "" && referral !== null) {
      handleReferral(referral);
    } else {
      return;
    }
  }, [reset]);
  const handleReferral = (data) => {
    reset({ affiliate_code: data });
    Cookies.set("Referral", data);
  };
  const getLink = async () => {
    let data = {
      redirect: "signup",
    };
    await twitterUrl(data)
      .then((res) => {
        setTwitterUrl(res.url);
      })
      .catch((err) => {
        ConsoleHelper(err);
      });
  };
  const handleSocialLogin = async (provider, socialCode, login, response) => {
    let data = {
      provider: provider,
      code: socialCode,
    };
    await login(data)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.extras));
        dispatch(loginUser(res.extras));
        if (props.location.state == undefined) {
          props.history.push("/myprofile");
        } else {
          props.history.push(props.location.state.prevPath);
        }
        setSpinnerLoader(false);
        if (Cookies.get("Referral") !== undefined) {
          Cookies.remove("Referral");
        } else return;
      })
      .catch((err) => {
        const str = err;
        const words = str.split(" ");
        if (words[0].includes("@")) {
          props.history.push({
            pathname: "/verifyEmail",
            state: { email: words[0] },
          });
        } else if (words[0] === "An") {
          resetFormStateFromProvider(response);
        } else if (words.length === 13) {
          toastify("success", STRINGS_CONSTANTS.incompleteSignUpForm);
          reset({
            firstName:
              response?.profile?.first_name || response.profileObj.givenName,
            lastName:
              response?.profile?.last_name || response.profileObj.familyName,
            email: response?.profile?.email || response.profileObj.email,
            affiliate_code:
              referral === "" || referral === null ? "" : referral,
          });
          setProvider(words[1]);
          setSocialToken(words[0]);
          onChange(
            response?.profile?.birthday === undefined
              ? ""
              : new Date(response?.profile?.birthday).toLocaleString("en-US", {
                  day: "numeric", // numeric, 2-digit
                  year: "numeric", // numeric, 2-digit
                  month: "numeric", // numeric, 2-digit, long, short, narrow
                  timeZone: "UTC",
                })
          );
        } else {
          resetFormStateFromProvider(response);
        }
        setSpinnerLoader(false);
      });
  };

  const handleLogin = async (provider, socialCode, login, response) => {
    if (provider === "facebook") {
      setSpinnerLoader(true);
      handleSocialLogin(provider, socialCode, login, response);
    } else if (provider === "google") {
      if (response.valid || response.valid === undefined) {
        setSpinnerLoader(true);
        handleSocialLogin(provider, socialCode, login, response);
      } else {
        return;
      }
    } else {
      setSpinnerLoader(true);
      handleSocialLogin(provider, socialCode, login);
    }
  };

  const handleTwitter = async (oauth) => {
    handleLogin("twitter", oauth, twitterLogin);
  };

  const handleDiscord = async (discordRes) => {
    axios
      .get("https://discord.com/api/v9/users/@me", {
        headers: { Authorization: `Bearer ${discordRes.data.access_token}` },
      })
      .then((discordAuthorizationRes) => {
        reset({
          email: discordAuthorizationRes.data.email,
          affiliate_code: getAffiliateCode(),
        });
      })
      .catch((err) => {
        ConsoleHelper(err);
      });
    handleLogin("discord", discordRes.data.access_token, discordLogin);
  };

  const handleTwitch = async (twitchRes) => {
    const newToken = twitchRes.data.access_token;
    await validateTwitch(newToken)
      .then((twitchValidationRes) => {
        fetchData(twitchValidationRes.data, newToken);
      })
      .catch((err) => {
        setSpinnerLoader(false);
      });
    handleLogin("twitch", newToken, twitchLogin);
  };

  const fetchData = async (dataTwitch, token) => {
    await twitchUserData(dataTwitch.user_id, token)
      .then((res) => {
        reset({
          email: res.data.data[0].email,
          affiliate_code: getAffiliateCode(),
        });
        setSpinnerLoader(false);
      })
      .catch((err) => {
        setSpinnerLoader(false);
      });
  };
  const [dobErrorBoolean, setDOBError] = useState(false);
  const [dobErroMsg, setDOBerrorMsg] = useState({
    required: "",
    month: "",
    day: "",
    year: "",
  });
  async function onSubmit(data) {
    if (ageCheck >= 18) {
      let body = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        username: data.username,
        password: data.password,
        dob: originalDOB,
        player_ip: player_ip,
        provider: socialProvider,
        social_token: socialToken,
        affiliate_code: data.affiliate_code,
      };
      showLoader(true);
      seterrorMsg(null);

      await signup(body)
        .then((res) => {
          showLoader(false);
          props.history.push({
            pathname: "/verifyEmail",
            state: { email: data.email },
          });
          if (Cookies.get("Referral") !== undefined) {
            Cookies.remove("Referral");
          } else return;
        })
        .catch((err) => {
          showLoader(false);
          seterrorMsg(err);
        });
    } else {
      setAgeModal(true);
      setAgeError(VALIDATIONS_ERROR.minUserAge);
    }
  }

  const getProvider = () => {
    if (props?.location?.state === undefined) {
      return socialProvider === "" ? "" : socialProvider;
    }
    return props?.location?.state?.provider;
  };

  const getsocialToken = () => {
    if (props?.location?.state === undefined) {
      return socialToken === "" ? "" : socialToken;
    }
    return props?.location?.state?.socialToken;
  };

  const getIp = async () => {
    try {
      const res = await publicIp.v4();
      setIP(res);
      if (value !== "" && !calledOnce) {
        setCalledOnce(true);
        CallAgeCalculator(value);
        setProvider(getProvider());
        setSocialToken(getsocialToken);
      }
    } catch (error) {
      toastify(
        "error",
        error.message +
          " ,Please try again after disabling your adblocker if enabled"
      );
    }
  };
  function showLoader(isLoad) {
    setisLoading(isLoad);
  }

  const closeAgeModal = () => {
    setAgeModal(false);

    setAgeError("");
  };
  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  const getDayValidation = (DayValidation) => {
    if (parseInt(DayValidation[0].value) < parseInt(DayValidation[0].min))
      return "Date should be greater than 0";
    else if (parseInt(DayValidation[0].value) > parseInt(DayValidation[0].max))
      return `Date should be less than or equal to ${DayValidation[0].max}`;
    else return "";
  };

  const getMonthValidation = (monthValue) => {
    if (monthValue == 0) return "Month should be greater than 0";
    else if (monthValue > 12)
      return "Month should be less than or equal to 12 ";
    else return "";
  };

  const getYearValidations = (YearValidation) => {
    if (parseInt(YearValidation[0].value) < parseInt(YearValidation[0].min))
      return `Year should be greater than ${YearValidation[0].min}`;
    else if (
      parseInt(YearValidation[0].value) >= parseInt(YearValidation[0].max)
    )
      return VALIDATIONS_ERROR.minUserAge;
    else return "";
  };

  function onInput(event) {
    let MonthValidation = document.getElementsByClassName(
      "react-date-picker__inputGroup__input react-date-picker__inputGroup__month"
    );
    let DayValidation = document.getElementsByClassName(
      "react-date-picker__inputGroup__input react-date-picker__inputGroup__day"
    );
    let YearValidation = document.getElementsByClassName(
      "react-date-picker__inputGroup__input react-date-picker__inputGroup__year"
    );
    if (
      MonthValidation[0].value.length != 0 ||
      DayValidation[0].value.length != 0 ||
      YearValidation[0].value.length != 0
    ) {
      setDOBError(true);
      setDOBerrorMsg({
        required:
          MonthValidation[0].value.length === 0 &&
          DayValidation[0].value.length === 0 &&
          YearValidation[0].value.length === 0
            ? VALIDATIONS_ERROR.DOBRequired
            : "",
        month: getMonthValidation(parseInt(MonthValidation[0].value)),
        day: getDayValidation(DayValidation),
        year: getYearValidations(YearValidation),
      });
    } else if (
      MonthValidation[0].value.length == 0 ||
      DayValidation[0].value.length == 0 ||
      YearValidation[0].value.length == 0
    ) {
      setDOBError(true);
      setDOBerrorMsg({
        required: acceptTerms || accept18 ? VALIDATIONS_ERROR.DOBRequired : "",
        month: "",
        day: "",
        year: "",
      });
    } else {
      setDOBError(false);
      setDOBerrorMsg({
        required: "",
        month: "",
        day: "",
        year: "",
      });
    }
  }

  const onChangeDOB = (DOBValue) => {
    if (DOBValue === null) {
      onChange("");
    } else {
      const formatdDate = formatDate(DOBValue);
      setDOB(formatdDate);
      let diff_ms = Date.now() - DOBValue.getTime();
      let age_dt = new Date(diff_ms);
      const age = Math.abs(age_dt.getUTCFullYear() - 1970);
      setAgeCheck(age);
      if (age < 18) {
        setAgeModal(true);
        setAgeError(VALIDATIONS_ERROR.minUserAge);
      } else {
        setAgeModal(false);
        setAgeError("");
      }
      onChange(DOBValue);
    }
  };
  const CallAgeCalculator = (ageValue) => {
    const formatdDate = formatDate(ageValue);
    setDOB(formatdDate);
    let today = new Date();
    let birthDate = new Date(formatdDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAgeCheck(age);
    if (age < 18) {
      setAgeModal(true);
      setAgeError(VALIDATIONS_ERROR.minUserAge);
    } else {
      setAgeModal(false);
      setAgeError("");
    }
  };

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [accept18, setAccept18] = useState(false);
  const onChangeCheck = (e) => {
    getIp();
    const check = e.target.checked;
    setAcceptTerms(check);
  };
  const onChange18 = (e) => {
    const check = e.target.checked;
    setAccept18(check);
  };
  const responseFacebook = async (response) => {
    handleLogin(
      "facebook",
      response.tokenDetail.accessToken,
      facebookLogin,
      response
    );
  };

  const responseGoogle = async (response) => {
    handleLogin("google", response?.tokenObj?.id_token, googleLogin, response);
  };

  const responseFacebookError = (error) => {
    ConsoleHelper(error, ">>>");
  };

  const responseErrorGoogle = (response) => {
    if (
      response.details === "Cookies are not enabled in current environment."
    ) {
      toastify("info", STRINGS_CONSTANTS.cookieMessage);
      setSpinnerLoader(false);
    } else {
      setSpinnerLoader(false);
    }
  };

  function getValue() {
    if (
      props?.location?.state === undefined ||
      props?.location?.state?.provider === "google" ||
      props?.location?.state?.data?.birthday === undefined ||
      props?.location?.state?.data?.birthday === ""
    ) {
      return "";
    } else {
      return new Date(props?.location?.state?.data?.birthday).toLocaleString(
        "en-US",
        {
          day: "numeric", // numeric, 2-digit
          year: "numeric", // numeric, 2-digit
          month: "numeric", // numeric, 2-digit, long, short, narrow
          timeZone: "UTC",
        }
      );
    }
  }

  const resetProviderAndToken = () => {
    setProvider("");
    setSocialToken("");
  };

  const getAffiliateCode = () => {
    if (referral === "" || referral === null) {
      return Cookies.get("Referral") === undefined
        ? ""
        : Cookies.get("Referral");
    }
    return referral;
  };

  const resetFormStateFromProvider = (response, provider) => {
    let firstName, lastName, email, birthday;

    switch (provider) {
      case "facebook":
        firstName = response.profile.first_name;
        lastName = response.profile.last_name;
        email = response.profile.email;
        birthday = response?.profile?.birthday;
        break;
      case "google":
        firstName = response.profile.given_name;
        lastName = response.profile.family_name;
        email = response.profile.email;
        birthday = response?.profile?.birthday;
        break;
      case "twitch":
        firstName = response.profile.display_name;
        email = response.profile.email;
        break;
      case "twitter":
        firstName = response.profile.name;
        break;
      case "discord":
        firstName = response.profile.username;
        break;
      default:
        break;
    }

    reset({
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      affiliate_code: referral === "" || referral === null ? "" : referral,
    });

    resetProviderAndToken();

    if (birthday) {
      onChange(
        new Date(birthday).toLocaleString("en-US", {
          day: "numeric",
          year: "numeric",
          month: "numeric",
          timeZone: "UTC",
        })
      );
    }
  };
  return (
    <Fragment>
      <div id="contact" className="fix">
        {spinnerLoader ? (
          <div class="loading"></div>
        ) : (
          <div className="rn-contact-area ptb--120 bg_color--1">
            <div className="contact-form--1">
              <div className="container">
                <div className="row row--35 align-items-start">
                  <div className="col-lg-6 image-class signup-image">
                    <AuthWrapper data={props} />
                  </div>
                  <div className="col-lg-6 form-class">
                    <div className="section-title text-left mb--50">
                      <h2 className="title sign-in-title">
                        {STRINGS_CONSTANTS.signup}
                      </h2>
                      <p className="description continue">
                        Create a new account
                      </p>
                    </div>

                    <div className="sign-in-bottom-content">
                      <div className="">
                        <div className="social-icons">
                          <div className="grow">
                            <a
                              href={`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${urlChanger}&scope=viewing_activity_read+openid+user:read:email&force_verify=true`}
                            >
                              <img
                                src="/assets/images/Twitch.svg"
                                alt="Twitch"
                                data-tip
                                data-for="socialloginTip"
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.twitch}
                            </ReactTooltip>{" "}
                          </div>
                          <div className="grow">
                            <FacebookProvider
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            >
                              <Login
                                fields="first_name, last_name, email, picture, birthday, link"
                                scope="public_profile, email, user_birthday, user_link"
                                onCompleted={responseFacebook}
                                onError={responseFacebookError}
                              >
                                {({ loading, handleClick, error, data }) => (
                                  <img
                                    onClick={handleClick}
                                    src="/assets/images/facebook-one.svg"
                                    alt="facebook"
                                    style={{ cursor: "pointer" }}
                                    className="social-login"
                                    data-tip
                                    data-for="socialloginTip1"
                                  />
                                )}
                              </Login>
                            </FacebookProvider>
                            <ReactTooltip
                              id="socialloginTip1"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.facebook}
                            </ReactTooltip>{" "}
                          </div>
                          <div className="grow">
                            <a
                              href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${urlChanger}&response_type=code&scope=identify%20email`}
                            >
                              <img
                                src="/assets/images/chats.svg"
                                alt="Discord"
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="socialloginTip2"
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip2"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.discord}
                            </ReactTooltip>{" "}
                          </div>

                          <div className="grow">
                            <GoogleLogin
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              autoLoad={false}
                              onSuccess={responseGoogle}
                              onFailure={responseErrorGoogle}
                              cookiePolicy={"single_host_origin"}
                              render={(renderProps) => (
                                <img
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                  src="/assets/images/Google.svg"
                                  alt="Google"
                                  style={{ cursor: "pointer" }}
                                  data-tip
                                  data-for="socialloginTip3"
                                />
                              )}
                            />
                            <ReactTooltip
                              id="socialloginTip3"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.google}
                            </ReactTooltip>{" "}
                          </div>
                          <div>
                            <a
                              data-tip
                              data-for="socialloginTip4"
                              href={twitterLink}
                            >
                              <img
                                src="/assets/images/twitter-one.svg"
                                alt="chats"
                                style={{ cursor: "pointer" }}
                              />
                            </a>
                            <ReactTooltip
                              id="socialloginTip4"
                              place="bottom"
                              effect="solid"
                            >
                              {SOCIAL_ACCOUNTS.twitter}
                            </ReactTooltip>{" "}
                          </div>
                        </div>
                        <h3>- Or -</h3>
                        <h4>{STRINGS_CONSTANTS.signUpWith}</h4>
                      </div>
                    </div>

                    <div className="form-wrapper">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        onReset={reset}
                        onInput={onInput}
                      >
                        <label htmlFor="item01">
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            id="item01"
                            {...register("firstName")}
                            className={`${
                              errors.firstName ? "is-invalid" : ""
                            }`}
                          />
                        </label>
                        {errors.firstName?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.firstName?.message}
                          </div>
                        )}
                        <label htmlFor="item02">
                          <input
                            type="text"
                            name="lastName"
                            id="item02"
                            placeholder="Last Name"
                            {...register("lastName")}
                            className={`${errors.lastName ? "is-invalid" : ""}`}
                          />
                        </label>
                        {errors.lastName?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.lastName?.message}
                          </div>
                        )}
                        <label htmlFor="item03">
                          <DatePicker
                            name="dob"
                            className={`${ageError ? "is-invalid" : ""}`}
                            placeholderText={PLACEHOLDERS.DOB}
                            onChange={onChangeDOB}
                            value={value}
                            maxDate={new Date()}
                            openCalendarOnFocus={false}
                            dayPlaceholder="dd"
                            monthPlaceholder="mm"
                            yearPlaceholder="yyyy"
                            minDate={new Date("01-01-1900")}
                            id="item03"
                          />
                          <h6 class="dob">{PLACEHOLDERS.DOB}</h6>
                        </label>
                        {dobErrorBoolean ? (
                          <div className="invalid-feedback d-block">
                            {dobErroMsg.required == "" ? null : (
                              <> {dobErroMsg.required}</>
                            )}
                            <ul className="password-conditions-ul">
                              {dobErroMsg.month == "" ? null : (
                                <li>{dobErroMsg.month}</li>
                              )}
                              {dobErroMsg.day == "" ? null : (
                                <li>{dobErroMsg.day}</li>
                              )}
                              {dobErroMsg.year == "" ? null : (
                                <li>{dobErroMsg.year}</li>
                              )}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                        {ageError ? (
                          <div className="invalid-feedback d-block">
                            {ageError}
                          </div>
                        ) : (
                          ""
                        )}
                        <label htmlFor="item04">
                          <input
                            type="text"
                            name="email"
                            id="item04"
                            placeholder="Email"
                            {...register("email")}
                            className={`${errors.email ? "is-invalid" : ""}`}
                          />
                        </label>
                        {errors.email?.message && (
                          <div className="invalid-feedback d-block">
                            {errors.email?.message}
                          </div>
                        )}
                        <label htmlFor="item05">
                          <input
                            type="text"
                            name="username"
                            id="item05"
                            placeholder="Username"
                            {...register("username")}
                            className={`${errors.username ? "is-invalid" : ""}`}
                          />
                        </label>
                        {errors.username === undefined ||
                        errors?.username?.message === "Username is required" ? (
                          <>
                            {errors?.username?.message ===
                            "Username is required" ? (
                              <div className="invalid-feedback d-block">
                                {errors.username?.message}
                              </div>
                            ) : null}
                            <div className="d-block password-guidelines">
                              {" "}
                              <PasswordValidation
                                leftBulltets={userNameLeftBullets}
                                rightBullets={userNameRightBullets}
                              />
                            </div>
                          </>
                        ) : (
                          errors.username?.message && (
                            <div className="invalid-feedback d-block">
                              <PasswordValidation
                                leftBulltets={userNameLeftBullets}
                                rightBullets={userNameRightBullets}
                              />
                            </div>
                          )
                        )}
                        <PasswordComponent
                          PasswordRightBullets={PasswordRightBullets}
                          passWordLeftBullets={passWordLeftBullets}
                          errors={errors}
                          register={register}
                          htmlFor1="item06"
                          htmlFor2="item07"
                        />
                        <label htmlFor="item08">
                          <input
                            type="text"
                            name="affiliate_code"
                            id="item08"
                            placeholder="Affiliate code"
                            {...register("affiliate_code")}
                          />
                        </label>
                        {errorMsg && (
                          <div
                            className="invalid-feedback d-block my-2"
                            style={{ marginLeft: "1px" }}
                          >
                            {handleBannedLocationError(errorMsg)}
                          </div>
                        )}
                        <div className="terms-conditions-wrapper">
                          <div className="remember-me termsConditions">
                            <Form.Check
                              type="checkbox"
                              label={
                                <p>
                                  I agree to{" "}
                                  <Link to="/terms-of-use" target="_blank">
                                    terms & conditions
                                  </Link>
                                </p>
                              }
                              onChange={onChangeCheck}
                              checked={acceptTerms}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div class="less-age">
                          <div className="remember-me termsConditions">
                            <Form.Check
                              type="checkbox"
                              label={<p>{STRINGS_CONSTANTS.certifyMinAge}</p>}
                              onChange={onChange18}
                              checked={accept18}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <button
                          className="rn-button-style--2 btn-solid"
                          type="submit"
                          id="mc-embedded-subscribe"
                          disabled={accept18 && acceptTerms ? isLoading : true}
                        >
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                          )}{" "}
                          {STRINGS_CONSTANTS.signup}
                        </button>
                      </form>
                    </div>
                    <div className="not-a-member">
                      <h6 className="already-member">
                        Already a member?
                        <a href="/signin">
                          <span className="sign-up">
                            {STRINGS_CONSTANTS.signin}
                          </span>
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {ageModal ? (
        <Modal show={ageModal} onHide={() => closeAgeModal()}>
          <Modal.Header closeButton className="warning-header"></Modal.Header>
          <Modal.Body className="money-added warning-modal">
            <div class="warning-icon">
              <img src="/assets/images/warning.svg" alt="warning" />
            </div>
            <h3>{STRINGS_CONSTANTS.legalAgeWarning}</h3>
            <h4>{VALIDATIONS_ERROR.minUserAge}</h4>
          </Modal.Body>
        </Modal>
      ) : null}
    </Fragment>
  );
};
export default withRouter(SignUp);
