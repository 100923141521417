import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Paginator from "../../helper/paginator/Paginator";
import { getTeamLists } from "../../services/user.service";
import DeleteModal from "./DeleteModal";
import TeamModal from "./TeamModal";
import { ConsoleHelper, lobbyModeFilters } from "../../helper/utilities";
import { putTeamRequestProfile } from "../../services/teams.service";
import toastify from "../../helper/toastify";
const TeamModule = (props) => {
  const [teamList, setTeamList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageNum, setpageNum] = useState(1);
  useEffect(() => {
    if (props.activeKey === "teams") {
      getTeamList();
    }
  }, [props.activeKey, props.callTeam, pageNum]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [gameName, setGameName] = useState("");
  const getTeamList = async () => {
    let filter = {
      //  isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    props.showloader()
    await getTeamLists(filter).then((res) => {
      setTotalCount(res.total);
      setPageSize(res.page_size);
      setTeamList(res.results);
      const openEditModalToCompleteTeam = res.results.filter((data) => {
        return data.name === props?.gameName && props?.gameMode != 1;
      });
      if (openEditModalToCompleteTeam.length != 0) {
        setEditId(openEditModalToCompleteTeam[0].id);
        setGameName(openEditModalToCompleteTeam[0].name);
        setShowEditModal(!showEditModal);
      }
      props.hideloader()

    });
  };
  const handleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(id);
  };
  const hideDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const reCallTeamsList = () => {
    setShowDeleteModal(!showDeleteModal);
    getTeamList();
  };

  const handleEditModal = (id, game) => {
    setShowEditModal(!showEditModal);
    setEditId(id);
    setGameName(game);
  };
  const hideCreateTeamModal = () => {
    setShowEditModal(!showEditModal);
    props.onCancel();
  };
  const closeTeamModal = () => {
    setShowEditModal(!showEditModal);
    getTeamList();
  };
  const handlePageClick = (newPageNum) => {
    setpageNum(newPageNum);
  };

  const handleTeamRequest = async (team, action) => {
    try {
      props.showloader()
      await putTeamRequestProfile({ action, teamId: team.id });
      toastify(
        "success",
        action ? "Team request accepted" : "Team request rejected"
      );
      getTeamList();
    } catch (error) {
      ConsoleHelper(error);
      toastify("error", "Something went wrong");
      props.hideloader()

    }
  };

  return (
    <>
      {teamList.length === 0 ? (
        <h5 style={{ textAlign: "center", padding: "20px" }}>
          No Team(s) Created yet
        </h5>
      ) : (
        <div className="stats-wrapper">
          <table style={{ color: "white" }}>
            <tr>
              <th>GAME NAME</th>
              <th>TEAM MODE</th>
              <th>TEAM NAME</th>
              <th>TEAM CREATOR</th>
              <th>CREATION DATE</th>
              <th>ACTIONS</th>
            </tr>

            {teamList.length &&
              teamList?.map((team, idx) => {
                return (
                  <tr>
                    <td>
                      <p>{team.game || "NA"}</p>
                    </td>
                    <td>
                      <p>{lobbyModeFilters?.[team?.mode]?.label || "NA"}</p>
                    </td>
                    <td>
                      <p>{team.name || "NA"}</p>
                    </td>
                    <td>
                      <p>{team.creator || "NA"}</p>
                    </td>
                    <td>
                      <p>{team.create_date}</p>
                    </td>
                    <td>
                      {team.leader ? (
                        <div class="edit-icon profile-edit">
                          <img
                            src="/assets/images/edit-pen-white.svg"
                            alt="edit"
                            onClick={() => handleEditModal(team.id, team.game)}
                            data-tip
                            data-for="editTeam"
                          />
                          <ReactTooltip
                            id="editTeam"
                            place="bottom"
                            effect="solid"
                          >
                            Edit team name and teammates
                          </ReactTooltip>{" "}
                          <img
                            src="/assets/images/delete-icon.svg"
                            alt="Delete"
                            className="deleteicon cursorPointer ms-3"
                            data-tip
                            data-for="deleteTeam"
                            onClick={() => handleDeleteModal(team.id)}
                          />
                          <ReactTooltip
                            id="deleteTeam"
                            place="bottom"
                            effect="solid"
                          >
                            Delete team
                          </ReactTooltip>{" "}
                        </div>
                      ) : team.status != 1 ? (
                        <div class="edit-icon profile-edit">
                          <img
                            src="/assets/images/edit-pen-disabled.svg"
                            alt="edit"
                            style={{ cursor: "no-drop" }}
                            title="Edit Team"
                          />
                          <img
                            src="/assets/images/delete-disabled.svg"
                            alt="Delete"
                            className="deleteicon cursorPointer greyDisabledIcon"
                            style={{ cursor: "no-drop" }}
                            title="Delete Team"
                          />
                        </div>
                      ) : (
                        <div>
                          <Button
                            className="declineAction member-decline"
                            onClick={() => handleTeamRequest(team, false)}
                          >
                            Decline
                          </Button>
                          <Button
                            className="declineAction memberAccept primary-blue-color"
                            onClick={() => handleTeamRequest(team, true)}
                          >
                            Accept
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      )}

      <Paginator
        totalCount={totalCount}
        pageSize={pageSize}
        pageClick={handlePageClick}
      />
      <Modal
        className="create-team-modal-box"
        show={showDeleteModal}
        onHide={() => hideDeleteModal()}
        backdrop="static"
      >
        <DeleteModal
          Id={deleteId}
          onCancel={() => hideDeleteModal()}
          onDelete={() => reCallTeamsList()}
        />
      </Modal>
      <Modal
        className="create-team-modal-box"
        show={showEditModal}
        onHide={() => hideCreateTeamModal()}
        backdrop="static"
      >
        <TeamModal
          onComplete={() => closeTeamModal()}
          EditId={editId}
          GameName={gameName}
        />
      </Modal>
    </>
  );
};

export default TeamModule;
