import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { requestverification, verifyaccount } from "../../services/authService";
import { Spinner, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthWrapper from "./AuthWrapper";
import { STRINGS_CONSTANTS } from "../../helper/stringConstants";

const AccountVerification = (props) => {
  const [isSuccess, setisSuccess] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isReverifyLoading, setisReverifyLoading] = useState(false);
  const [isEmailSent, setisEmailSent] = useState(false);

  const queryParam = useLocation().search;
  const id = new URLSearchParams(queryParam).get("id");
  const token = new URLSearchParams(queryParam).get("token");

  useEffect(() => {
    checkVerificationLink();
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const [errorMsg, seterrorMsg] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function checkVerificationLink() {
    setisSuccess(false);
    showLoader(true);
    await verifyaccount(id, token)
      .then(() => {
        showLoader(false);
        setisSuccess(true);
      })
      .catch(() => {
        showLoader(false);
        setisSuccess(false);
      });
  }

  function showLoader(isLoad) {
    setisLoading(isLoad);
  }

  async function verificationRequested(data) {
    setisEmailSent(false);
    setisReverifyLoading(true);
    await requestverification(data.email)
      .then(() => {
        setisEmailSent(true);
        setisReverifyLoading(false);
      })
      .catch((err) => {
        setisEmailSent(false);
        setisReverifyLoading(false);
        seterrorMsg(err);
      });
  }

  const renderAccounVerification = (success) => {
    if (success) {
      return (
        <div className="section-title text-left mb--50">
          <div className="tick-image">
            <img src="/assets/images/tick.svg" alt="tick" />
          </div>
          <h2 className="title sign-in-title signup-successful">
            Sign Up<span>Successful!</span>
          </h2>
          <h3 className="thankyou">Thank You</h3>

          <Link to="/signin">
            {" "}
            <button class="signin-refer-button verification-signin rn-button-style--2 btn-solid">
              Sign in
            </button>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="mt-5">
          <div className="section-title text-left mb--50">
            <p className="verify">
              Link is expired, please request again for account verification
            </p>
          </div>
          <div className="form-wrapper">
            <form onClick={handleSubmit(verificationRequested)}>
              <label htmlFor="item01">
                <input
                  type="text"
                  name="email"
                  id="item01"
                  placeholder="Email Address"
                  {...register("email")}
                  className={`${errors.email ? "is-invalid" : ""}`}
                />
              </label>
              {errors.email?.message && (
                <div className="invalid-feedback d-block">
                  {errors.email?.message}
                </div>
              )}
              {errorMsg && (
                <div className="invalid-feedback d-block my-2">{errorMsg}</div>
              )}
              <button
                className="rn-button-style--2 btn-solid mt-4"
                type="submit"
                id="mc-embedded-subscribe"
                disabled={isReverifyLoading}
              >
                {isReverifyLoading && (
                  <span className="spinner-border spinner-border-sm mr-5 ml-5"></span>
                )}
                Request Verification
              </button>
            </form>
            {isEmailSent ? (
              <Alert variant="success" dismissible className="mt-4">
                <p>
                  {STRINGS_CONSTANTS.accountVerificationMail}
                </p>
              </Alert>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      {/* Start COntact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <div className="contact-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 image-class">
                  <AuthWrapper />
                </div>
                <div className="col-lg-6 form-class signup-success">
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner
                        animation="border"
                        variant="light"
                        className="spinny-loader"
                      ></Spinner>
                    </div>
                  ) : (
                    renderAccounVerification(isSuccess)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End COntact Area */}
    </Fragment>
  );
};
export default withRouter(AccountVerification);
