export default function AuthToken() {
  const obj = JSON.parse(localStorage.getItem("user"));

  if (obj && obj.access_token) {
    return `Bearer ${obj.access_token}`;
  } else {
    return null;
  }
}

export const RefreshAuthToken = () => {
  const obj = JSON.parse(localStorage.getItem("user"));

  if (obj && obj.refresh_token) {
    return `Bearer ${obj.refresh_token}`;
  } else {
    return null;
  }
}

export const expiresAuthTime = () => {
  const obj = JSON.parse(localStorage.getItem("user"));

  if (obj && obj.expires_on) {
    return obj.expires_on;
  } else {
    return null;
  }
}

