import axios from "axios";
import toastify from "../helper/toastify";
import AuthToken from "../helper/authToken";
//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});
export function setToken() {
  axiosApi.defaults.headers.common["Authorization"] = AuthToken();
}

// response interceptors =================================================
let isRefreshing = false;
let failedQueue = [];
axiosApi.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    let errStatus = error?.response?.status;
    const originalRequest = error.config;
    if (errStatus === 406) {
      setTimeout(() => {
        window.location.replace("/vpn");
      }, 3000);
      return;
    } else if (errStatus === 500) {
      if (originalRequest?.url?.includes("/token")) {
        toastify("error", error.response.data.detail);
        failedQueue = [];
        const remember = JSON.parse(localStorage.getItem("remember"));
        if (remember !== null && remember) {
          localStorage.removeItem("userProfile");
          localStorage.removeItem("user");
          localStorage.setItem("logout-event", "logout" + window.crypto.getRandomValues(new Uint32Array(1))[0]);
          setTimeout(() => {
            localStorage.removeItem("logout-event");
          }, 1000);
        } else {
          localStorage.setItem("logout-event", "logout" + window.crypto.getRandomValues(new Uint32Array(1))[0]);
          localStorage.clear();
        }
        window.location.replace("/signin");
        return Promise.reject(error.response.data.detail);
      } else {
        let errRes = error.response.data;
        if (errRes?.social_token?.length > 0) {
          let errMsg = "";
          for (const key in errRes) {
            if (Object.hasOwnProperty.call(errRes, key)) {
              if (errRes[key] instanceof Array)
                errMsg = errMsg + errRes[key][0] + " ";
              else errMsg = errMsg + errRes[key] + " ";
            }
          }
          return Promise.reject(errMsg);
        }
        toastify(
          "error",
          error.response.data.error === "Please verify your account"
            ? error.response.data.error
            : error.response.data.detail
        );
        return Promise.reject(error.response.data.detail);
      }
    } else if (errStatus === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refershTokenUpdate()
          .then((res) => {
            isRefreshing = false;
            const obj = JSON.parse(localStorage.getItem("user"));
            obj.access_token = res.access_token;
            obj.refresh_token = res.refresh_token;
            localStorage.setItem("user", JSON.stringify({ ...obj }));
            error.config.headers[
              "Authorization"
            ] = `Bearer ${res.access_token}`;
            error.config.baseURL = undefined;
            onRrefreshed(res.access_token);
          })
          .catch((e) => {
            return;
          });
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((accessToken) => {
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;
          resolve(axiosApi(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      let errRes = error.response?.data;
      let errMsg = "";
      var keys = Object.keys(errRes);
      if (keys[0] === "validity") {
        toastify("error", errRes.message);
      } else {
        for (const key in errRes) {
          if (Object.hasOwnProperty.call(errRes, key)) {
            if (errRes[key] instanceof Array) {
              errMsg = errMsg + errRes[key][0] + " ";
              toastify("error", errMsg);
            } else {
              errMsg = errMsg + errRes[key] + " ";
              toastify("error", errMsg);
            }
          }
        }
      }

      return Promise.reject(errMsg);
    }

  }
);
function subscribeTokenRefresh(cb) {
  failedQueue.push(cb);
}

function onRrefreshed(accessToken) {
  failedQueue.forEach((cb) => cb(accessToken));
}
export async function refershTokenUpdate() {
  const url = process.env.REACT_APP_APIURL + `accounts/token/`;
  const obj = JSON.parse(localStorage.getItem("user"));
  let body = {
    refresh_token: obj.refresh_token,
    access_token: obj.access_token,
  };
  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}
