/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ConsoleHelper } from "../helper/utilities";

function useNotificationSocket({ callbackMessage = () => {} }) {
  const loggedUser = useSelector((state) => state);
  const [reconnect,setRecconnect] = useState(false)
  let notificationSocketInstance = useRef(null);
  useEffect(() => {
    // create a new client with our server url
    let wsCurrent;
    if (loggedUser && loggedUser.user) {
      try {
        notificationSocketInstance.current = new WebSocket(
          `${process.env.REACT_APP_SOCKET_URL}${loggedUser?.user?.id}/`
        );
  
        notificationSocketInstance.current.onconnect = function (msg) {
          ConsoleHelper(msg, "CONNECT");
        };
  
        notificationSocketInstance.current.onmessage = function (msg) {
          const notificationSocketData = JSON.parse(msg.data);
          callbackMessage(notificationSocketData);
        };
        notificationSocketInstance.current.onclose = function (msg) {
        setTimeout(() => {
          ConsoleHelper("RECONNECT");
          setRecconnect(prev => !prev)
        },2000)
      }
        wsCurrent= notificationSocketInstance.current;
      } catch (error) {
        ConsoleHelper(error);
      }
      
    } 
    return () => {
      wsCurrent?.close();
    };
  }, [loggedUser,reconnect]);

  useEffect(() => {
    ConsoleHelper(notificationSocketInstance.current?.readyState,"STATE");
  },[notificationSocketInstance.current?.readyState])

  return notificationSocketInstance;
}

export default useNotificationSocket;
