import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import Linkify from "react-linkify";
import linkifyHtml from 'linkifyjs/html'
function PPKQuestion({ ppkData }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <section>

          <div className="col ppk-cards">
            <div className="card newPPK-cards">
              <div className="card-body">
                <h3 className="card-title">
                  <p className="ppkQuestionHeading">{ppkData.question}</p>
                </h3>
                <Linkify
                  properties={{
                    style: { color: "blue" },
                  }}
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <p
                    className="cardsPPK-desc"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(sanitizeHtml(ppkData.answer)),
                    }}
                    style={{ fontSize: "inherit" }}
                  ></p>
                </Linkify>

                <button className="ppk-readMore" onClick={handleShow}>
                  + Read more
                </button>
              </div>
            </div>
          </div>
     

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="PPk-heading">
            <h2>{ppkData.question}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="PPkModal-des"
            dangerouslySetInnerHTML={{
              __html: linkifyHtml(sanitizeHtml(ppkData.answer)),
            }}
            style={{ fontSize: "inherit" }}
          ></p>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default PPKQuestion;
