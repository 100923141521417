import React, { useState, useEffect } from "react";
import { Parallax } from "react-parallax";
import Accordion from "react-bootstrap/Accordion";
import { Button, Modal } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";
import Form from "react-bootstrap/Form";
import { faqlisting, sendFeedBack } from "../../services/faq.service";
import Linkify from "react-linkify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toastify from "../../helper/toastify.jsx";
import { Spinner } from "react-bootstrap";
import { logoutEventPublicRoutes } from "../../helper/utilities";
import linkifyHtml from "linkifyjs/lib/linkify-html";
const Faq = (props) => {
  const userData =
    JSON.parse(localStorage.getItem("userProfile")) !== null
      ? JSON.parse(localStorage.getItem("userProfile"))
      : "";
  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email format")
      .required("Email is required"),
    feedBack: Yup.string().required("Feedback is required"),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name:
        userData === "" ? "" : userData.first_name + " " + userData.last_name,
      email: userData === "" ? "" : userData.email,
      feedBack: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [openFeedback, setopenFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(userData.phone);
  const [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    getList();
    logoutEventPublicRoutes();
  }, []);

  const getList = () => {
    setLoading(true);
    faqlisting({ type: "FAQ" })
      .then((res) => {
        setFaqs(res);
        setLoading(false);
        var element = document.getElementById(
          props.location?.data === "refer" ? 9 : 0
        );
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [phoneCheck, setPhoneCheck] = useState(userData.phone);
  const handleFeedbackModal = () => setopenFeedback(true);
  const handleClose = () => setopenFeedback(false);
  const setPhoneInput = (value, country, e, formattedValue) => {
    setPhoneNumber(value.replace(country.dialCode, ""));
    setCountryCode("+" + country.dialCode);
    setPhoneCheck(value);
  };
  const [isSubmitDisabled, setSubmitButtonDisabled] = useState(false);
  async function onSubmit(data) {
    setSubmitButtonDisabled(true);
    if (phoneCheck === undefined || phoneNumber === "") {
      toastify("error", "Please enter phone number");
      setSubmitButtonDisabled(false);
    } else {
      let body = {
        name:
          data.name === ""
            ? userData?.first_name + " " + userData?.last_name
            : data.name,
        email: data.email === "" ? userData?.email : data.email,
        feedback: data.feedBack,
        phone: phoneNumber == "" ? userData.phone : countryCode + phoneNumber,
      };
      await sendFeedBack(body).then((res) => {
        setopenFeedback(false);
        toastify("success", "Feedback sent successfully");
        reset({
          name:
            userData === ""
              ? ""
              : userData.first_name + " " + userData.last_name,
          email: userData === "" ? "" : userData.email,
          feedback: "",
        });
      });

      setSubmitButtonDisabled(false);
    }
  }
  const [lengthLimit, setLengthLimit] = useState(0);
  const handleCount = (e) => {
    const value = e.target.value;
    const CharLimit = value.length;
    setLengthLimit(CharLimit);
  };

  return (
    <>
      <div className="slider-activation slider-creative-agency profile-slider">
        <Parallax
          bgImage={"/assets/images/header-background.png"}
          strength={500}
          bgClassName="page-banner-parallax profile-banner-parallax"
        >
          <div className="slide slide-style-2 game-slide slider-paralax d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner text-center">
                    <h1 className="title game-title theme-gradient">Faq</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>

      {/*Faq*/}
      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="frequently-asked-wrapper">
                <div className="frequently-question">
                  <p>Frequently asked question</p>
                </div>
                <div className="feedback-wrapper">
                  <Button
                    className=""
                    variant="secondary"
                    onClick={handleFeedbackModal}
                  >
                    Have A Question?
                  </Button>

                  <Modal
                    id="send-feedback-wrapper"
                    show={openFeedback}
                    onHide={handleClose}
                  >
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                      <Modal.Header closeButton>
                        <Modal.Title>Have a Question?</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="send-feedback-body">
                        <Form>
                          <Form.Group
                            className="feedback-bottom"
                            controlId="formBasicName"
                          >
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              {...register("name")}
                              className={`${errors.name ? "is-invalid" : ""}`}
                            />
                          </Form.Group>
                          {errors.name?.message && (
                            <div className="invalid-feedback d-block">
                              {errors.name?.message}
                            </div>
                          )}

                          <Form.Group
                            className="feedback-bottom"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="Email"
                              {...register("email")}
                              className={`${errors.email ? "is-invalid" : ""}`}
                            />
                            {errors.email?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.email?.message}
                              </div>
                            )}
                          </Form.Group>

                          {/* <Form.Group className="feedback-bottom">
                            <Form.Control
                              type="email"
                              placeholder="Enter gamer ID"
                            />
                          </Form.Group> */}
                          <Form.Group
                            className="feedback-bottom"
                            controlId="formBasicNumber"
                          >
                            <PhoneInput
                              {...register("phone")}
                              inputProps={{
                                name: "phone",
                                required: true,
                              }}
                              country="us"
                              inputClass="phn-verify"
                              onChange={(value, country, e, formattedValue) =>
                                setPhoneInput(value, country, e, formattedValue)
                              }
                              value={userData.phone}
                              placeholder="Enter you phone number"
                              style={{ color: "black" }}
                            />
                          </Form.Group>
                          <Form.Group
                            className="written-text"
                            controlId={
                              lengthLimit === 200
                                ? "exampleForm.Limit-Reached"
                                : "exampleForm.ControlTextarea1"
                            }
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Feedback"
                              name="feedBack"
                              {...register("feedBack")}
                              className={`${
                                errors.feedBack ? "is-invalid" : "text-area"
                              }`}
                              onChange={(e) => {
                                handleCount(e);
                              }}
                            />
                            {errors.feedBack?.message && (
                              <div className="invalid-feedback d-block">
                                {errors.feedBack?.message}
                              </div>
                            )}
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer className="send-feedback-button">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitDisabled}
                        >
                          Submit
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal>
                </div>
              </div>
              {loading ? (
                <Spinner animation="grow" />
              ) : (
                faqs.map((faq, idx) => {
                  return (
                    <>
                      {faq?.category === null ? (
                        ""
                      ) : (
                        <h3 id={idx}>{faq?.category?.name}</h3>
                      )}
                      {faq?.category === null
                        ? ""
                        : faq?.category?.questions?.map((data, id) => {
                            return (
                              <Accordion flush>
                                <Accordion.Item eventKey={idx}>
                                  <Accordion.Header>
                                    <div>{data.question}</div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Linkify
                                      properties={{
                                        style: { color: "blue" },
                                      }}
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <a
                                          target="blank"
                                          href={decoratedHref}
                                          key={key}
                                        >
                                          {decoratedText}
                                        </a>
                                      )}
                                    >
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: linkifyHtml(
                                            sanitizeHtml(data.answer)
                                          ),
                                        }}
                                      ></p>
                                    </Linkify>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            );
                          })}
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>

      {/*Faq*/}
    </>
  );
};
export default Faq;
