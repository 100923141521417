import React,{useState,useEffect} from "react";
import { useNotifications } from "../../context/NotificationsContext";
import Paginator from "../../helper/paginator/Paginator";
import { ConsoleHelper } from "../../helper/utilities";
import { getNotificationsList } from "../../services/notifications.service";
import { getFriendsRequests } from "../../services/user.service";
import NotificationCard from "../notifications/NotificationCard";

function FriendsRequest() {
  const [requests, setRequests] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const { dispatch: dispatchContext } = useNotifications();
  const pageSize = 10;

  const fetchNotificationsList = () => {
    let filter = {
      isPaginated: true,
      pageNum: 1,
      pageSize: 4,
      column: "created",
      order: "desc",
    };
    getNotificationsList(filter)
      .then((res) => {
        dispatchContext({
          type: "UPDATE_NOTIFICATIONS_DATA",
          payload: res?.results,
        });
        dispatchContext({
          type: "UPDATE_UNREAD_COUNT",
          payload: res?.notifications,
        });
      })
      .catch((err) => {
        ConsoleHelper(err);
      });
  };

  const getRequests = async () => {
    try {
      let filter = {
        isPaginated: true,
        pageNum,
        pageSize,
      };
      const res = await getFriendsRequests(filter);
      setRequests(res.results);
      setTotalSize(res?.total);
      fetchNotificationsList()
    } catch (error) {
      ConsoleHelper(error);
    }
  };

  const handlePageClick = (newPageNum) => {
    setPageNum(newPageNum);
  };

  const FriendsRequestListComponent = () => requests.map((request) => {
    return (
      <NotificationCard
        userData={request}
        fetchNotificationsList={getRequests}
        key={request?.id}
        pageNum={pageNum}
        isFriendRequest={true}
      />
    );
  });

  useEffect(() => {
    getRequests();
  }, [pageNum]);
  return (
    <div>
      {requests?.length ? (
        <>

        <div className="notification-listing-box">
          <FriendsRequestListComponent />
        </div>
          <div>
            <Paginator
              totalCount={totalSize}
              pageSize={pageSize}
              pageClick={handlePageClick}
            />
          </div>
        </>
      ) : (
        <h5>No Pending Friend Request</h5>
      )}
    </div>
  );
}

export default FriendsRequest;
