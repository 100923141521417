import { axiosApi, setToken } from ".";
export async function getTeamMatesLobby({ lobby_id, teamId }) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/lobby/${lobby_id}/teammates/${teamId ? "?team=" + teamId : ""}`;
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function postFriendRequest({
  status,
  promo_choice,
  lobbyId,
  teamId,
}) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/lobby/${lobbyId}/${teamId}/${status}-invite/`;
  let headers = {
    "Content-Type": "application/json",
  };
  let body = {
    promo_choice: promo_choice,
  };
  setToken();
  return await axiosApi.post(url, body, { headers });
}

export async function postReplaceTeamMate({
  lobbyId,
  teamId,
  removeId,
  newId,
}) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/lobby/${lobbyId}/${teamId}/replace-teammate/`;
  let headers = {
    "Content-Type": "application/json",
  };
  let body = {
    remove_player: removeId,
    new_player: newId,
  };
  setToken();
  return await axiosApi.post(url, body, { headers });
}
export async function callPreMadeTeams(lobby_id) {
  const url =
    process.env.REACT_APP_APIURL + `players/lobby/${lobby_id}/existing-teams/`;
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function callLeaveTeam(data) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/lobby/${data.lobby_id}/${data.team_id}/leave-team/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.put(url, { headers });
}
export async function makePaymentByOwner(data) {
  const url = process.env.REACT_APP_APIURL + `players/lobby/make-payment/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.post(url, data, { headers });
}

export async function getPlayersForChallengeReplace({ name = "", team,challenge_id }) {
  const url = process.env.REACT_APP_APIURL + `players/challenge/${challenge_id}/teammates`;
  let headers = {
    "Content-Type": "application/json",
  };
  let params = new URLSearchParams();
  params.append("name", name);
  params.append("team", team);

  setToken();
  return await axiosApi.get(url, { headers, params });
}

export async function replaceMemberChallenge({ data, challenge_id, team_id }) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/challenge/${challenge_id}/${team_id}/replace-teammate/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.post(url, data, { headers });
}



export async function leaveMemberChallenge({challenge_id, team_id }) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/challenge/${challenge_id}/${team_id}/leave-team/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.put(url, { headers });
}

export async function makePaymentChallenge(data) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/challenge/make-payment/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.post(url,data, { headers });
}


export async function putTeamRequestProfile({
  action,
  teamId,
}) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/versus/${teamId}/action/`;

  let body = {
    action: action ? "accept" : "cancel",
  };
  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.put(url, body, { headers });
}
