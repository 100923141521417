import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import toastify from "../../helper/toastify.jsx";
import GoogleLogin from "react-google-login";
import { FacebookProvider, Login } from "react-facebook";
import { disconnectSocials, googleLogin } from "../../services/user.service";
import { ConsoleHelper, getUrlChanger } from "../../helper/utilities.js";
const Socials = (props) => {
  const urlChanger = getUrlChanger("myprofile")
  const capitalize = ([first, ...rest]) =>
    first.toUpperCase() + rest.join("").toLowerCase();
  const handleDisconnect = async (data) => {
    let body = {
      provider: data,
    };
    await disconnectSocials(body)
      .then((res) => {
        toastify(
          "success",
          `${capitalize(res.provider)} account disconnected successfully`
        );
        props.FetchUserData();
      })
      .catch((err) => {
        ConsoleHelper(err)
      });
  };
  const responseGoogle = async (response) => {
    if (response.valid || response.valid === undefined) {
      props.LoaderTrue();
      let data = {
        provider: "google",
        code: response?.tokenObj?.id_token,
      };
      await googleLogin(data)
        .then((res) => {
          if (res !== undefined) {
            toastify("success", "Google account linked successfully");
          }
          props.FetchUserData();
          props.GoogleData();
        })
        .catch((err) => {
          props.Path();
          props.GoogleData();
        });
    } else {
      return;
    }
  };
  const responseErrorGoogle = (response) => {
    props.GoogleErrorData();
  };
  return (
    <>
      <>
        <table className="connectAccount" style={{ color: "white" }}>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Status</th>
          </tr>

          <tr>
            {props.Provider.find((element) => {
              if (element.includes("twitch")) {
                return true;
              }
            }) ? (
              <>
                <td>
                  <img
                    src="/assets/images/Twitch.svg"
                    alt="twitch"
                    className="social-login"
                  />
                </td>
                <td>Twitch</td>
                <td>
                  <Button
                    className="invite-btn"
                    onClick={() => handleDisconnect("twitch")}
                  >
                    Disconnect
                  </Button>
                </td>
              </>
            ) : (
              <>
                <td>
                  <a
                    href={`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${urlChanger}&scope=viewing_activity_read+openid+user:read:email&force_verify=true`}
                  >
                    <img
                      src="/assets/images/Twitch.svg"
                      alt="twitch"
                      className="social-login"
                    />
                  </a>
                </td>
                <td>Twitch</td>
                <td>
                  <a
                    style={{ color: "white" }}
                    href={`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${urlChanger}&scope=viewing_activity_read+openid+user:read:email&force_verify=true`}
                  >
                    <Button className="invite-btn">Connect Now</Button>
                  </a>
                </td>
              </>
            )}
          </tr>
          <tr>
            {props.Provider.find((element) => {
              if (element.includes("facebook")) {
                return true;
              }
            }) ? (
              <>
                <td>
                  <img
                    src="/assets/images/facebook-one.svg"
                    alt="facebook"
                    className="social-login"
                  />
                </td>
                <td>Facebook</td>
                <td>
                  <Button
                    className="invite-btn"
                    onClick={() => handleDisconnect("facebook")}
                  >
                    Disconnect
                  </Button>
                </td>
              </>
            ) : (
              <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
                <Login
                  fields="first_name, last_name, email, picture, birthday, link"
                  scope="public_profile, email, user_birthday, user_link"
                  onCompleted={props.HandleResponse}
                  onError={props.HandleError}
                >
                  {({ loading, handleClick, error, data }) => (
                    <>
                      <td>
                        <img
                          onClick={handleClick}
                          src="/assets/images/facebook-one.svg"
                          alt="facebook"
                          style={{ cursor: "pointer" }}
                          className="social-login"
                        />
                      </td>
                      <td>Facebook</td>
                      <td onClick={handleClick} style={{ cursor: "pointer" }}>
                        <Button className="invite-btn">Connect Now</Button>
                      </td>
                    </>
                  )}
                </Login>
              </FacebookProvider>
            )}
          </tr>
          <tr>
            {props.Provider.find((element) => {
              if (element.includes("discord")) {
                return true;
              }
            }) ? (
              <>
                <td>
                  <img
                    src="/assets/images/chats.svg"
                    alt="Discord"
                    className="social-login"
                  />
                </td>
                <td>Discord</td>
                <td>
                  <Button
                    className="invite-btn"
                    onClick={() => handleDisconnect("discord")}
                  >
                    Disconnect
                  </Button>
                </td>
              </>
            ) : (
              <>
                <td>
                  <a
                    href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${urlChanger}&response_type=code&scope=identify%20email`}
                  >
                    <img
                      src="/assets/images/chats.svg"
                      alt="Discord"
                      style={{ cursor: "pointer" }}
                      className="social-login"
                    />
                  </a>
                </td>
                <td>Discord</td>
                <td>
                  <a
                    style={{ color: "white" }}
                    href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${urlChanger}&response_type=code&scope=identify%20email`}
                  >
                    <Button className="invite-btn">Connect Now</Button>
                  </a>
                </td>
              </>
            )}
          </tr>
          <tr>
            {props.Provider.find((element) => {
              if (element.includes("google")) {
                return true;
              }
            }) ? (
              <>
                <td>
                  <img
                    src="/assets/images/Google.svg"
                    alt="Google"
                    className="social-login"
                  />
                </td>
                <td>Google</td>
                <td>
                  <Button
                    className="invite-btn"
                    onClick={() => handleDisconnect("google")}
                  >
                    Disconnect
                  </Button>
                </td>
              </>
            ) : (
              <>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <>
                      <td>
                        <img
                          onClick={renderProps.onClick}
                          //disabled={renderProps.disabled}
                          src="/assets/images/Google.svg"
                          alt="Google"
                          style={{ cursor: "pointer" }}
                          className="social-login"
                        />
                      </td>
                      <td>Google</td>
                      <td
                        alt="Google"
                        onClick={renderProps.onClick}
                        style={{ cursor: "pointer" }}
                      >
                        <Button className="invite-btn">Connect Now</Button>
                      </td>
                    </>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseErrorGoogle}
                  cookiePolicy={"single_host_origin"}
                  autoLoad={false}
                />
              </>
            )}
          </tr>
          <tr>
            {props.Provider.find((element) => {
              if (element.includes("twitter")) {
                return true;
              }
            }) ? (
              <>
                <td>
                  <img
                    src="/assets/images/twitter-one.svg"
                    alt="twitter"
                    className="social-login"
                  />
                </td>
                <td>Twitter</td>

                <td>
                  <Button
                    className="invite-btn"
                    onClick={() => handleDisconnect("twitter")}
                  >
                    Disconnect
                  </Button>
                </td>
              </>
            ) : (
              <>
                <td>
                  <a href={props.TwitterLink}>
                    <img
                      src="/assets/images/twitter-one.svg"
                      alt="twitter"
                      style={{ cursor: "pointer" }}
                      className="social-login"
                    />
                  </a>
                </td>
                <td>Twitter</td>
                <td>
                  <a style={{ color: "white" }} href={props.TwitterLink}>
                    <Button className="invite-btn">Connect Now</Button>
                  </a>
                </td>
              </>
            )}
          </tr>
        </table>
      </>
    </>
  );
};
Socials.propTypes = {
  FetchUserData: PropTypes.func,
  HandleResponse: PropTypes.func,
  HandleError: PropTypes.func,
  LoaderTrue: PropTypes.func,
  GoogleData: PropTypes.func,
  GoogleErrorData: PropTypes.func,
};
export default Socials;
