import React from "react";
import PropTypes from "prop-types";
import LobbyCurrentStatus from "../../constants/LobbyCurrentStatus";
import './index.scss'
const LobbyStatus = (props) => {
  const {data,activeClasses} = props
  return (
    <>
      {(() => {
        switch (data.current_status) {
          case LobbyCurrentStatus.ACTIVE:
            return <><span className={`dot ${activeClasses}`}> </span> Active</>;
          case LobbyCurrentStatus.UPCOMING:
            return <span className="upcoming-lobby">{" Upcoming "}</span>
          default:
          return data?.stats_status == LobbyCurrentStatus.CANCELLED ? (
              <span className="cancelled-lobby">
                {" Cancelled "}
              </span>
            ) : (
              <span className="ended-lobby">{" Ended "}</span>
            );
        }
      })()}
    </>
  );
};

LobbyStatus.propTypes = {
  current_status: PropTypes.number.isRequired,
};

export default LobbyStatus;
