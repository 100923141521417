import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "../../services/user.service";
import { getTotalTake, isChromeAndAndroid, isSafariAndApple } from "./Utility";
import toastify from "../../helper/toastify";
import { Modal } from "react-bootstrap";

const PaymentRequestButton = ({
  amount,
  successFullCallback,
  setProcessing,
  walletBalance,
}) => {
  const stripe = useStripe();
  const [isWalletLoaded, setIsWalletLoaded] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleError = () => {
    setProcessing(false);
    toastify(
      "error",
      "We were unable to add funds to your wallet. Please try again"
    );
  };
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Add to Wallet",
          amount: Math.ceil(getTotalTake(amount, 2.9) * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        setIsWalletLoaded(true);
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        try {
          setProcessing(true);
          const { clientSecret } = await createPaymentIntent(amount);
          if (!clientSecret) return;
          const res = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
          if (res.error) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete("fail");
            handleError();
            return;
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete("success");
            successFullCallback({
              res: { amount: +walletBalance + amount },
              setProcessing,
            });
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (res.paymentIntent.status === "requires_action") {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                ev.complete("fail");
                handleError();
              } else {
                // The payment has succeeded.
                ev.complete("success");
                successFullCallback({
                  res: { amount: +walletBalance + amount },
                  setProcessing,
                });
              }
            } else {
              // The payment has succeeded.\
              ev.complete("success");
              successFullCallback({
                res: { amount: +walletBalance + amount },
                setProcessing,
              });
            }
          }
        } catch (error) {
          ev.complete("fail");
          handleError();
        }
      });
    }
  }, [stripe, amount, walletBalance]);

  const style = {
    paymentRequestButton: {
      theme: "light",
      type: "add-money",
    },
  };
  console.log(paymentRequest);
  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement options={{ paymentRequest, style }} />;
        {/* <Paypal
          amount={amount}
          successFullCallback={successFullCallback}
          handleError={handleError}
          setProcessing={setProcessing}
        /> */}
      </>
    );
  }
  return isWalletLoaded ? (
    <>
      {isChromeAndAndroid || isSafariAndApple ? (
        <div className="payment-unavailable-container">
          <p className="payment-method-unavailable-message">
            Either your browser does not support the payment wallets, or you do
            not have a saved payment method.
          </p>
          <p className="payment-error" onClick={() => setShowInfoModal(true)}>
            Click here to know more.
          </p>
        </div>
      ) : (
        <div className="payment-unavailable-container">
          <p className="payment-method-unavailable-message">
            We support Google Pay and Apple Pay for transaction. Please use
            "Google Chrome" for Google Pay and "Safari" for Apple Pay
          </p>
          <p>Note: Google Pay does not work on Apple devices.</p>
        </div>
      )}
      {isChromeAndAndroid ? (
        <Modal
          className="payment-method-info-modal"
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
        >
          <Modal.Header closeButton>
            <h3>STEPS TO ENABLE GOOGLE PAY</h3>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>Use Google Chrome browser</li>
              <li>
                Sign in with the Google Account linked with your Google Pay over
                the same browser
              </li>
              <li>
                Add atleast one card/ payment method mapped with Google Pay
              </li>
              <li>Add billing address associated with the card</li>
            </ul>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          className="payment-method-info-modal"
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
        >
          <Modal.Header closeButton>
            <h3>STEPS TO ENABLE APPLE PAY</h3>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>Use Safari browser</li>
              <li>
                Sign in with the Apple Account linked with your Apple Pay over
                the same browser
              </li>
              <li>
                Add atleast one card/ payment method mapped with Apple Pay
              </li>
              <li>Add billing address associated with the card</li>
            </ul>
          </Modal.Body>
        </Modal>
      )}
    </>
  ) : (
    <span style={{ color: "white" }}>Loading payment methods ...</span>
  );
};

export default PaymentRequestButton;
