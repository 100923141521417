import React, { useEffect, useState } from "react";
import Paginator from "../../helper/paginator/Paginator";
import { walletHistory } from "../../services/user.service";

import PaymentConstantTypes from "../../constants/paymentConstantTypes";
const WalletTransaction = (props) => {
  const [transactions, settransactions] = useState(null);
  const [totalCount, settotalCount] = useState(null);
  const [pageNum, setpageNum] = useState(1);
  const pageSize = 10;
  const pagination = true;

  useEffect(() => {
    getListing();
  }, [pageNum, props.data]);

  const getListing = async () => {
    let filter = {
      isPaginated: pagination,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    await walletHistory(filter).then((res) => {
      if (pagination) {
        settransactions(res.data);
        settotalCount(res.total);
      } else {
        settransactions(res);
        settotalCount(res.length);
      }
    });
  };

  const handlePageClick = (newPageNum) => {
    setpageNum(newPageNum)
  };

  return (
    <>
      {totalCount ? (
        <>
          <table style={{ color: "white" }}>
            <tr>
              <th>Transaction Id</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Wallet Balance</th>
              <th>Transaction type</th>
              <th>Payment Method</th>
            </tr>
            {transactions &&
              transactions.map((trans, idx) => {
                const paymentConst = PaymentConstantTypes.map((type) => type);
                const paymentConstFilter = paymentConst.filter(
                  (paymentType) => paymentType.value === trans.type
                );
                return (
                  <tr key={idx}>
                    <td className="walletTransaction">
                      <p>{trans.transaction_number || "NA"}</p>
                    </td>

                    <td>
                      {trans.transaction_date}
                    </td>
                    <td>
                      {/* credited amount */}
                      {[1, 4, 7, 9, 10].includes(trans.type) && (
                        <p style={{ color: "green" }}>+ ${trans.amount}</p>
                      )}
                      {/* debited amount */}
                      {[2, 3, 5, 6, 12].includes(trans.type) && (
                        <p style={{ color: "red" }}>- ${trans.amount}</p>
                      )}

                      {/* on hold */}
                      {[8].includes(trans.type) && (
                        <p style={{ color: "#ff9900" }}>${trans.amount}</p>
                      )}
                    </td>
                    <td> ${trans.current_wallet_balance}</td>
                    <td>
                      {trans?.type == 3 && trans?.is_free_lobby  ? `${paymentConstFilter[0].label} (Free Entry)`   :trans.status === 3
                        ? `${paymentConstFilter[0].label} (pending)`
                        : trans.status === 4
                        ? "ON HOLD"
                        : trans.status === 2
                        ? "FAILED"
                        : trans.transaction_mode === 1
                        ? "PROMO BONUS"
                        : `${paymentConstFilter[0].label}`}
                    </td>
                    <td>
                      {trans?.payment_method == "Stripe Instant" ||
                      trans?.payment_method == "Stripe"
                        ? trans?.payment_method == "Stripe Instant"
                          ? "Instant"
                          : "Standard"
                        : trans?.payment_method}
                    </td>
                  </tr>
                );
              })}
          </table>
          <div>
            {pagination ? (
              <Paginator
                totalCount={totalCount}
                pageSize={pageSize}
                pageClick={handlePageClick}
              />
            ) : null}
          </div>
        </>
      ) : (
        <h5 style={{ textAlign: "center", padding: "20px" }}>
          No transactions yet{" "}
        </h5>
      )}
    </>
  );
};

export default WalletTransaction;
