import { axiosApi, setToken } from ".";


export async function getAvailableLobbies() {
  const url =
    process.env.REACT_APP_APIURL + "players/dashboard/available-lobbies/";

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function getActiveLobbies() {
  const url =
    process.env.REACT_APP_APIURL + "players/dashboard/active-lobbies/";

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function getPreviousLobbies() {
  const url =
    process.env.REACT_APP_APIURL + "players/dashboard/previous-lobbies/";

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}

export async function callGameFilter(filter) {
  const url =
    process.env.REACT_APP_APIURL +
    `players/dashboard/available-lobbies/?game=${filter}`;

  let headers = {
    "Content-Type": "application/json",
  };

  setToken();
  return await axiosApi.get(url, { headers });
}
