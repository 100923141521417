// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./component/common/App";
import { Provider } from "react-redux";
import store from "./store";
import { NotificationsProvider } from "./context/NotificationsContext";
import { ConfigProvider } from "./context/ConfigContext";
import { GameProvider } from "./context/GameContext";

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <PageScrollTop>
            <ConfigProvider>
              <NotificationsProvider>
                <GameProvider>
                  <App />
                </GameProvider>
              </NotificationsProvider>
            </ConfigProvider>
          </PageScrollTop>{" "}
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});
