import axios from "axios";
import { axiosApi, setToken } from ".";

export async function login({ email, password, player_ip }) {
  const body = {
    email: email,
    password: password,
    player_ip: player_ip,
  };

  const url = process.env.REACT_APP_APIURL + "accounts/login/";

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers }).then((res) => {
    localStorage.setItem("user", JSON.stringify(res.extras));
    return res.extras;
  });
}

export async function signup(model) {
  const body = model;
  model["confirm_password"] = model.password;

  const url = process.env.REACT_APP_APIURL + "accounts/signup/";

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function forgotpassword(email) {
  const body = {
    email: email,
  };

  const url = process.env.REACT_APP_APIURL + "accounts/forgot-password/";

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function forgotusername(email) {
  const body = {
    email: email,
  };

  const url = process.env.REACT_APP_APIURL + "accounts/forgot-username/";

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function resetpassword(password, id, token) {
  const body = {
    password: password,
    confirm_password: password,
  };

  const url =
    process.env.REACT_APP_APIURL +
    `accounts/reset-password/?id=${id}&token=${token}`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.put(url, body, { headers });
}

export async function verifyaccount(id, token) {
  const url =
    process.env.REACT_APP_APIURL +
    `accounts/verification?id=${id}&token=${token}`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers });
}

export async function requestverification(email) {
  let body = {
    email: email,
  };

  const url =
    process.env.REACT_APP_APIURL + `accounts/resend-verification-email/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, body, { headers });
}

export async function logout(type) {
  const remember = JSON.parse(localStorage.getItem("remember"));
  if (type === "changePassword") {
    window.location.replace("/signin");
    localStorage.setItem("logout-event", "logout" + window.crypto.getRandomValues(new Uint32Array(1))[0]);
    localStorage.clear();
  } else {
    const res = await logoutAPI();
    if (res.success == "Logged out successfully") {
      if (remember !== null && remember) {
        localStorage.removeItem("userProfile");
        localStorage.removeItem("user");
        localStorage.setItem("logout-event", "logout" + window.crypto.getRandomValues(new Uint32Array(1))[0]);
        setTimeout(() => {
          localStorage.removeItem("logout-event");
        }, 1000);
      } else {
        localStorage.setItem("logout-event", "logout" + window.crypto.getRandomValues(new Uint32Array(1))[0]);
        localStorage.clear();
      }
      window.location.replace("/signin");
    }
  }
}
export async function validateTwitch(data) {
  const url = "https://id.twitch.tv/oauth2/validate";

  let headers = {
    Authorization: `Bearer ${data}`,
  };

  return await axios.get(url, { headers });
}

export async function twitchUserData(user, token) {
  const url = `https://api.twitch.tv/helix/users?id=${user}`;
  let headers = {
    Authorization: `Bearer ${token}`,
    "Client-ID": process.env.REACT_APP_TWITCH_CLIENT_ID,
  };

  return await axios.get(url, {
    headers,
  });
}
export async function discordLogin(data) {
  const url = process.env.REACT_APP_APIURL + `accounts/social-login/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function twitchLogin(data) {
  const url = process.env.REACT_APP_APIURL + `accounts/social-login/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function facebookLogin(data) {
  const url = process.env.REACT_APP_APIURL + `accounts/social-login/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}
export async function googleLogin(data) {
  const url = process.env.REACT_APP_APIURL + `accounts/social-login/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}
export async function twitterUrl({ redirect }) {
  let params = new URLSearchParams();
  if (redirect) params.append("redirect", redirect);
  const url = process.env.REACT_APP_APIURL + `accounts/twitter-auth/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.get(url, { headers, params });
}

export async function twitterLogin(data) {
  const url = process.env.REACT_APP_APIURL + `accounts/social-login/`;

  let headers = {
    "Content-Type": "application/json",
  };

  return await axiosApi.post(url, data, { headers });
}

export async function logoutAPI() {
  const url = process.env.REACT_APP_APIURL + `accounts/logout/`;
  let headers = {
    "Content-Type": "application/json",
  };
  setToken();
  return await axiosApi.put(url, { headers });
}
